import './scss/style.scss';
import 'core-js/stable';
import 'fastestsmallesttextencoderdecoder';
import 'webcrypto-shim';
import 'regenerator-runtime/runtime';
import 'classlist-polyfill';
import { ThemeProvider } from '@material-ui/core/styles';
import { hydrateRoot, createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as React from 'react';
import initData from './utilities/init-data';
import gtmTracker from './utilities/gtm-tracker';
import { theme } from './theme/theme';
import Router from './routes/router';
import Store from './redux/store';
import NotificationContainer from './components/notification-container';
import ErrorBoundary from './components/_common/error-boundary';
import { fetchUserProfile, fetchUserPreference } from './redux/user/user.actions';
import { fetchQueryBuilderSteps } from './redux/querybuilder/query-builder.actions';
import security from './utilities/security';
import marketoTracker from './utilities/marketo-tracker';
import cookieNotice from './utilities/cookie-notice';
import setLanguageContext from './utilities/set-language-context';
import spgVariables from './utilities/spg-variables';
import featureFlags from './utilities/feature-flags';
import serviceWorkerRegistration from './serviceWorkerRegistration';
import hotjartracker from './utilities/tracker';
import datadogtracker from './utilities/tracker-datadog';
import history from './utilities/history';

const spgVars = spgVariables();
const anonymousText = 'Anonymous';
const Window = window as AnonymousObject;

const domNode = document.getElementById('root');

if (!domNode) {
    throw new Error("The element #root wasn't found");
}
const cRoot = createRoot(domNode);
const hRoot = hydrateRoot(domNode, React.Component);
Window.dataLayer = Window.dataLayer || [];
function renderApp(root: Root) {
    root.render(
        <Provider store={Store}>
            <ThemeProvider theme={theme}>
                <NotificationContainer />
                <ErrorBoundary isSilent>
                    <Router />
                </ErrorBoundary>
            </ThemeProvider>
        </Provider>,
    );
}

setLanguageContext();
cookieNotice();
initData(window.location.pathname);
gtmTracker();

security
    .authenticate()
    .then(token => {
        if (token) {
            Store.dispatch(
                fetchUserProfile.action({
                    onSuccess: (user: UserInfo) => {
                        featureFlags.init(spgVars.LD_CLIENT_ID, spgVars.LD_SECRET_HASH, spgVars.LD_RELAYPROXY, user);
                        console.log('Key Online User : ', user.KeyOnlineUser);
                        Window.dataLayer.push({
                            event: 'login',
                            keyOnlineUser: user.KeyOnlineUser
                        });
                    }
                })
            );
            // Code snippet to have backward and forward compatibility from OKTA to IDM or viceversa hidden iframe
            const kenshoAutoLoginURL = 'https://secure.signin.spglobal.com/app/spglobaliam_wam12cprod_1/exk1ll4itvcxcfGlc1d8/sso/saml?RelayState=https://login.spglobal.com/favicon.ico';
            const noscript = document.createElement('noscript');
            const oktaiframe = document.createElement('iframe');
            oktaiframe.id = 'OktaToIDM12cBackwardCHandShakeUrlIFrame';
            oktaiframe.src = kenshoAutoLoginURL;
            oktaiframe.height = '0';
            oktaiframe.width = '0';
            oktaiframe.style.visibility = 'hidden';
            oktaiframe.style.display = 'none';
            noscript.appendChild(oktaiframe);
            document.getElementsByTagName('body')[0].appendChild(noscript);
            Store.dispatch(fetchUserPreference.action());
            Store.dispatch(fetchQueryBuilderSteps());
        } else {
            featureFlags.init(spgVars.LD_CLIENT_ID, spgVars.LD_SECRET_HASH, spgVars.LD_RELAYPROXY);
            Window.dataLayer.push({
                KeyOnlineUser: anonymousText,
                SubscriberAccount: anonymousText,
                CompanyType: anonymousText,
                PrimaryJobFunction: anonymousText,
                UserWebpartProfile: anonymousText
            });
        }
    })
    .finally(() => {
        renderApp(spgVars.SSR ? hRoot : cRoot);
        marketoTracker();
    });

serviceWorkerRegistration();

setTimeout(() => { datadogtracker(); }, 2000);

setTimeout(() => { hotjartracker(); }, 2000);
