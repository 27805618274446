import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { cn, removeInvalidCharacters } from '../../../../helpers';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        errorMessage: {
            color: theme.palette.error.main,
            display: 'block',
            textAlign: 'right',
            fontSize: theme.typography.pxToRem(12)
        }
    });
});

const textFieldInput = function TextFieldInput({
    autoFocus,
    className,
    controlledValue,
    defaultValue,
    errorObject,
    helpText,
    inputProps,
    isDisabled,
    isFullWidth = true,
    isRequired,
    label,
    name,
    onChange,
    type = 'text',
    hasOtherField,
    placeHolder,
    autocomplete = ''
}: TextFieldInputProps) {
    const classes = useStyles(0);

    const [value, setValue] = React.useState<number | string>(defaultValue || '');

    function handleChange(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) {
        const inputVal = event.target.value as string;
        const update: string = removeInvalidCharacters(inputVal);
        const alphanumreg = /^[a-zA-Z0-9,. ]*$/;
        if (hasOtherField && (alphanumreg.test(inputVal))) {
            setValue(update);
            onChange(name, update);
        }
        if (!hasOtherField) {
            setValue(update);
            onChange(name, update);
        }

    }

    function validate(el: any, isValid: boolean, errorMessage: any, opts: any) {
        const removeOnly = opts && opts.removeOnly;
        const elField = el.closest('.field');
        const elError = elField && elField.querySelector(`#${name}`);

        if (isValid && !opts.addOnly) {
            // elError.innerText = ''; // It's valid
            el.removeAttribute('aria-invalid');
            return true;
        } if (!removeOnly && elError) {
            el.setAttribute('aria-invalid', 'true');
            elError.innerText = `${name} is required `;
            return false;
        }
        return false;
    }

    function validateFn(el: any, opts: any) {
        const isEmpty = el.value === '';
        if (isEmpty && errorObject) {
            validate(el, !isEmpty, errorObject.message, opts);
        }
    }


    function handleBlur(event: any) {
        validateFn(event.target, { live: true });
    }


    return (
        <div className={cn(`field ${className}`)}>
            <TextField
                autoFocus={autoFocus}
                disabled={isDisabled}
                error={errorObject !== undefined}
                fullWidth={isFullWidth}
                helperText={helpText}
                inputProps={{
                    ...inputProps,
                    'aria-label': label,
                    'data-testid': name,
                    'aria-describedby': name,
                }}
                label={label}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                required={isRequired}
                type={type}
                value={controlledValue || value}
                variant="filled"
                placeholder={placeHolder}
                autoComplete={autocomplete}
            />
            {errorObject && <span id={name} className={classes.errorMessage} aria-live="assertive">{errorObject.message}</span>}
        </div>
    );
};
export default React.memo(textFieldInput);
