import * as React from 'react';
import { cn, sanitizer } from '../../../helpers';

import './html-content.scss';

function HtmlContent(props: HtmlContentProps) {
    const { html, className, inputProps } = props;

    return <div className={cn('html-content', className)} data-testid="html-content" dangerouslySetInnerHTML={{ __html: sanitizer(html, {}) }} {...inputProps} />;
}

export default React.memo(HtmlContent);
