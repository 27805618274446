import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useParams } from 'react-router-dom';
import { getText } from '../../helpers';

/**
 * This component mirrors the LoginCallback provided by okta-react except it will redirect the user
 * to the previous page that triggered the auth flow in the event an error occurs.
 */

const langCode = getText('langCode');

export default function OktaLoginCallback(): null {
    const { oktaAuth, authState } = useOktaAuth();
    const { type } = useParams();

    React.useEffect(() => {
        if (oktaAuth && oktaAuth.options) {
            oktaAuth.options.redirectUri = `${window.location.origin}/${langCode}/${type}/demos/auth/callback`;
        }

        oktaAuth.handleLoginRedirect();

        if (!authState.isPending && authState.error) {
            const location = oktaAuth.getOriginalUri();
            window.location.assign(location);
        }
    }, [oktaAuth, authState]);

    return null;
}
