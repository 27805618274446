/* eslint-disable @typescript-eslint/camelcase */
import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import pRetry from 'p-retry';
import { getConfig } from '../helpers';
import queryStringManager from './query-string-manager';
import SPGVariables from './spg-variables';
import Store from '../redux/store';
import { ErrorManager } from './error-manager';
import { toggleLogInStatus, clearUserProfile } from '../redux/user/user.actions';

const spgVariables = SPGVariables();

const securityConfig = getConfig('utilities.security');
const pRetryOptions = getConfig('utilities.pRetryOptions');
const urls = getConfig('urls.mi.security');

const { suppressAuthorizationHeader }: { suppressAuthorizationHeader: string[] } = securityConfig;

let miCredentials: AnonymousObject;
let tokenRefreshTimer: NodeJS.Timeout;

// initialize axios instance to handle requests to security service
const axiosSecurityClient: AxiosInstance = axios.create({
    baseURL: spgVariables.APIBASE,
    timeout: securityConfig.requestTimeout,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
});

const sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};


const signOut = async (clearCookies = true) => {
    clearTimeout(tokenRefreshTimer);
    localStorage.removeItem(securityConfig.token.sessionStorageKey);
    localStorage.removeItem('state');
    localStorage.removeItem('stateUser');

    if (clearCookies && process.env.NODE_ENV === 'production') {
        localStorage.setItem('logout-event', `logout${Math.random()}`);
        const batchClearTokenCookies = axiosSecurityClient.post(
            urls.batchClearTokenCookies,
            queryStringManager.stringify({
                domains: [window.location.hostname],
                refreshToken: true
            })
        );
        const clearAllTokenCookies = axiosSecurityClient.post(
            urls.clearAllTokenCookies,
            queryStringManager.stringify({
                path: '/',
                domain: window.location.hostname
            })
        );
        await Promise.all([batchClearTokenCookies, clearAllTokenCookies])
            .catch((error: AxiosError) => {
                console.error('Clear token cookies error');
                // ErrorManager.apiError(error, { msg: 'Sign out process error.' });
            })
            .finally(() => {
                Store.dispatch(clearUserProfile());
            });
    } else {
        Store.dispatch(clearUserProfile());
    }
};

// load token info in localStorage
const getAccessToken = (): AccessToken | undefined => {
    const serializedToken = localStorage.getItem(securityConfig.token.sessionStorageKey);
    if (serializedToken == null) {
        return undefined;
    }
    return JSON.parse(serializedToken);
};

const scheduleTokenRefresh = () => {
    // first, cancel any pending refresh
    clearTimeout(tokenRefreshTimer);
    // and determine the interval until we should refresh
    const accessToken = getAccessToken();
    if (accessToken) {
        const timeout = Math.max(accessToken.expires_in - new Date().getTime(), 0);
        if (timeout) {
            // set a callback
            tokenRefreshTimer = setTimeout(() => {
                // authenticate to get new tokens
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                pRetry(() => authenticate(false), pRetryOptions).catch((error: AxiosError) => {
                    ErrorManager.apiError(error, { msg: 'Authentication Error!' });
                    console.error('2. Security.tsx ', error);
                    signOut();
                });
            }, timeout);
        }
    }
};


// store token info in localStorage
const storeAccessToken = (accessToken: AccessToken) => {
    const expires = new Date().getTime() + accessToken.expires_in * 1000 * 0.75;
    localStorage.setItem(securityConfig.token.sessionStorageKey, JSON.stringify({ ...accessToken, expires_in: expires }));
    scheduleTokenRefresh();
};


// authenticate via security service
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authenticate = async (signoutOnFail = true): Promise<AccessToken> => {
    return new Promise((resolve, reject) => {
        const requestBody = {
            client_id: spgVariables.CLIENTID,
            client_secret: spgVariables.CLIENTSECRET,
            scope: `${securityConfig.token.scopePart1}${securityConfig.token.scopePart2}`,
            grant_type: securityConfig.grantTypes.refreshToken
        };

        if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_RELEASELOCAL) {
            if (process.env.REACT_APP_MIUSERNAME && process.env.REACT_APP_MIPASSWORD) {
                miCredentials = {
                    username: process.env.REACT_APP_MIUSERNAME,
                    password: process.env.REACT_APP_MIPASSWORD
                };
                requestBody.grant_type = securityConfig.grantTypes.password;
            } else {
                requestBody.client_id = '';
                requestBody.client_secret = '';
            }
        }

        if (!(requestBody.client_id && requestBody.client_secret)) {
            signOut(false).then(() => resolve()); // anonymous user
        } else {
            const body =
                requestBody.grant_type === securityConfig.grantTypes.refreshToken
                    ? { ...requestBody, refresh_token: spgVariables.REFRESHTOKEN }
                    : { ...requestBody, ...miCredentials };

            axiosSecurityClient
                .post<AccessToken>(urls.token, queryStringManager.stringify(body))
                .then(response => {
                    storeAccessToken(response.data);
                    Store.dispatch(toggleLogInStatus(true));
                    axiosSecurityClient
                        .post(
                            urls.setTokenCookies,
                            JSON.stringify({
                                path: '/',
                                domain: window.location.hostname.substr(window.location.hostname.indexOf('.'))
                            }),
                            {
                                headers: { Authorization: `Bearer ${response.data.access_token}`, 'Content-Type': 'application/json' }
                            }
                        )
                        .catch((error: AxiosError) => {
                            ErrorManager.apiError(error, { msg: 'Authentication Error!' });
                            console.log('3. Security.tsx ', error);
                        });
                    // Below code is logout user from all the browser tab if user logged out from one tab
                    window.addEventListener('storage', event => {
                        if (event.key === 'logout-event') {
                            signOut(false);
                        }
                    });
                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    const parameters = {
                        ServiceName: 'SecurityService',
                        RequestUri: urls.token,
                        error,
                        msg: 'Authentication Error!'
                    };
                    ErrorManager.apiError(error, parameters);
                    console.log('4. Security.tsx ', error);

                    if (signoutOnFail) {
                        signOut().finally(() => reject());
                    } else {
                        reject(error);
                    }
                });
        }
    });
};

const setOAuthRequestInterceptor = (instance: AxiosInstance) => {
    let tokenRefreshInProgress = false;
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        return new Promise(resolve => {
            const url = config.url || '';

            // we have some urls that dont need a token
            const supressToken =
                url.length &&
                suppressAuthorizationHeader.some(regexStr => {
                    const regex = RegExp(regexStr, 'i');
                    return regex.test(url);
                });

            if (supressToken) {
                resolve(config);
                return;
            }
            if (tokenRefreshInProgress) {
                // Token refresh in progress
                pRetry(
                    () => {
                        return new Promise((tokenAvailable, tokenNotAvailable) => {
                            if (tokenRefreshInProgress) {
                                tokenNotAvailable(new Error(url));
                            } else {
                                tokenAvailable();
                            }
                        });
                    },
                    {
                        retries: 10,
                        minTimeout: 100,
                        maxTimeout: 60 * 10 * 1000, // 10 mins
                        factor: 2.25,
                        onFailedAttempt: () => {
                            sleep(500); // snooze
                        }
                    }
                )
                    .then(() => {
                        // we have a new token
                        const token = getAccessToken();
                        if (token) {
                            config.headers.Authorization = `Bearer ${token.access_token}`;
                        }
                        resolve(config);
                    })
                    .catch(() => {
                        // still no token available, expect 401
                        console.warn(`no accesstoken available for ${config.url}`);
                        resolve(config);
                    });
            } else {
                const token = getAccessToken();
                if (token) {
                    const refreshThreshold = new Date().getTime() + 300000; // 5 minutes from now
                    if (refreshThreshold > token.expires_in) {
                        tokenRefreshInProgress = true;
                        // we got an old token, so lets get a new one before we proceed
                        pRetry(() => authenticate(false), pRetryOptions)
                            .then(response => {
                                // got a new one, great! attach to header
                                if (response) {
                                    config.headers.Authorization = `Bearer ${response.access_token}`;
                                } else {
                                    config.headers.Authorization = `Bearer ${token.access_token}`;
                                }
                            })
                            .catch((error: AxiosError) => {
                                // security service unavailable, log and expect 401
                                ErrorManager.apiError(error, { msg: 'Authentication Error!' });
                                console.log('5. Security.tsx ', error);
                                // signout since we didnt pass that option when authenticating above
                                signOut();
                            })
                            .finally(() => {
                                // release waiting requests and allow to succeed or fail
                                tokenRefreshInProgress = false;
                                resolve(config);
                            });
                    } else {
                        // we have a token, attach to header
                        config.headers.Authorization = `Bearer ${token.access_token}`;
                        resolve(config);
                    }
                } else {
                    resolve(config);
                }
            }
        });
    });
};

const security = {
    setOAuthRequestInterceptor,
    authenticate,
    signOut
};

export default security;
