import * as React from 'react';
import Typeahead from '../../_common/typeahead-old';

import { ReactComponent as SearchIcon } from '../../../assets/svg/icons/search.svg';
import { getText } from '../../../helpers';

const placeholder = getText('generic.typeAheadPlaceholder');
const title = getText('generic.search');

function GlobalHeaderSearchOverlayIcon() {
    return (
        <span className="global-header-search-overlay__form-icon">
            <SearchIcon />
        </span>
    );
}

function GlobalHeaderSearchOverlay() {

    React.useEffect(() => {
        document.body.classList.add('kill-scroll');
        document.documentElement.classList.add('kill-scroll');
        return () => {
            document.body.classList.remove('kill-scroll');
            document.documentElement.classList.remove('kill-scroll');
        };
    });

    return (
        <article className="global-header-search-overlay page-section" data-testid="search-overlay">
            <div className="global-header-search-overlay__title-container page-section__inner">
                <h5 className="global-header-search-overlay__title">{title}</h5>
            </div>
            <div className="global-header-search-overlay__bar">
                <article className="global-header-search-overlay__form">
                    <Typeahead
                        iconPrepend={GlobalHeaderSearchOverlayIcon()}
                        name="global-search-overlay"
                        isMobileDropdown
                        className="typeahead--equal-height global-header-search-overlay__input"
                        placeholder={placeholder}
                        autoFocus
                    />
                </article>
            </div>
        </article>
    );
}

export default React.memo(GlobalHeaderSearchOverlay);
