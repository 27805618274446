/**
 *
 * Usage
 *
 * _.debounce(func, [wait=0], [options={}])
 *
 * const debounced = _.debounce(someFunction, 250);
 */

import debounce from 'lodash.debounce';

export default debounce;
