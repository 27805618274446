import DOMPurify from 'dompurify';

const sanitizerOptions = {
    ADD_ATTR: ['target'],
    ADD_TAGS: ['iframe'],
    ALLOW_DATA_ATTR: true,
    FORBID_ATTR: ['style'],
    FORBID_TAGS: ['style'],
    KEEP_CONTENT: true
};

const sanitizer = (str: string, options?: {}) => DOMPurify.sanitize(str, { ...sanitizerOptions, ...options });

export default sanitizer;
