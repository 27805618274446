import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps, RouteProps, useHistory } from 'react-router-dom';
import { get, getConfig } from '../../helpers';
import store from '../../redux/store';

const RedirectToLoginPage = () => {
    const signInUrl = getConfig('urls.mi.ssacs.oktaSignIn');
    const returnUrl = `?mpurl=${encodeURIComponent(window.location.pathname)}`;
    import('../application-insights').then(AppInsights => {
        const appInsights = AppInsights.default;
        if (appInsights) appInsights.flush();
        window.location.href = `${signInUrl + returnUrl}`;
    });
    return <div />;
};

const ProtectedRoute = function ProtectedRoute({ component: Component, ...rest }: RouteProps) {
    const { isLoggedIn } = store.getState().user;
    const history = useHistory();
    const token = localStorage.getItem('token');

    if (!Component) {
        return null;
    }

    if (isLoggedIn) return <Route {...rest} render={(props: RouteComponentProps<{}>) => <Component {...props} />} />;

    return !token ? history.push('/') : RedirectToLoginPage();
};

export default React.memo(ProtectedRoute);
