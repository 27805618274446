import { Base64 } from 'js-base64';
import { getConfig } from '../helpers';

const runningAsServer = process.env.REACT_APP_SPGVARS_IS_SERVER_REQUEST && process.env.REACT_APP_SPGVARS_IS_SERVER_REQUEST === 'true';

export declare const spgVars: string;

function getEnv(baseUrl: string): string {
    if (baseUrl.includes('marketplacedev')) {
        return 'dev';
    }
    if (baseUrl.includes('marketplacestage')) {
        return 'stage';
    }
    return 'prod';
}

export default function loadSPGVariables(): SPGlobalEnv {
    if (process.env.NODE_ENV !== 'production' || process.env.REACT_APP_SPOOF_API === 'true') {
        return getConfig('spgVars');
    }

    if (runningAsServer) {
        return {
            APIGEE_SEARCH_HEADER: '',
            CROWNPEAK_HOST: '',
            CloudFrontURL: '',
            READ_SERVICE_HOST: '',
            DATAVENDORTOEMAIL: '',
            GTM_CONTAINER_ID: '',
            GA_TRACKING_ID: '',
            MARKETO_MUNCHKINID: '',
            ONE_TRUST_COOKIE_GUID: '',
            CLIENTID: '',
            CLIENTSECRET: '',
            REFRESHTOKEN: '',
            TRENDINGDATASETS: [],
            TABLEAU_SECURESITE: '',
            TABLEAU_PUBLICSITE: '',
            SSO_LOGOUT_URL: '',
            SUPPORTED_LANGUAGES: [],
            IS_CRAWLER_REQUEST: false,
            BUILD_VERSION: process.env.REACT_APP_SPGVARS_BUILD_VERSION || '',
            APIBASE: process.env.REACT_APP_SPGVARS_APIBASE || '',
            IS_SERVER_REQUEST: true,
            DEVICE_ISMOBILE: false,
            IS_RENDERTRON_REQUEST: false,
            OKTA_ISSUER_URL: '',
            OKTA_CLIENT_ID: '',
            OKTA_IDP_ID: '',
            LD_CLIENT_ID: '',
            LD_SECRET_HASH: '',
            LD_RELAYPROXY: '',
            ENV: '',
            RATINGSRATEMAILLIST: '',
            INDEXRATEMAILLIST: '',
            SPGLOBALRATEMAILLIST: '',
            SPGVARS_QUERY_BUILDER: false,
            SPGVARS_ENABLE_KENSHO_DEMOS: false,
            SPGVARS_ENABLE_TRANSLATIONS: false,
            SPGVARS_ENABLE_WORKBENCH: false,
            SPGVARS_ENABLE_BLUEPRINT: false,
            COALITIONEMAILLIST: '',
            KENSHOLLMEmailList: '',
            LLMEmailList: '',
            MOBILITYEMAILLIST: ''
        };
    }

    const spgVarsObj: SPGlobalEnv = JSON.parse(Base64.decode(spgVars));
    return {
        APIGEE_SEARCH_HEADER: spgVarsObj.APIGEE_SEARCH_HEADER,
        BUILD_VERSION: spgVarsObj.BUILD_VERSION,
        CROWNPEAK_HOST: spgVarsObj.CROWNPEAK_HOST,
        READ_SERVICE_HOST: spgVarsObj.READ_SERVICE_HOST,
        DATAVENDORTOEMAIL: spgVarsObj.DATAVENDORTOEMAIL,
        GA_TRACKING_ID: spgVarsObj.GA_TRACKING_ID,
        GTM_CONTAINER_ID: spgVarsObj.GTM_CONTAINER_ID,
        MARKETO_MUNCHKINID: spgVarsObj.MARKETO_MUNCHKINID,
        ONE_TRUST_COOKIE_GUID: spgVarsObj.ONE_TRUST_COOKIE_GUID,
        OKTA_ISSUER_URL: spgVarsObj.OKTA_ISSUER_URL,
        OKTA_CLIENT_ID: spgVarsObj.OKTA_CLIENT_ID,
        OKTA_IDP_ID: spgVarsObj.OKTA_IDP_ID,
        CLIENTID: spgVarsObj.CLIENTID,
        CLIENTSECRET: spgVarsObj.CLIENTSECRET,
        REFRESHTOKEN: spgVarsObj.REFRESHTOKEN,
        TRENDINGDATASETS: spgVarsObj.TRENDINGDATASETS,
        TABLEAU_SECURESITE: spgVarsObj.TABLEAU_SECURESITE,
        TABLEAU_PUBLICSITE: spgVarsObj.TABLEAU_PUBLICSITE,
        SUPPORTED_LANGUAGES: spgVarsObj.SUPPORTED_LANGUAGES,
        IS_CRAWLER_REQUEST: spgVarsObj.IS_CRAWLER_REQUEST,
        APIBASE: spgVarsObj.APIBASE,
        SSO_LOGOUT_URL: spgVarsObj.SSO_LOGOUT_URL,
        IS_SERVER_REQUEST: spgVarsObj.IS_SERVER_REQUEST,
        DEVICE_ISMOBILE: spgVarsObj.DEVICE_ISMOBILE,
        SSR: spgVarsObj.SSR,
        IS_RENDERTRON_REQUEST: spgVarsObj.IS_RENDERTRON_REQUEST,
        LD_CLIENT_ID: spgVarsObj.LD_CLIENT_ID,
        LD_SECRET_HASH: spgVarsObj.LD_SECRET_HASH,
        LD_RELAYPROXY: spgVarsObj.LD_RELAYPROXY,
        ENV: getEnv(spgVarsObj.APIBASE.toLowerCase()),
        RATINGSRATEMAILLIST: spgVarsObj.RATINGSRATEMAILLIST,
        INDEXRATEMAILLIST: spgVarsObj.INDEXRATEMAILLIST,
        SPGLOBALRATEMAILLIST: spgVarsObj.SPGLOBALRATEMAILLIST,
        SPGVARS_QUERY_BUILDER: spgVarsObj.SPGVARS_QUERY_BUILDER,
        SPGVARS_ENABLE_KENSHO_DEMOS: spgVarsObj.SPGVARS_ENABLE_KENSHO_DEMOS,
        SPGVARS_ENABLE_TRANSLATIONS: spgVarsObj.SPGVARS_ENABLE_TRANSLATIONS,
        SPGVARS_ENABLE_WORKBENCH: spgVarsObj.SPGVARS_ENABLE_WORKBENCH,
        SPGVARS_ENABLE_BLUEPRINT: spgVarsObj.SPGVARS_ENABLE_BLUEPRINT,
        COALITIONEMAILLIST: spgVarsObj.COALITIONEMAILLIST,
        CloudFrontURL: spgVarsObj.CloudFrontURL,
        KENSHOLLMEmailList: spgVarsObj.KENSHOLLMEmailList,
        LLMEmailList: spgVarsObj.LLMEmailList,
        MOBILITYEMAILLIST: spgVarsObj.MOBILITYEMAILLIST
    };
}
