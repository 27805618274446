import APIManager from './api-manager';
import { get } from '../helpers';
import Store from '../redux/store';

export default function logUsage(keyPage: number, internetTransferDuration = 0) {
    if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_TRACKING === 'true') {
        const isLoggedIn = get(Store.getState(), 'user.isLoggedIn', false);
        if (isLoggedIn) {
            APIManager.logUsage({
                keyPage,
                queryString: window.location.pathname + window.location.search,
                internetTransferDuration: internetTransferDuration.toString()
            });
        }
    } else if (process.env.NODE_ENV === 'development') {
        console.warn(`Logging hit skipped - KeyPage:${keyPage} - Page: ${window.location.pathname + window.location.search}`);
    }
}
