import update from 'react-addons-update';
import { API_FULL_BLUEPRINT, API_INITIAL_BLUEPRINTS } from '../actionTypes';

const initialBlueprintsState: BlueprintsStoreState = {
    full: {},
    all: {}
};

// TODO - remove any
// eslint-disable-next-line
const reducer = (state: BlueprintsStoreState = initialBlueprintsState, incomingAction: BlueprintsResultAction | BlueprintResultAction) => {
    const action = incomingAction;
    switch (action.type) {
        case API_INITIAL_BLUEPRINTS: {
            const actionObj = action as BlueprintsResultAction;
            return update(state, { all: { response: { $set: actionObj.response }, time: { $set: actionObj.time } } });
        }
        case API_FULL_BLUEPRINT: {
            const actionObj = action as BlueprintResultAction;
            // console.log('fetchFullBlueprint.async.done', state, actionObj.type, actionObj.response);
            return update(state, { full: { [actionObj.id]: { $set: { response: actionObj.response } } } });
        }
        default:
            return state || initialBlueprintsState;
    }
};

export { reducer, initialBlueprintsState };
