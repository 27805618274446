const mapDatasetToDataProfileCard = (item: APIDataset, nameItems: APIDataset[]) => {
    const englishNameItem = nameItems.length > 0 && nameItems.find(nameItem => nameItem.KeyMarketplaceDataset === item.KeyMarketplaceDataset);
    return {
        name: item.MarketplaceDatasetName,
        absoluteName: englishNameItem ? englishNameItem.MarketplaceDatasetName : item.MarketplaceDatasetName,
        id: item.KeyMarketplaceDataset,
        isBundle: false,
        addedDate: item.MarketplaceDatasetAdded,
        enhancementDate: item.MktPlaceDataSetEnhancementDate,
        shortDescription: item.MarketplaceShortDescription,
        thumbnailImageFilePath: item.ThumbnailImageFilePath, // tile image
        longDescription: item.MarketplaceLongDescription,
        dataProvider: item.MarketplaceDataProvider,
        active: item.ActiveMPDatasetCoverage,
        earliestRecord: [item.EarliestRecord],
        pointInTime: [item.PointInTime],
        supportDocs: item.MarketDatasetSupportDocs ? item.MarketDatasetSupportDocs : [],
        categories: item.MarketDatasetSearchTags ? item.MarketDatasetSearchTags.map(category => category.KeyMarketplaceSearchTag) : [],
        industries: item.MarketDatasetMIIndustrys ? item.MarketDatasetMIIndustrys.map(industry => industry.KeyMIIndustryTree) : [],
        geographies: item.MarketDatasetGeographys ? [item.MarketDatasetGeographys.map(geography => geography.KeyGeographyTree)] : [],
        deliveryChannel: item.MarketDatasetDestPlatforms && item.MarketDatasetDestPlatforms.map(x => x.KeySPGDestinationPlatform),
        marketMSADataLink: item.MSADataLink,
        marketMSADataFilePath: item.MSADataFilePath,
        marketplaceMSATooltip: item.MarketplaceMSATooltip
    };
};
export default mapDatasetToDataProfileCard;
