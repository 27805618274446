/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { connect } from 'react-redux';
import Media from 'react-media';
import LeftMenuItem from './blackhat-left-menu-item';
import RightMenuItem from './blackhat-right-menu-item';
import { getConfig, getText, get } from '../../../helpers';
import SPGVariables from '../../../utilities/spg-variables';

const data = getText('blackHeader');
const spgVariables = SPGVariables();
const supportedLangs = spgVariables.SUPPORTED_LANGUAGES;
const BlackhatHeader = function BlackhatHeader(props: FeatureFlags) {

    const { enableTranslations } = props;
    const [siteNavVisible, siteNavOpen] = React.useState<boolean>(false);
    const [investorNavVisible, investorNavOpen] = React.useState<boolean>(false);
    const [supportNavVisible, supportNavOpen] = React.useState<boolean>(false);
    const [siteIsDesktop, setSiteDesktop] = React.useState<boolean>(false);
    const [languageNavVisible, languageNavOpen] = React.useState<boolean>(false);
    const dropdownOne = React.useRef<HTMLLIElement>(null);
    const dropdownTwo = React.useRef<HTMLLIElement>(null);
    const dropdownThree = React.useRef<HTMLLIElement>(null);
    const dropdownFour = React.useRef<HTMLLIElement>(null);
    const breakPoint = getConfig('routes.homepage.blackhatBreakpoint');

    const handleResize = () => {
        const curWidth = window.innerWidth;
        if (curWidth > breakPoint && !siteIsDesktop) {
            setSiteDesktop(true);
        } else if (curWidth <= breakPoint && siteIsDesktop) {
            setSiteDesktop(false);
        }
    };

    handleResize();

    const handleClickAway = (event: Event) => {
        if (dropdownOne.current && event.target !== null) {
            if (!dropdownOne.current.contains(event.target as Node)) {
                siteNavOpen(false);
            }
        }
        if (dropdownTwo.current && event.target !== null) {
            if (!dropdownTwo.current.contains(event.target as Node)) {
                investorNavOpen(false);
            }
        }
        if (dropdownThree.current && event.target !== null) {
            if (!dropdownThree.current.contains(event.target as Node)) {
                supportNavOpen(false);
            }
        }
        if (dropdownFour.current && event.target !== null) {
            if (!dropdownFour.current.contains(event.target as Node)) {
                languageNavOpen(false);
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);
        document.addEventListener('click', handleClickAway);
        document.addEventListener('touchend', handleClickAway);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('click', handleClickAway);
            document.removeEventListener('touchend', handleClickAway);
        };
    });

    const getLangURL = (lancCode: string) => {
        return `${window.location.origin}/${lancCode}/`;
    };

    const languages = [
        { label: 'English', link: getLangURL('en'), langCode: 'en' },
        { label: '中文', link: getLangURL('zh'), langCode: 'zh' },
        { label: '日本語', link: getLangURL('ja'), langCode: 'ja' }
    ];

    return (

        <div className="top-navigation">
            <div className="page-section__inner">
                <nav className="top-navigation-container">
                    <div className="top-navigation-container__left" aria-label="company Dropdown">
                        {(spgVariables.IS_CRAWLER_REQUEST || !spgVariables.IS_RENDERTRON_REQUEST) && (
                            <LeftMenuItem
                                nodeRef={dropdownOne}
                                onClick={siteNavOpen}
                                isVisible={siteNavVisible || siteIsDesktop}
                                navOpenMobile={siteNavVisible}
                                title={data.siteMenuName}
                                menuItems={data.siteNav}
                            />
                        )}

                        <span className="sites-link__info">Discover more about S&P Global’s offerings</span>
                    </div>
                    <Media query={{ minWidth: 1024 }}>
                        <div className="top-navigation-container__right">
                            <div>
                                <RightMenuItem
                                    nodeRef={dropdownTwo}
                                    onClick={investorNavOpen}
                                    isVisible={investorNavVisible}
                                    navOpenMobile={investorNavVisible}
                                    title={data.investorMenuName}
                                    menuItems={data.investorNav}
                                    classNameProp="investor"
                                />
                            </div>
                            {enableTranslations && supportedLangs.length > 1 && (
                                <div>
                                    <RightMenuItem
                                        nodeRef={dropdownFour}
                                        onClick={languageNavOpen}
                                        isVisible={languageNavVisible}
                                        navOpenMobile={languageNavVisible}
                                        title={getText('generic.languages')}
                                        menuItems={languages.filter(x => supportedLangs.includes(x.langCode))}
                                        isLangSelector
                                        classNameProp="language"
                                    />
                                </div>
                            )}
                            <div>
                                <RightMenuItem
                                    nodeRef={dropdownThree}
                                    onClick={supportNavOpen}
                                    isVisible={supportNavVisible}
                                    navOpenMobile={supportNavVisible}
                                    title={data.supportMenuName}
                                    menuItems={data.supportNav}
                                    classNameProp="support"
                                />
                            </div>

                        </div>
                    </Media>
                </nav>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootStoreState): FeatureFlags => {
    return {
        enableTranslations: get(state, 'shared.flags.enableTranslations')
    };
};

export default connect(mapStateToProps)(React.memo(BlackhatHeader));
