import * as React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { cn } from '../../../helpers';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        alignItems: 'start'
    },
    success: {
        backgroundColor: green[600] // default Material-UI colors can be changed later for branding
    },
    error: {
        backgroundColor: '#D6002A'
    },
    info: {
        backgroundColor: theme.palette.primary.main // default Material-UI colors can be changed later for branding
    },
    warning: {
        backgroundColor: amber[700] // default Material-UI colors can be changed later for branding
    },
    icon: {
        fontSize: theme.typography.pxToRem(25)
    },
    iconClose: {
        padding: theme.typography.pxToRem(5)
    },
    iconVariant: {
        marginRight: theme.typography.pxToRem(15)
    },
    message: {
        display: 'flex',
        maxWidth: '300px'
    }
}));

function Notification({ className, message, variant, position, onClosed }: NotificationProps) {
    const classes = useStyles();
    const Icon = variantIcon[variant];
    const [open, setClose] = React.useState(true);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return;
        setClose(false);
        if (onClosed) onClosed();
    };

    return (
        <div data-testid={`notification-${variant}`} className={cn(`notification notification--${variant}`, className)}>
            <Snackbar
                anchorOrigin={{
                    ...position
                }}
                open={open}
                onClose={handleClose}
            >
                <SnackbarContent
                    className={cn(classes[variant], classes.container)}
                    aria-describedby={`${variant} notification`}
                    message={
                        <span id={`client-${variant}-notification`} className={classes.message}>
                            <Icon className={cn(classes.icon, classes.iconVariant)} />
                            {message}
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose} className={classes.iconClose}>
                            <CloseIcon className={classes.icon} titleAccess="close" />
                        </IconButton>
                    ]}
                />
            </Snackbar>
        </div>
    );
}

export default React.memo(Notification);
