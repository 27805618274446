import * as React from 'react';
import Media from 'react-media';
import SPGVariables from '../../utilities/spg-variables';
import Desktop from './global-header-desktop';
import Mobile from './global-header-mobile';

const spgVariables = SPGVariables();

function GlobalHeader(props: FeatureFlags) {
    const handleSearchIconClick = () => {
        const event = new Event('HeaderSearchClick');
        document.dispatchEvent(event);
    };

    const handleHeaderBodyClick = () => {
        const event = new Event('HeaderBodyClick');
        document.dispatchEvent(event);
    };

    if (spgVariables.DEVICE_ISMOBILE) return <Mobile {...props} />;
    return (
        <>
            <Media query={{ minWidth: 1024 }}>
                <Desktop {...props} onSearchIconClick={handleSearchIconClick} onHeaderBodyClick={handleHeaderBodyClick} />
            </Media>
            <Media query={{ maxWidth: 1023 }}>
                <Mobile {...props} onSearchIconClick={handleSearchIconClick} onHeaderBodyClick={handleHeaderBodyClick} />
            </Media>
        </>
    );
}

export default React.memo(GlobalHeader);
