import { getConfig } from '.';

const flagCutOff = getConfig('components.flagVisibilityCutoffTime');

const getFlagType = (addedDate?: string, enhancedDate?: string): string => {
    const dateTodayMinus90 = new Date().getTime() - flagCutOff;
    const dateToday = new Date().getTime();

    const dateDatasetAdded = addedDate && Date.parse(addedDate);
    const dateDatasetEnhanced = enhancedDate && Date.parse(enhancedDate);

    if (dateDatasetAdded && dateDatasetAdded > dateToday) {
        return 'coming-soon';
    }
    if (dateDatasetAdded && dateDatasetAdded > dateTodayMinus90 && dateDatasetAdded <= dateToday) {
        return 'recently-added';
    }
    if (dateDatasetEnhanced && (dateDatasetEnhanced > dateTodayMinus90 && dateDatasetEnhanced < dateToday)) {
        return 'recently-enhanced';
    }
    return 'default';
};
export default getFlagType;
