/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import SPGVariables from '../../../utilities/spg-variables';

const spgVariables = SPGVariables();
const languages = spgVariables.SUPPORTED_LANGUAGES;
const RightMenuItem = function RightMenuItem(props: BlackhatMenuItemProps) {
    const { nodeRef, onClick, isVisible, navOpenMobile, title, classNameProp, menuItems, isLangSelector, onMobileClick } = props;

    const handleItemClick = (event: React.SyntheticEvent, url: string, text: string, langCode = 'en') => {
        if (isLangSelector) {
            event.preventDefault();
            const langRegex = new RegExp(
                languages
                    .map((lang: string) => {
                        return `/${lang}/|/${lang}`;
                    })
                    .join('|')
            );
            // remove filters when language changed
            let search = window.location.search
                .split('&')
                .filter(str => !str.includes('filters='))
                .join('&')
                .replace('?', '');
            search = search.length > 0 ? `?${search}` : search;
            const lang = `/${langCode}/`;
            const href = `${window.location.origin}${window.location.pathname.replace(langRegex, lang)}${search}`;
            window.location.href = `${href}`;
        }
    };
    const handleMenuClick = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (onMobileClick) onMobileClick(classNameProp);
        else onClick(!navOpenMobile);
    };
    const getLangName = (langCode: string) => {
        switch (langCode) {
            case 'zh':
                return 'Chinese';
            case 'ja':
                return 'Japanese';
            default:
                return '';
        }
    };

    return (
        <div ref={nodeRef} className="utility-dropdown" aria-label="s&amp;p global Divisions">
            <a href="#" aria-haspopup="true" id="right" aria-expanded={navOpenMobile} className="linkCta" lang="en-us" onClick={handleMenuClick}>
                {title}
            </a>
            { isVisible && navOpenMobile && (
                <ul className={`utility-dropdown-data ${classNameProp}`} aria-expanded={navOpenMobile}>
                    {menuItems.map(item => {
                        return (
                            <li key={item.label} role="menuitem">
                                <a
                                    href={item.link}
                                    title=""
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    onClick={event => handleItemClick(event, item.link, item.label, item.langCode)}
                                >
                                    {item.label}
                                    {(item.langCode !== 'en') ? <span className="x-screen-reader"> {getLangName(item.langCode)} </span> : ''}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>

    );
};

export default React.memo(RightMenuItem);
