const mapDatasetToDataProfile = (dataset: APIDataset) => {
    const datasetLogos: MarketDatasetLogos[] = [];
    if (dataset.MarketDatasetLogos) {
        dataset.MarketDatasetLogos.forEach((logoObj: MarketDatasetLogos) => {
            const logo: MarketDatasetLogos = {
                OnlineImageFilePath: logoObj.OnlineImageFilePath,
                FormOrder: logoObj.FormOrder,
                DataProvider: dataset.MarketplaceDataProvider
            };
            datasetLogos.push(logo);
        });
    }

    let deliveryChannels: number[] = [];
    if (dataset.MarketDatasetDestPlatforms && dataset.MarketDatasetDestPlatforms.length > 0) {

        deliveryChannels = dataset.MarketDatasetDestPlatforms
            .sort()
            .map(x => {
                return x.KeySPGDestinationPlatform;
            });
    }

    let deliveryPlatforms: number[] = [];
    if (dataset.MarketDatasetDelivPlatforms && dataset.MarketDatasetDelivPlatforms.length > 0) {
        deliveryPlatforms = dataset.MarketDatasetDelivPlatforms
            .sort()
            .map(x => {
                return x.KeySPGDestinationPlatform;
            });
    }

    const marketDatasetDatasets = dataset.MarketDatasetDatasets && dataset.MarketDatasetDatasets.filter(relDataset => {
        if (!relDataset.MarketplaceDatasetRelated) return true;
        return relDataset.MarketplaceDatasetRelated.ActiveMPDatasetCoverage;
    }).map(relData => {
        return { KeyMarketplaceDatasetRelated: relData.KeyMarketplaceDatasetRelated, KeyMarketplaceDatasetBundle: relData.KeyMarketplaceDatasetBundle, FormOrder: relData.FormOrder } as MarketDatasetDataset;
    });

    return {
        id: dataset.KeyMarketplaceDataset,
        active: dataset.ActiveMPDatasetCoverage,
        isBundle: false,
        addedDate: dataset.MarketplaceDatasetAdded,
        enhancementDate: dataset.MktPlaceDataSetEnhancementDate,
        name: dataset.MarketplaceDatasetName,
        shortDescription: dataset.MarketplaceShortDescription,
        thumbnailImageFilePath: dataset.ThumbnailImageFilePath,
        dataProvider: dataset.MarketplaceDataProvider,
        dataProviderDesc: dataset.MarketplaceDataProviderDesc,
        earliestRecord: dataset.EarliestRecord,
        pointInTime: dataset.PointInTime,
        longDescription: dataset.MarketplaceLongDescription,
        onlineImageFilePath: dataset.OnlineImageFilePath,
        releaseNote: dataset.MktPlaceDataSetEnhancementDesc,
        releaseNoteLink: dataset.MktPlaceDataSetEnhancementLink,
        socialSharingImageFilePath: dataset.SocialSharingImageFilePath,
        earliestSignificantCoverage: dataset.EarliestSignificantCoverage,
        estimatedDatasetSize: dataset.EstimatedDatasetSize,
        estimatedDatasetStorage: dataset.EstimatedDatasetStorage,
        marketplaceDatasetCoverageDesc: dataset.MarketplaceDatasetCoverageDesc,
        marketplaceDataSource: dataset.MarketplaceDataSource,
        overviewImageFilePath: dataset.OverviewImageFilePath,
        pointInTimeDescription: dataset.PointInTimeDescription,
        marketDatasetDatasets,
        marketplaceTrialButtonTitle: dataset.MarketplaceTrialButtonTitle,
        marketDatasetSearchTags: dataset.MarketDatasetSearchTags,
        marketDatasetMIIndustrys: dataset.MarketDatasetMIIndustrys,
        marketDatasetGeographys: dataset.MarketDatasetGeographys,
        marketDatasetLinks: dataset.MarketDatasetLinks,
        marketplaceDatasetFieldCount: dataset.MarketplaceDatasetFieldCount,
        marketDatasetVisualization: dataset.MarketDatasetVizs,
        marketDatasetNotebooks: dataset.MarketDatasetNotebooks,
        spgCoverageType: dataset.SPGCoverageType,
        spgDatasetFrequency: dataset.SPGDatasetFrequency,
        spgDatasetLatency: dataset.SPGDatasetLatency,
        sampleDataLink: dataset.SampleDataLink,
        sampleDataFilePath: dataset.SampleDataFilePath,
        dataDictionaryLink: dataset.DataDictionaryLink,
        marketDatasetResearchs: dataset.MarketDatasetResearchs,
        supportDocs: dataset.MarketDatasetSupportDocs,
        marketDatasetLogos: datasetLogos,
        hasAlert: dataset.ActiveMarketplaceDatasetNote || false,
        alertNote: dataset.MarketplaceDatasetNote || '',
        alertTitle: dataset.MarketplaceDatasetNoteTitle || '',
        marketMSADataLink: dataset.MSADataLink,
        marketMSADataFilePath: dataset.MSADataFilePath,
        marketplaceMSATooltip: dataset.MarketplaceMSATooltip,
        mktplaceSolutionSupportEmail: dataset.MktplaceSolutionSupportEmail,
        marketSPGDestinationPlatform: deliveryChannels,
        marketSPGDeliveryPlatform: deliveryPlatforms,
    } as DataProfile;
};
export default mapDatasetToDataProfile;
