import getConfig from './get-config';

const name = 'filter Builder';
const filterOptions = getConfig('components.filterOptions');
const keyRegion: string = filterOptions.region.keyColumn;
const keyPointInTime: string = filterOptions.pointInTime.keyColumn;
const keyIndustry: string = filterOptions.industry.keyColumn;
const keyCategory: string = filterOptions.category.keyColumn;
const keyHistory: string = filterOptions.history.keyColumn;
const keyDelivery: string = filterOptions.delivery.keyColumn;
const keyDeliveryPlatform: string = filterOptions.deliveryPlatform.keyColumn;
const keyVendor: string = filterOptions.vendor.keyColumn;

const filterBuilder = (filters: string[]) => {
    const pointInTime: string[] = [];
    const delivery: string[] = [];
    const deliveryPlatform: string[] = [];
    const industry: string[] = []; // Include zero for dataset has industry as all
    const category: string[] = [];
    const region: string[] = [];
    const history: string[] = [];
    const vendor: string[] = [];
    const filter: string[] = [];
    filters.forEach((filterData: string) => {
        if (filterData.split(':')[0] === keyRegion) {
            const keyValue: string[] = filterData.split(':');
            const GeographyArray: number[] = keyValue[1].split(',').map(value => parseInt(value, 0));
            if (GeographyArray.length === 1) {
                region.push(filterData);
            } else {
                // for Global we need to dataset should has all keys
                region.push(`${keyRegion}:(${GeographyArray.join(' AND ')})`);
            }
        }
        if (filterData.split(':')[0] === keyDelivery) delivery.push(filterData);
        if (filterData.split(':')[0] === keyPointInTime) pointInTime.push(filterData);
        if (filterData.split(':')[0] === keyDeliveryPlatform) deliveryPlatform.push(filterData);
        if (filterData.split(':')[0] === keyIndustry) industry.push(filterData);
        if (filterData.split(':')[0] === keyCategory) category.push(filterData);
        if (filterData.split(':')[0] === keyHistory) history.push(filterData);
        if (filterData.split(':')[0] === keyVendor) vendor.push(filterData);
    });
    if (pointInTime.length > 0) filter.push(pointInTime.join(' OR '));
    if (delivery.length > 0) filter.push(delivery.join(' OR '));
    if (deliveryPlatform.length > 0) filter.push(deliveryPlatform.join(' OR '));
    if (industry.length > 0) filter.push(industry.join(' OR '));
    if (category.length > 0) filter.push(category.join(' OR '));
    if (history.length > 0) filter.push(history.join(' OR '));
    if (region.length > 0) filter.push(region.join(' OR '));
    if (vendor.length > 0) filter.push(vendor.join(' OR '));
    return filter;
};

export { name as default, filterBuilder };
