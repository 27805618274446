export default (str: string) => {
    return str
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/-a-/g, '-') // Replace '-a-' with '-'
        .replace(/\w+/g, x => ((x === 'the' || x === 'and') ? '' : x)) // Replace 'and' and 'the' with ''
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};
