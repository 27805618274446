const isInternalUser = (allowedList: string[], userEmail: string) => {
    // Hide sensitive solutions for external users
    let arr = [];
    if (allowedList && allowedList.length > 0 && userEmail) {
        arr = allowedList.filter(x => x && userEmail.toLowerCase().indexOf(x.toLowerCase(), userEmail.length - x.length) !== -1);
    }
    return arr.length > 0;
};

export default isInternalUser;
