import { addTask } from 'domain-task';
import { get, getConfig, dateDiff, getText } from '../../helpers';
import APIManager from '../../utilities/api-manager';
import { API_FULL_QUERYLIBRARY, API_QL_RELATED_QUERIES } from '../actionTypes';

const apiDataRefreshTime = getConfig('redux.cacheRefreshTimes.long');

const fetchFullQueryLibrary = (params: QueryLibraryParams): AppThunkAction<QueryLibraryProfileResultAction> => (dispatch, getState) => {
    const { queryLibrary } = getState();
    const { id } = params;
    const cachedResponse = get(queryLibrary, `full.${id}`, {});
    const hasExpired = cachedResponse && (!cachedResponse.response ? true : dateDiff(cachedResponse.response.CachedTime) > apiDataRefreshTime);
    if (!id || !cachedResponse || hasExpired) {
        const fetchTask = APIManager.getQueryLibraryProfileById(id).then(response => {
            dispatch({ response: { ...response.data, CachedTime: new Date() }, type: API_FULL_QUERYLIBRARY, id });
        });
        addTask(fetchTask);
    }
};

const fetchQueryLibraryRelatedQueries = (params: QueryLibraryParams): AppThunkAction<QueryLibraryRelatedQueriesResultAction> => (dispatch, getState) => {
    const { queryLibrary } = getState();
    const { id } = params;
    const cachedResponse = get(queryLibrary, `relatedQueries.${id}`, {});
    const hasExpired = cachedResponse && (!cachedResponse.response ? true : dateDiff(cachedResponse.response.CachedTime) > apiDataRefreshTime);
    if (!id || !cachedResponse || hasExpired) {
        const fetchTask = APIManager.getQueryLibraryRelatedQueriesById(id).then(response => {
            dispatch({ response: { ...response.data, CachedTime: new Date() }, type: API_QL_RELATED_QUERIES, id });
        });
        addTask(fetchTask);
    }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchFullQueryLibrary, fetchQueryLibraryRelatedQueries };
