import * as React from 'react';
import { cn } from '../../../helpers';
import { ReactComponent as CaretRightIcon } from '../../../assets/svg/icons/caret-right.svg';

function Button(
    {
        title,
        text,
        theme = 'secondary',
        isUpperCase,
        isFullWidth,
        hasIcon,
        className,
        onClick,
        type = 'button',
        isDisabled,
        gtmId = '',
        gtmLabel = ''
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
) {
    const buttonClass = cn('button', className, {
        'button--fullwidth': isFullWidth,
        'button--uppercase': isUpperCase,
        'button--titled': title,
        [`button--${theme}`]: theme
    });

    const buttonTestId = !className ? 'button' : className;

    const buttonInner = (
        <>
            {text}
            {hasIcon && <CaretRightIcon className="button__icon" />}
        </>
    );
    if (type === 'submit') {
        return (
            <button type="submit" disabled={isDisabled} className={buttonClass} onClick={onClick} ref={ref} data-testid={buttonTestId} data-gtm-id={gtmId} data-gtm-label={gtmLabel}>
                {title && (
                    <>
                        <span className="button__title">{title}</span>
                        <span>{buttonInner}</span>
                    </>
                )}
                {!title && buttonInner}
            </button>
        );
    }
    const handleClick = (event: React.SyntheticEvent) => {
        if (onClick) onClick(event);
    };

    return (
        <button type="button" disabled={isDisabled} className={buttonClass} onClick={handleClick} ref={ref} data-testid={buttonTestId} data-gtm-id={gtmId} data-gtm-label={gtmLabel}>
            {title && (
                <>
                    <span className="button__title">{title}</span>
                    <span>{buttonInner}</span>
                </>
            )}
            {!title && buttonInner}
        </button>
    );
}

export default React.memo(React.forwardRef(Button));
