/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useClickAway, useDebounce } from 'react-use';
import Spinner from '../spinner';
import TypeaheadDropdown from './typeahead-dropdown';
import CustomLink from '../custom-link';
import APIManager from '../../../utilities/api-manager';
import History from '../../../utilities/history';
import { cn, getConfig, getText, get, authdata } from '../../../helpers';
import { mapSearchResultToDataProfileCard, mapSearchResultToSolution, mapSearchResultToBluePrint } from '../../../utilities/data-mappers';
import { ModalContext } from '../modal/modal';

import './typeahead.style.scss';
import authdatablueprint from '../../../helpers/auth-data-blueprint';

const langCode = getText('langCode');

const Window = window as AnonymousObject;
Window.dataLayer = Window.dataLayer || [];

const UnstyledTextInput = withStyles({
    root: {
        'flex-direction': 'row',
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:after': {
            content: 'none'
        }
    }
})(TextField);

const throttleDuration = getConfig('components.typeaheadThrottleDuration');
const seeAllResults = getText('generic.seeAllResults');

function Typeahead(props: TypeaheadProps) {
    const {
        iconPrepend,
        iconAppend,
        name,
        className,
        placeholder,
        onFocusEvent,
        isMobileDropdown,
        autoFocus = false,
        autoClose = false,
        onComplete
    } = props;

    const ua = navigator.userAgent; // prevent autofocus on IE11
    const safeAutoFocus = ua.indexOf('Trident/') > -1 ? false : autoFocus;
    const user = useSelector((state: RootStoreState) => get(state, 'user', ''));
    const enabledBlueprintFlag = useSelector((state: RootStoreState) => get(state, 'shared.flags.enabledBlueprintFlag', false));
    const userEmail = user && user.userInfo && user.userInfo.Email ? user.userInfo.Email : '';
    const [response, setResponse] = React.useState<(DataProfileCard | APISolution | APIBlueprint)[]>([]);
    const [searchValue, setSearchValue] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    // debugging state to avoid typeahead closing on init
    // somewhere is click event, that triggers useClickAway hook on init
    const [isFirstClickEvent, setIsFirstClickEvent] = React.useState<boolean>(true);

    const textFieldRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const textFieldAndDropdownRef: React.RefObject<HTMLDivElement> = React.useRef(null);

    //  CONTEXT
    const onCloseContext = React.useContext(ModalContext);

    const reset = () => {
        // console.log('RESET');
        setLoading(false);
        setSearchValue('');
        setResponse([]);
        if (onComplete) onComplete();
        onCloseContext();
    };

    const handleScroll = (w: Waypoint.CallbackArgs) => {
        if (w.viewportTop > w.waypointTop) {
            reset();
            if (textFieldRef && textFieldRef.current) textFieldRef.current.blur();
        }
    };

    const handleOnFocus = (event: React.FocusEvent) => {
        if (onFocusEvent) onFocusEvent(event);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { value } = target;
        const regex = /^\s+/g;
        const newSearch = value.replace(regex, '');
        setSearchValue(newSearch);
        if (newSearch.length) {
            setLoading(true);
        } else {
            setResponse([]);
            setLoading(false);
        }
    };

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        reset();
        if (!searchValue.length) History.push(`/${langCode}/search-results`);
        else History.push(`/${langCode}/search-results?search=${encodeURIComponent(searchValue.trim())}`);
    };

    useDebounce(
        () => {
            if (searchValue.length) {
                APIManager.search(searchValue.trim(), undefined).then(res => {
                    const data = get(res, 'data.hits.hits', []).map((hit: Hit) => {
                        if (hit._source.isType === 'Solution') {
                            const x = mapSearchResultToSolution(hit._source);
                            const arr = authdata([x as APISolution], userEmail);
                            if (arr && arr.length > 0) {
                                return x;
                            }
                            return null;
                        }
                        if (hit._source.isType === 'Blueprint') {
                            const x = mapSearchResultToBluePrint(hit._source);
                            const arr = authdatablueprint([x as unknown as APIBlueprint], userEmail);
                            if (arr && arr.length > 0) {
                                return x;
                            }
                            return null;
                        }
                        return mapSearchResultToDataProfileCard(hit._source);
                    });

                    let updatedData = [];
                    updatedData = data.filter((x: (DataProfileCard | APISolution | APIBlueprint)) => !!x);
                    if (!enabledBlueprintFlag) {
                        updatedData = updatedData.filter((x: any) => x.KeyMarketplaceBlueprint === undefined);
                    }

                    Window.dataLayer.push({
                        event: 'searchSubmit',
                        searchTerm: searchValue,
                        searchResultsCount: updatedData.length
                    });

                    setResponse(updatedData);
                    setLoading(false);
                });
            }
        },
        throttleDuration,
        [searchValue]
    );

    useClickAway(textFieldAndDropdownRef, () => {
        if (!isFirstClickEvent) {
            reset();
        } else {
            setIsFirstClickEvent(false);
        }
    }, ['click']);

    return (
        <div ref={textFieldAndDropdownRef} className="typeahead-old__wrapper">
            <form action="/search-results" noValidate className={cn('typeahead-old', className)} onSubmit={handleOnSubmit}>
                {iconPrepend}
                <div className="typeahead-old__input">
                    <Waypoint onLeave={(w: Waypoint.CallbackArgs) => handleScroll(w)}>
                        <UnstyledTextInput
                            type="search"
                            autoFocus={safeAutoFocus}
                            autoComplete="off"
                            fullWidth
                            id={name}
                            name={name}
                            onChange={handleOnChange}
                            onFocus={handleOnFocus}
                            placeholder={placeholder}
                            value={searchValue}
                            inputRef={textFieldRef}
                            inputProps={{
                                style: {
                                    height: '100%'
                                },
                                'aria-label': placeholder,
                                'data-testid': 'typeahead-input'
                            }}
                        />
                    </Waypoint>
                </div>
                <Spinner disable={!loading} />
                {iconAppend}
            </form>

            {!autoClose && (
                <TypeaheadDropdown
                    dataProfiles={response}
                    inputRef={textFieldRef}
                    isMobileDropdown={isMobileDropdown}
                    searchToken={searchValue}
                    loading={loading}
                    onClick={reset}
                    parentRef={textFieldAndDropdownRef}
                    searchFilter=""
                />
            )}

            {response.length > 5 && isMobileDropdown && (
                <div className="typeahead-dropdown-old__mobile-button__container">
                    <CustomLink
                        buttonTheme="tertiary"
                        className="typeahead-dropdown-old__mobile-button"
                        isButton
                        isFullWidth
                        text={`${seeAllResults} (${response.length})`}
                        url={`/search-results?search=${encodeURIComponent(searchValue.trim())}`}
                        onClick={reset}
                    />
                </div>
            )}
        </div>
    );
}

export default React.memo(Typeahead);
