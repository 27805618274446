import { addTask } from 'domain-task';
import { get, getConfig, dateDiff, getText } from '../../helpers';
import APIManager from '../../utilities/api-manager';
import { API_INITIAL_SOLUTIONS, API_FULL_SOLUTION } from '../actionTypes';

const apiDataRefreshTime = getConfig('redux.cacheRefreshTimes.long');

const fetchInitialSolutions = (): AppThunkAction<SolutionsResultAction> => (dispatch, getState) => {
    const { solutions } = getState();
    const cachedResponse = get(solutions, 'all');
    const hasExpired = cachedResponse && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime);
    if (hasExpired) {
        const isDefaultLanguage = getText('isDefaultLanguage'); // making it local as SSR needs to fetch this for each store

        const fetchTask = APIManager.getSolutions().then(response => {
            let apiSolutions = response[0];
            if (!isDefaultLanguage && response.length > 1) {
                apiSolutions = response[0].map((item: APISolution) => {
                    const nameitem = response[1].find((resitem: { KeyPlatformService: string }) => resitem.KeyPlatformService === item.KeyPlatformService);
                    item.AbsoluteSolutionName = nameitem && nameitem.MktplaceSolutionName ? nameitem.MktplaceSolutionName : item.MktplaceSolutionName;
                    return item;
                });
            }
            dispatch({ response: { value: apiSolutions }, time: new Date(), type: API_INITIAL_SOLUTIONS });
        });
        addTask(fetchTask);
    }
};

const fetchFullSolution = (params: SolutionsParams): AppThunkAction<SolutionResultAction> => (dispatch, getState) => {
    const { solutions } = getState();
    const { id } = params;
    const cachedResponse = get(solutions, `full.${id}`, {});
    const hasExpired = cachedResponse && (!cachedResponse.response ? true : dateDiff(cachedResponse.response.CachedTime) > apiDataRefreshTime);
    if (!id || !cachedResponse || hasExpired) {
        const fetchTask = APIManager.getSolutionDetailsById(id).then(response => {
            dispatch({ response: { ...response.data, CachedTime: new Date() }, type: API_FULL_SOLUTION, id });
        });
        addTask(fetchTask);
    }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchInitialSolutions, fetchFullSolution };
