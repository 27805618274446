/**
 * * Action Types
 * * It is a requirement to export all action type constants to enforce consistency and team exposure.
 * * This file must be strictly kept alphabetical. Group constants by a common prefix.
 */

// eslint-disable-next-line import/prefer-default-export
export const CLEAR_STORE = 'CLEAR_STORE';
export const API_FILTERS = 'API_FILTERS';
export const API_INITIAL_DATASETS = 'API_INITIAL_DATASETS';
export const API_INITIAL_SOLUTIONS = 'API_INITIAL_SOLUTIONS';
export const API_INITIAL_BLUEPRINTS = 'API_INITIAL_BLUEPRINTS';
export const API_FULL_DATASETS = 'API_FULL_DATASETS';
export const API_FULL_SOLUTION = 'API_FULL_SOLUTION';
export const USER_LOGGIN = 'USER_LOGGIN';
export const USER_PROFILE = 'USER_PROFILE';
export const USER_PREFERENCE = 'USER_PREFERENCE';
export const UPDATE_USER_PREFERENCE = 'UPDATE_USER_PREFERENCE';
export const UPDATE_USER_PREFERENCE_MANUALLY = 'UPDATE_USER_PREFERENCE_MANUALLY';
export const TABLEAU_SESSION_CREATED = 'TABLEAU_SESSION_CREATED';
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const ERRORS = 'ERRORS';
export const API_BUNDLE_DATASETS = 'API_BUNDLE_DATASETS';
export const API_RESEARCHLINKS = 'API_RESEARCHLINKS';
export const API_FAQS = 'API_FAQS';
export const API_PAGETEXTS = 'API_PAGETEXTS';
export const UPDATE_UTMPARAMETERS = 'UPDATE_UTMPARAMETERS';
export const SET_FLAGS = 'SET_FLAGS';
export const QUERY_DOWNLOADS = 'QUERY_DOWNLOADS';
export const QUERY_STEPS = 'QUERY_STEPS';
export const ID_VARIANTS = 'ID_VARIANTS';
export const API_FULL_BLUEPRINT = 'API_FULL_BLUEPRINT';
export const TILE_NAMES_GT_25_CHARS = 'TILE_NAMES_GT_25_CHARS';
export const API_FULL_QUERYLIBRARY = 'API_FULL_QUERYLIBRARY';
export const API_QL_RELATED_QUERIES = 'API_QL_RELATED_QUERIES';
