import update from 'react-addons-update';
import { API_FULL_QUERYLIBRARY, API_QL_RELATED_QUERIES } from '../actionTypes';

const initialQueryLibraryState: QueryLibraryStoreState = {
    full: {},
    all: {},
    relatedQueries: {}
};

// TODO - remove any
// eslint-disable-next-line
const reducer = (state: QueryLibraryStoreState = initialQueryLibraryState, incomingAction: QueryLibraryResultAction | QueryLibraryProfileResultAction | QueryLibraryRelatedQueriesResultAction) => {
    const action = incomingAction;
    switch (action.type) {
        case API_FULL_QUERYLIBRARY: {
            const actionObj = action as QueryLibraryProfileResultAction;
            return update(state, { full: { [actionObj.id]: { $set: { response: actionObj.response } } } });
        }
        case API_QL_RELATED_QUERIES: {
            const actionObj = action as QueryLibraryRelatedQueriesResultAction;
            return update(state, { relatedQueries: { [actionObj.id]: { $set: { response: actionObj.response } } } });
        }
        default:
            return state || initialQueryLibraryState;
    }
};

export { reducer, initialQueryLibraryState };
