
const getUpdatedLink = (value: string, langCode: string) => {
    let link = value;
    if (value && langCode) {
        const urlArray = value.split('/');
        if (urlArray && urlArray.length > 4) {
            urlArray[0] = window.location.protocol;
            urlArray[2] = window.location.host;
            if (!value.includes(`/${langCode}/`)) {
                urlArray[3] = langCode;
            }
            link = urlArray.join('/');
        }
    }
    return link;
};
export default getUpdatedLink;
