const name = 'Html to Json Parser';

const htmlToJson = (content: string) => {
    // create temp data structures
    const headerArray: string[][] = [];
    const bodyArray: string[][] = [];
    // create div in virtual memeroy to render the html
    let tempDiv: HTMLElement | null = document.createElement('div');
    tempDiv.innerHTML = content;
    // query the thead and tbody
    const headers = tempDiv.querySelector('thead');
    const body = tempDiv.querySelector('tbody');
    // loop through to build the data
    if (headers !== null && body !== null) {
        // loop through the headers
        const headerRows = headers.querySelectorAll('tr');
        for (let i = 0; i < headerRows.length; i++) {
            headerArray[i] = [];
            for (let r = 0; r < headerRows[i].cells.length; r++) {
                headerArray[i][r] = headerRows[i].cells[r].textContent || ' ';
            }
        }
        // loop through the body
        const bodyRows = body.querySelectorAll('tr');
        for (let i = 0; i < bodyRows.length; i++) {
            bodyArray[i] = [];
            for (let r = 0; r < bodyRows[i].cells.length; r++) {
                bodyArray[i][r] = bodyRows[i].cells[r].textContent || ' ';
            }
        }
    }
    tempDiv.innerHTML = '';
    tempDiv = null;
    return { headerRows: headerArray, bodyRows: bodyArray };
};

export { name as default, htmlToJson };
