import { AxiosResponse } from 'axios';
import { addTask } from 'domain-task';
import APIManager from '../../utilities/api-manager';
import { QUERY_DOWNLOADS, QUERY_STEPS } from '../actionTypes';

const addQueryExecute = (data: BuildQueryRequest) => {
    return APIManager.submitQueryExecute(data);
};

const fetchDownloads = (): AppThunkAction<QueryBuilderDownloadAction> => dispatch => {
    const fetchTask = APIManager.getDownloads().then((response: AxiosResponse<Downloads>) => {
        if (response.data) {
            dispatch({ downloads: response.data, time: new Date(), type: QUERY_DOWNLOADS } as QueryBuilderDownloadAction);
        }
    });
    addTask(fetchTask);
};

const fetchQueryBuilderSteps = (): AppThunkAction<QueryBuilderStepsAction> => dispatch => {
    const fetchTask = APIManager.getQueryBuilderSteps().then(response => {
        if (response.data) {
            dispatch({ steps: response.data, time: new Date(), type: QUERY_STEPS } as QueryBuilderStepsAction);
        }
    });
    addTask(fetchTask);
};

export { addQueryExecute, fetchDownloads, fetchQueryBuilderSteps };
