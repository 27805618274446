import { getText, get } from '../../helpers';

const mapSearchResultToSolution = (item: DataModal) => {
    const localeCode = getText('langCode');
    const MktplaceSolutionProvider = get(item, localeCode === 'en' ? 'MarketplaceDataProvider' : `MarketplaceDataProvider_${localeCode}`, '');
    const MktplaceSolutionProviderDesc = get(
        item,
        localeCode === 'en' ? 'MarketplaceDataProviderDesc' : `MarketplaceDataProviderDesc_${localeCode}`,
        ''
    );
    const MktplaceSolutionName = get(item, localeCode === 'en' ? 'Name' : `Name_${localeCode}`, '');
    const MktplaceSolutionDesc = get(item, localeCode === 'en' ? 'Description' : `Description_${localeCode}`, '');
    const KeyPlatformService = get(item, 'keyplatformservice', '').toLowerCase();
    return {
        MarketSolutionProvider: {
            MktplaceSolutionProvider,
            MktplaceSolutionProviderDesc
        },
        dataProvider: MktplaceSolutionProvider,
        MktplaceSolutionName,
        name: MktplaceSolutionName,
        AbsoluteSolutionName: undefined,
        MktplaceSolutionDesc,
        PlatformService: {
            ThumbnailImageFilePath: item.ThumbnailImageFilePath
        },
        MktplaceDateAdded: item.MarketplaceDatasetAdded,
        addedDate: item.MarketplaceDatasetAdded,
        MktplaceSolutionCurrentVerDate: item.MktPlaceDataSetEnhancementDate,
        enhancementDate: item.MktPlaceDataSetEnhancementDate,
        KeyPlatformService,
        SocialSharingImageFilePath: item.SocialSharingImageFilePath,
        MktplaceSolutionUrlName: item.Name
    };
};
export default mapSearchResultToSolution;
