import * as React from 'react';
import Button from '../_common/button';
import HtmlContent from '../_common/html-content';
import HelmetManager from '../_common/helmet-manager';

import { getText } from '../../helpers';
import './error-message.style.scss';

const errorMessageTitle = getText('generic.errorMessageTitle');
const tryAgain = getText('generic.tryAgain');
const langCode = getText('langCode');
const errorMessageContact = getText('generic.errorMessageContact');
const metaDataText = getText('pages.errorPage.metaData');

export default function ErrorMessage() {
    const reload = () => {
        if (window.location.href.indexOf('/404') > -1) {
            window.location.href = `/${langCode}`;
        } else {
            window.location.reload();
        }
    };

    return (
        <>
            <HelmetManager metaData={metaDataText} />
            <div className="error-page page-section">
                <div className="page-section__inner">
                    <h1 className="h3">{errorMessageTitle}</h1>
                    <Button onClick={reload} text={tryAgain} theme="primary" className="error-page__try-again-btn" />
                    <HtmlContent html={errorMessageContact} />
                </div>
            </div>
        </>
    );
}
