import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const allowResetScrollOnPage = ['query-library'];

// cache the current page
let previousLocation = {
    pathname: '',
    search: ''
};

function resetScroll() {
    window.scrollTo(0, 0);
}

history.listen(location => {
    const hasPageChanged = (previousLocation.pathname && previousLocation.pathname !== location.pathname) || location.search.length === 0;
    const resetScrollOnPage = location.search.length > 0 && allowResetScrollOnPage.filter(page => location.pathname.indexOf(page) > -1).length > 0;
    const hasHash = location.hash !== '';

    if ((hasPageChanged && !hasHash) || resetScrollOnPage) setTimeout(resetScroll, 1);

    // update cached page
    previousLocation = location;
});

export default history;
