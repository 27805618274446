import * as React from 'react';
import { useSelector } from 'react-redux';
import CustomLink from '../custom-link';
import { get, getText, getConfig } from '../../../helpers';

const signUpText = getText('generic.newUserSignUp');
const gaLabel = 'New User Sign Up';
const Window = window as AnonymousObject;
Window.dataLayer = Window.dataLayer || [];

function SignUpButton(props: SignUpButtonProps) {
    const user = useSelector((state: RootStoreState) => get(state, 'user', ''));
    const keyOnlineUserId = user && user.userInfo && user.userInfo.KeyOnlineUser ? user.userInfo.KeyOnlineUser : '';
    const signUpUrl = getConfig('urls.mi.ssacs.oktaSignUp');

    const {
        className,
        buttonTheme,
        isButton,
        isFullWidth,
        text = signUpText
    } = props;

    const handleClick = async (event: React.SyntheticEvent) => {
        if (process.env.NODE_ENV === 'production') {
            event.preventDefault();
            import('../../../utilities/application-insights').then(AppInsights => {
                const appInsights = AppInsights.default;
                if (appInsights) appInsights.flush();
                window.location.href = signUpUrl;
            });
        }
        Window.dataLayer.push({
            event: 'sign_up',
            keyOnlineUser: keyOnlineUserId
        });
    };

    return (
        <CustomLink
            url={signUpUrl}
            text={text}
            isButton={isButton}
            buttonTheme={buttonTheme}
            target="_self"
            isFullWidth={isFullWidth}
            className={className}
            onClick={handleClick}
            id="sign-up-link"
            gaLabel={gaLabel}
        />
    );
}

export default React.memo(SignUpButton);
