import { getText, get } from '../../helpers';

const localeCode = getText('langCode');

const buildPipeArray = (str: string) => {
    return str.split('|').filter(s => s.trim() !== '').map(s => s.trim());
};

const translateDatasets = (item: DataModal): string[] => {
    const associatedBundle = get(item, localeCode === 'en' ? 'AssociatedBundle' : `AssociatedBundle_${localeCode}`, '');
    const associatedDataset = get(item, localeCode === 'en' ? 'AssociatedDataset' : `AssociatedDataset_${localeCode}`, '');
    if (associatedBundle && associatedDataset) return [...buildPipeArray(associatedDataset), ...buildPipeArray(associatedBundle)];
    if (associatedDataset) return buildPipeArray(associatedDataset);
    if (associatedBundle) return buildPipeArray(associatedBundle);
    return [];
};

const translateToCodeData = (item: DataModal): QuerySubnavCodeData[] | undefined => {
    if (item.WebsiteURL && item.MarketplaceQueryType) {
        const queryTypes = buildPipeArray(item.MarketplaceQueryType);
        const websiteURLs = buildPipeArray(item.WebsiteURL);
        return websiteURLs.map((url: string, index: number) => {
            return {
                url, data: '', title: queryTypes[index]
            };
        });
    }
    return undefined;
};

const mapSearchResultsToQuery = (item: DataModal) => {
    const marketplaceDatasetPackage = get(item, localeCode === 'en' ? 'MarketplaceDatasetPackage' : `MarketplaceDatasetPackage_${localeCode}`, '');
    return {
        name: get(item, localeCode === 'en' ? 'Name' : `Name_${localeCode}`, ''),
        queryId: item.KeyMarketplaceQuery,
        description: get(item, localeCode === 'en' ? 'Description' : `Description_${localeCode}`, ''),
        versiondate: item.MktPlaceDataSetEnhancementDate,
        packages: buildPipeArray(marketplaceDatasetPackage || ''),
        datasets: translateDatasets(item),
        codeData: translateToCodeData(item)
    };
};


export default mapSearchResultsToQuery;
