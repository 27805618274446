/* eslint-disable max-classes-per-file */
import getText from './get-text';

enum SearchType {
    openSearch = 'Open Search',
    llmSearch = 'LLM Search',
    unknownSearch = 'Unknown Search'
}

const keywordList = getText('pages.home.genAIKeywords');

const getPatternString = () => {
    const patternString = keywordList.map(x => '\\b'.concat(x).concat('\\b'));
    const strRegExPattern = patternString.join('|');
    return strRegExPattern;
};

const isMatchedData = (searchInput: string, valueList: string[]) => {
    const matched = valueList.filter(element => element && element.toLowerCase().trim() === searchInput.trim().toLowerCase());
    if (matched && matched.length > 0) return true;
    return false;
};

interface Strategy {
    apply: () => string;
}


class OpenSearchStrategy implements Strategy {
    public apply = () => {
        return SearchType.openSearch;
    }
}

class LLMSearchStrategy implements Strategy {
    public apply = () => {
        return SearchType.llmSearch;
    }
}

const createSearchStrategyFactory = () => {
    const conditions: Map<string, Strategy> = new Map<string, Strategy>([
        ['conditionNotLogin', new OpenSearchStrategy()],
        ['conditionMatchWord', new LLMSearchStrategy()],
        ['conditionLessThan25', new OpenSearchStrategy()],
        ['conditionGreaterThan25Match', new OpenSearchStrategy()],
        ['conditionGreaterThan25NotMatch', new LLMSearchStrategy()],
        ['conditionElse', new OpenSearchStrategy()]
    ]);

    const getSearchStrategy = (searchValue: string, valueList: string[]) => {
        const pattern = getPatternString();
        const regEx = new RegExp(pattern, 'gi');
        if (searchValue.match(regEx)) return conditions.get('conditionMatchWord');
        if (searchValue.length <= 25) return conditions.get('conditionLessThan25');
        if (searchValue.length > 25 && isMatchedData(searchValue, valueList)) return conditions.get('conditionGreaterThan25Match');
        if (searchValue.length > 25 && !isMatchedData(searchValue, valueList)) return conditions.get('conditionGreaterThan25NotMatch');
        return conditions.get('conditionElse');
    };

    return { getSearchStrategy };
};

export { SearchType, createSearchStrategyFactory };
