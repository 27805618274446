const mapDataBundleToDataProfileCard = (item: APIDataBundleCard, nameItems: APIDataBundleCard[]) => {
    const categories: number[] = [];
    const industries: number[] = [];
    const geographies: number[][] = [];
    const earliestRecord: number[] = [];
    const pointInTime: boolean[] = [];
    const deliveryChannel: number[] = [];
    const deliveryPlatform: number[] = [];
    const dataProvider: string[] = [];
    if (item.MarketDatasetBundleDatasets) {
        item.MarketDatasetBundleDatasets.forEach(bundleDataset => {
            if (bundleDataset) {
                categories.push(...bundleDataset.MarketDatasetSearchTags.map(category => category.KeyMarketplaceSearchTag));
                industries.push(...bundleDataset.MarketDatasetMIIndustrys.map(industry => industry.KeyMIIndustryTree));
                geographies.push(bundleDataset.MarketDatasetGeographys.map(geography => geography.KeyGeographyTree));
                earliestRecord.push(bundleDataset.EarliestRecord);
                pointInTime.push(bundleDataset.PointInTime);
                if (bundleDataset && bundleDataset.MarketDatasetDestPlatforms) bundleDataset.MarketDatasetDestPlatforms.forEach(element => deliveryChannel.push(element.KeySPGDestinationPlatform));
                if (bundleDataset && bundleDataset.MarketDatasetDelivPlatforms) bundleDataset.MarketDatasetDelivPlatforms.forEach(element => deliveryPlatform.push(element.KeySPGDestinationPlatform));
                if (dataProvider.indexOf(bundleDataset.MarketplaceDataProvider) === -1) dataProvider.push(bundleDataset.MarketplaceDataProvider);
            }
        });
    }
    const englishNameItem = nameItems.length > 0 && nameItems.find(nameItem => nameItem.KeyMarketplaceDatasetBundle === item.KeyMarketplaceDatasetBundle);
    return {
        name: item.MarketplaceDatasetBundleName,
        absoluteName: englishNameItem ? englishNameItem.MarketplaceDatasetBundleName : item.MarketplaceDatasetBundleName,
        id: item.KeyMarketplaceDatasetBundle,
        isBundle: true,
        addedDate: item.MarketplaceDatasetBundleAdded,
        enhancementDate: item.MktplaceBundleEnhancementDate,
        shortDescription: item.MarketplaceBundleShortDesc,
        thumbnailImageFilePath: item.ThumbnailImageFilePath, // tile image
        longDescription: item.MarketplaceBundleLongDesc,
        dataProvider: dataProvider.join(', '),
        deliveryChannel,
        deliveryPlatform,
        earliestRecord,
        pointInTime,
        categories,
        industries,
        geographies,
        active: item.ActiveMPDatasetCoverage,
        supportDocs: item.MarketDSBundleSupportDocs ? item.MarketDSBundleSupportDocs : [],
    };
};
export default mapDataBundleToDataProfileCard;
