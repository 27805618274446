const name = 'html-entities';

function decode(text: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
}

function encode(text: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.innerHTML;
}

export { name as default, encode, decode };
