
import { isInternalUser, getConfig } from '.';


const authSolList = getConfig('authorizedsolutions');
const allowedDomains = getConfig('internalMailDomains');

const authdata = (list: APISolution[], email: string) => {

    const isValidUser = isInternalUser(allowedDomains, email);
    // Hide sensitive solutions for external users
    if (list && list.length > 0 && list as APISolution[] && !isValidUser) {
        const value = list.filter(x => !authSolList.includes(x.KeyPlatformService));
        return value || [];
    }
    return list;
};

export default authdata;
