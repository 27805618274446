import update from 'react-addons-update';
import { createYearInPast, sortAsc, getText } from '../../helpers';
import slugify from '../../utilities/slugify';
import { API_FILTERS } from '../actionTypes';

const allIndustries = getText('generic.allIndustries');
const lessThan5Years = getText('historyFilterOptions.lessThan5Years');
const atleast5Years = getText('historyFilterOptions.atleast5Years');
const atleast10Years = getText('historyFilterOptions.atleast10Years');
const atleast15Years = getText('historyFilterOptions.atleast15Years');
const atleast20Years = getText('historyFilterOptions.atleast20Years');

const initialFiltersState: FilterStoreState = {
    categories: [],
    solutionCategories: [],
    delivery: [],
    history: [],
    industries: [],
    pointInTime: [],
    regions: [],
    countries: [],
    states: [],
    featuredDatasets: [],
    alternativeDatasets: [],
    deliveryPlatform: [],
    allDeliveryPlatform: [],
    time: '',
    vendors: [],
    extractedDatasets: [],
    featuredBlueprints: [],
    queryTypes: [],
    datasets: []
};

// TODO - remove any
// eslint-disable-next-line
const reducer = (state: FilterStoreState = initialFiltersState, incomingAction: FiltersResultAction) => {
    const action = incomingAction;
    switch (action.type) {
        case API_FILTERS: {
            const actionObj = action as FiltersResultAction;
            if (actionObj.response !== undefined) {
                const mapIndustries = (industryResponse: AnonymousObject[]) => {
                    const mappedIndustries = industryResponse.map((item: AnonymousObject) => {
                        if (item.IndustryLongName === 'All') {
                            item.IndustryLongName = allIndustries;
                        }
                        return {
                            name: item.IndustryLongName,
                            id: `industry-${slugify(item.IndustryLongName)}`,
                            value: item.KeyMIIndustryTree,
                            checked: false
                        };
                    });
                    return mappedIndustries;
                };

                const mapDelivery = (deliveryResponse: AnonymousObject[]) => {
                    const mappedDelivery = deliveryResponse
                        .filter(item => [49, 55, 57, 64].includes(item.KeySPGDestinationPlatform))
                        .map((item: AnonymousObject) => {
                            return {
                                name: item.SPGDestinationPlatform,
                                id: item.KeySPGDestinationPlatform,
                                value: item.KeySPGDestinationPlatform,
                                checked: false
                            };
                        });
                    return mappedDelivery;
                };

                const mapQueryCategories = (categoryResponse: AnonymousObject[]) => {
                    const mappedCategories = categoryResponse
                        .filter(x => x.ActiveQuerySearchTag)
                        .sort((x, y) => (x.FormOrderQuery > y.FormOrderQuery ? 1 : -1))
                        .map((item: AnonymousObject) => {
                            return {
                                name: item.MarketplaceSearchTag,
                                id: `category-${slugify(item.MarketplaceSearchTag)}`,
                                value: item.KeyMarketplaceSearchTag,
                                checked: false
                            };
                        });
                    return mappedCategories;
                };

                const mapCategories = (categoryResponse: AnonymousObject[]) => {
                    const mappedCategories = categoryResponse
                        .filter(x => x.ActiveMarketplaceSearchTag)
                        .sort((x, y) => (x.FormOrder > y.FormOrder ? 1 : -1))
                        .map((item: AnonymousObject) => {
                            return {
                                name: item.MarketplaceSearchTag,
                                id: `category-${slugify(item.MarketplaceSearchTag)}`,
                                value: item.KeyMarketplaceSearchTag,
                                checked: false
                            };
                        });
                    return mappedCategories;
                };

                const mapSolutionCategories = (categoryResponse: AnonymousObject[]) => {
                    const mappedSolCategories = categoryResponse
                        .filter(x => x.ActiveSolutionSearchTag)
                        .sort((x, y) => (x.FormOrder > y.FormOrder ? 1 : -1))
                        .map((item: AnonymousObject) => {
                            return {
                                name: item.MarketplaceSearchTag,
                                id: `category-${slugify(item.MarketplaceSearchTag)}`,
                                value: item.KeyMarketplaceSearchTag,
                                checked: false
                            };
                        });
                    return mappedSolCategories;
                };

                const mapRegions = (regionResponse: Region[]) => {
                    const globalLabel = getText('pages.dataSets.filterOptions.region.additionalOptions.global') as string;
                    const globalValue: string[] = [];

                    const mappedRegions = regionResponse.map((item: Region) => {
                        globalValue.push(item.KeyGeographyTree);
                        return {
                            name: item.GlobalRegion.GlobalRegion,
                            id: `region-${slugify(item.GlobalRegion.GlobalRegion)}`,
                            value: item.KeyGeographyTree,
                            checked: false
                        };
                    });

                    const globalRegion = {
                        name: globalLabel,
                        id: `region-${slugify(globalLabel)}`,
                        value: `${globalValue}`,
                        checked: false
                    };

                    // Apply sortAsc on field GlobalRegion as in oData query it is getting to complex
                    return [globalRegion, ...sortAsc(mappedRegions, 'name')];
                };

                const mapHistory = () => {
                    const historyOptions = [
                        {
                            name: lessThan5Years,
                            value: `[${createYearInPast(5)} TO *]`
                        },
                        {
                            name: atleast5Years,
                            value: `[* TO ${createYearInPast(5)}]`
                        },
                        {
                            name: atleast10Years,
                            value: `[* TO ${createYearInPast(10)}]`
                        },
                        {
                            name: atleast15Years,
                            value: `[* TO ${createYearInPast(15)}]`
                        },
                        {
                            name: atleast20Years,
                            value: `[* TO ${createYearInPast(20)}]`
                        }
                    ];
                    const mappedHistory = historyOptions.map((item: AnonymousObject) => {
                        return {
                            name: item.name,
                            id: `history-${slugify(item.value)}`,
                            value: item.value,
                            checked: false
                        };
                    });
                    return mappedHistory;
                };

                const mapPointInTime = () => {
                    const pointIntTimeText = getText('pages.dataSets.filterOptions.pointInTime.text');

                    return [
                        {
                            name: pointIntTimeText,
                            id: slugify(pointIntTimeText),
                            value: '1',
                            checked: false
                        }
                    ];
                };

                const mapBookmark = () => {
                    const bookmarkText = getText('pages.dataSets.filterOptions.bookmark.text');

                    return [
                        {
                            name: bookmarkText,
                            id: slugify(bookmarkText),
                            value: '1',
                            checked: false
                        }
                    ];
                };

                const mapCountries = (categoryResponse: Country[]) => {
                    return categoryResponse;
                };

                const mapStates = (categoryResponse: State[]) => {
                    return categoryResponse;
                };

                const mapMarketDatasetFeaturedAlternative = (items: DatasetFeaturedAlternative[]) => {
                    return items;
                };

                const mapDeliveryPlatform = (items: Delivery[]) => {
                    const platformData: DeliveryPlatform[] = [];
                    items.forEach((item: Delivery) => {
                        if ([52, 60, 6, 47, 49, 29, 62, 61, 65, 104, 105].includes(item.KeySPGDestinationPlatform) && item.KeySPGDestinationPlatform > 0) {
                            const platform = {
                                id: item.KeySPGDestinationPlatform,
                                name: item.SPGDestinationPlatform,
                                value: item.KeySPGDestinationPlatform.toString(),
                                checked: false,
                                keyPlatformService: item.KeyPlatformService,
                                mktplaceSolutionName: item.MktplaceSolutionName
                            };
                            platformData.push(platform as DeliveryPlatform);
                        }
                    });
                    return platformData;
                };

                const mapAllDeliveryPlatform = (items: Delivery[]) => {
                    const platformData: DeliveryPlatform[] = [];
                    items.forEach((item: Delivery) => {
                        if (item.KeySPGDestinationPlatform > 0) {
                            const platform = {
                                id: item.KeySPGDestinationPlatform,
                                name: item.SPGDestinationPlatform,
                                value: item.KeySPGDestinationPlatform.toString(),
                                checked: false,
                                keyPlatformService: item.KeyPlatformService,
                                mktplaceSolutionName: item.MktplaceSolutionName
                            };
                            platformData.push(platform as DeliveryPlatform);
                        }
                    });
                    return platformData;
                };

                const mapDatasetVendors = (items: Vendor[]) => {
                    const vendorsData: VendorPlatform[] = [];
                    items.forEach((item: Vendor) => {
                        if (item.SolutionType === 'Dataset') {
                            const vendor = {
                                id: item.KeyMarketPlaceVendor,
                                name: item.MarketPlaceVendor,
                                value: item.MarketPlaceVendor.toString(),
                                checked: false
                            };
                            vendorsData.push(vendor as VendorPlatform);
                        }
                    });
                    const tempVendorsData = vendorsData.filter((x, i, a) => a.indexOf(x) === i);
                    const key = 'name';
                    const uniqueVendorsData = [...new Map(tempVendorsData.map(item => [item[key], item])).values()];
                    return uniqueVendorsData;
                };

                const mapSolutionVendors = (items: Vendor[]) => {
                    const vendorsData: VendorPlatform[] = [];
                    items.forEach((item: Vendor) => {
                        if (item.SolutionType === 'Solution') {
                            const vendor = {
                                id: item.KeyMarketPlaceVendor,
                                name: item.MarketPlaceVendor,
                                value: item.MarketPlaceVendor.toString(),
                                checked: false
                            };
                            vendorsData.push(vendor as VendorPlatform);
                        }
                    });
                    const tempVendorsData = vendorsData.filter((x, i, a) => a.indexOf(x) === i);
                    const key = 'name';
                    const uniqueVendorsData = [...new Map(tempVendorsData.map(item => [item[key], item])).values()];
                    return uniqueVendorsData;
                };
                const mapMarketDatasetExtract = (items: DatasetExtractedAlternative[]) => {
                    return items;
                };
                const mapMarketBlueprintFeatured = (items: BlueprintsFeatured[]) => {
                    return items;
                };

                const mapQueryTypes = (categoryResponse: AnonymousObject[]) => {
                    const mappedCategories = categoryResponse
                        .sort((x, y) => (x.MarketplaceQueryType > y.MarketplaceQueryType ? 1 : -1))
                        .map((item: AnonymousObject) => {
                            return {
                                name: item.MarketplaceQueryType,
                                id: `queryType-${slugify(item.KeyMarketplaceQueryType)}`,
                                value: item.KeyMarketplaceQueryType,
                                checked: false
                            };
                        });
                    return mappedCategories;
                };

                const mapDatasets = (datasetsResponse: AnonymousObject[]) => {
                    const mappedDatasets = datasetsResponse
                        .sort((x, y) => (x.marketplaceDatasetName > y.marketplaceDatasetName ? 1 : -1))
                        .map((item: AnonymousObject) => {
                            return {
                                name: item.marketplaceDatasetName,
                                id: `dataset-${slugify(item.keyMarketplaceDataset)}`,
                                value: item.keyMarketplaceDataset,
                                checked: false
                            };
                        });
                    return mappedDatasets;
                };

                const filters: FilterStoreState = {
                    industries: mapIndustries(actionObj.response[0]),
                    delivery: mapDelivery(actionObj.response[1]),
                    categories: mapCategories(actionObj.response[2]),
                    regions: mapRegions(actionObj.response[3]),
                    history: mapHistory(),
                    countries: mapCountries(actionObj.response[4]),
                    states: mapStates(actionObj.response[5]),
                    featuredDatasets: mapMarketDatasetFeaturedAlternative(actionObj.response[6]),
                    alternativeDatasets: mapMarketDatasetFeaturedAlternative(actionObj.response[7]),
                    pointInTime: mapPointInTime(),
                    bookmark: mapBookmark(),
                    deliveryPlatform: mapDeliveryPlatform(actionObj.response[1]),
                    allDeliveryPlatform: mapAllDeliveryPlatform(actionObj.response[1]),
                    queryCategories: mapQueryCategories(actionObj.response[2]),
                    solutionCategories: mapSolutionCategories(actionObj.response[2]),
                    vendors: mapDatasetVendors(actionObj.response[8]),
                    solutionVendors: mapSolutionVendors(actionObj.response[8]),
                    extractedDatasets: mapMarketDatasetExtract(actionObj.response[9]),
                    featuredBlueprints: mapMarketBlueprintFeatured(actionObj.response[10]),
                    queryTypes: mapQueryTypes(actionObj.response[11]),
                    datasets: mapDatasets(actionObj.response[12])
                };

                return update(state, { $set: { ...filters, time: actionObj.time } });
            }
            return state;
        }
        default:
            return state || initialFiltersState;
    }
};

export { reducer, initialFiltersState };
