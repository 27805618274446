import { reducerWithInitialState } from 'typescript-fsa-reducers';
import update from 'immutability-helper';
import newError from './errors.actions';

const initialErrorState: ErrorStoreState = {
    errors: {},
    lastErrorTime: ''
};

const reducer = reducerWithInitialState(initialErrorState);

reducer.case(newError, (state, error) => {
    const time = new Date().toISOString();
    return update(state, { errors: { [time]: { $set: error.stack } }, lastErrorTime: { $set: time } });
});

export { reducer, initialErrorState };
