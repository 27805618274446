/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { SlideDown } from 'react-slidedown';
import SPGVariables from '../../../utilities/spg-variables';
import 'react-slidedown/lib/slidedown.css';

const spgVariables = SPGVariables();
const languages = spgVariables.SUPPORTED_LANGUAGES;
const LeftMenuItem = function LeftMenuItem(props: BlackhatMenuItemProps) {
    const { nodeRef, onClick, isVisible, navOpenMobile, title, menuItems, isLangSelector } = props;

    const handleItemClick = (event: React.SyntheticEvent, url: string, text: string, langCode = 'en') => {
        if (isLangSelector) {
            event.preventDefault();
            const langRegex = new RegExp(
                languages
                    .map((lang: string) => {
                        return `/${lang}/|/${lang}`;
                    })
                    .join('|')
            );
            // remove filters when language changed
            let search = window.location.search
                .split('&')
                .filter(str => !str.includes('filters='))
                .join('&')
                .replace('?', '');
            search = search.length > 0 ? `?${search}` : search;
            const lang = `/${langCode}/`;
            const href = `${window.location.origin}${window.location.pathname.replace(langRegex, lang)}${search}`;
            window.location.href = `${href}`;
        }
    };
    const handleMenuClick = (event: React.SyntheticEvent) => {
        event.preventDefault();
        onClick(!navOpenMobile);
    };
    return (
        <div ref={nodeRef} className="sites-link" aria-label="s&amp;p global Divisions">
            <a href="#" aria-haspopup="true" id="division" aria-expanded={navOpenMobile} className="sites-link__cta" lang="en-us" onClick={handleMenuClick}>
                {title}
            </a>
            <SlideDown className="sites-link__dropdown" aria-hidden={!navOpenMobile} closed={!(isVisible && navOpenMobile)}>
                <div>
                    <div className="page-section__inner">
                        <ul
                            aria-label="submenu"
                            className="sites-link__links"
                            data-testid="header-dropdown-submenu"
                            role="menu"
                        >
                            {menuItems.map(item => {
                                return (
                                    <li key={item.label} role="menuitem">
                                        <a
                                            href={item.link}
                                            title=""
                                            className={item.label === 'S&P Global' ? 'active' : ''}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            onClick={event => handleItemClick(event, item.link, item.label, item.langCode)}
                                        >
                                            {item.label}
                                        </a>
                                    </li>
                                );
                            })}
                            <div className="sites-link__close">
                                <a href="#" onClick={() => onClick(!navOpenMobile)}> <CloseIcon /> Close</a>
                            </div>
                        </ul>
                    </div>
                </div>
            </SlideDown>
        </div>
    );
};

export default React.memo(LeftMenuItem);
