import { hotjar } from 'react-hotjar';


export function hotjartracker() {
    const devId = '3295869';
    const stageId = '3295897';
    const prodId = '2724634';
    const script = document.createElement('script');
    script.type = 'text/plain';
    script.className = 'optanon-category-C0002';
    const hotjarScript = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        if(window.location.hostname.includes('dev')) {
            h._hjSettings={hjid:${devId},hjsv:6};
        } else if(window.location.hostname.includes('stage')) {
            h._hjSettings={hjid:${stageId},hjsv:6};
        } else {
            h._hjSettings={hjid:${prodId},hjsv:6}; 
        }
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    script.innerHTML = hotjarScript;
    document.getElementsByTagName('head')[0].appendChild(script);
    if (window.location.hostname.includes('dev')) {
        hotjar.initialize(3295869, 6, false);
    } else if (window.location.hostname.includes('stage')) {
        hotjar.initialize(3295897, 6, false);
    } else {
        hotjar.initialize(2724634, 6, false);
    }
}

export default hotjartracker;
