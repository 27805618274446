
const uniqueArray = ((array: AnonymousObject, property: string): AnonymousObject => {
    const flags = new Set();

    return array.filter((entry: AnonymousObject) => {
        if (flags.has(entry[property])) {
            return false;
        }
        flags.add(entry[property]);
        return true;
    });
});

export default uniqueArray;
