
import mapDatasetToDataProfile from './map-dataset-to-dataprofile';

const mapDataBundleToIndustry = (item: APIBundleDatasetIndustrys) => {
    const dataProfiles: DataProfile[] = [];
    const marketDatasetDatasets: MarketDatasetDataset[] = [];
    const dataProviders: Set<string> = new Set<string>();

    if (item && item.MarketplaceDataset && item.MarketplaceDataset.length > 0) {
        dataProfiles.push(
            ...item.MarketplaceDataset.filter(dataset => dataset !== null)
                .map(i => {
                    dataProviders.add(i.MarketplaceDataProvider);
                    return mapDatasetToDataProfile(i);
                })
        );
    }

    const bundleLogos: MarketDatasetLogos[] = [];

    return {
        marketDatasetVisualization: [],
        name: '',
        id: 0,
        isBundle: true,
        addedDate: '',
        enhancementDate: '',
        shortDescription: '',
        thumbnailImageFilePath: '',
        longDescription: '',
        dataProviderDesc: '',
        bundleDatasets: dataProfiles,
        releaseNote: '',
        releaseNoteTitle: '',
        marketDatasetResearchs: [],
        marketplaceTrialButtonTitle: '',
        dataProvider: [...dataProviders].join(', '),
        earliestRecord: 0,
        pointInTime: false,
        onlineImageFilePath: '',
        socialSharingImageFilePath: '',
        earliestSignificantCoverage: 0,
        estimatedDatasetSize: 0,
        estimatedDatasetStorage: 0,
        marketplaceDatasetCoverageDesc: '100',
        marketplaceDataSource: '',
        overviewImageFilePath: '',
        pointInTimeDescription: '',
        marketDatasetDatasets,
        marketDatasetSearchTags: [],
        marketDatasetMIIndustrys: [],
        marketDatasetGeographys: [],
        marketDatasetLinks: [],
        marketDatasetNotebooks: [],
        deliveryChannel: [{ SPGDestinationPlatform: '', KeySPGDestinationPlatform: 0 }],
        deliveryPlatform: [{ SPGDestinationPlatform: '', KeySPGDestinationPlatform: 0 }],
        marketplaceDatasetFieldCount: { MarketplaceDatasetFieldCount: '' },
        supportDocs: [],
        marketDatasetLogos: bundleLogos,
        hasAlert: false,
        alertNote: '',
        alertTitle: '',
        active: false,
        marketMSADataLink: '',
        marketMSADataFilePath: '',
        marketplaceMSATooltip: '',
        mktplaceSolutionSupportEmail: '',
        marketSPGDeliveryPlatform: [],
        marketSPGDestinationPlatform: []
    } as DataProfile;
};
export default mapDataBundleToIndustry;
