import React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import store from '../../redux/store';
import history from '../history';

const RedirectToHomePage = () => {
    history.push('/');
    return <div />;
};

const AnnonymousUserRoute = function AnnonymousUserRoute({ component: Component, ...rest }: RouteProps) {
    const { isLoggedIn } = store.getState().user;

    if (!Component) {
        return null;
    }

    if (!isLoggedIn) return <Route {...rest} render={(props: RouteComponentProps<{}>) => <Component {...props} />} />;

    return RedirectToHomePage();
};

export default React.memo(AnnonymousUserRoute);
