import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Modal from '../../_common/modal';
import BurgerIcon from '../global-header-menu-icon';
import SearchOverlay from '../global-header-search-overlay';
import Menu from '../global-header-menu';
import { ReactComponent as SPLogo } from '../../../assets/svg/standard-and-poor-global-marketplace.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/icons/search.svg';
import { cn, getText } from '../../../helpers';
import HistoryInstance from '../../../utilities/history';

const homeText = getText('generic.home');
const langCode = getText('langCode');

function GlobalHeaderMobile(props: FeatureFlags) {
    const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
    const [modalIsOpen, setModalOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        const historyListener = HistoryInstance.listen(() => {
            setShowMobileNav(false);
        });
        return () => {
            historyListener();
        };
    }, []);

    // temporarily solution, till we use old searchbar view
    const handleOldSearchButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setModalOpen(true);
    };

    const handleSearchButtonClick = (e: React.MouseEvent) => {
        setShowMobileNav(false);
        e.stopPropagation();
        // this is urgent fix of mobile search button click listener
        // we shouls uncomment onSearchIconClick property call and not use the event listener
        // the problem is - overlay is not opening on search button click on the staging
        document.dispatchEvent(new Event('MobileSearchButtonClickEvent'));
    };

    const handleHeaderBodyClick = () => {
        // this is urgent fix of mobile search button click listener
        document.dispatchEvent(new Event('MobileHeaderBodyClickEvent'));
    };

    return (
        <header role="presentation" onClick={() => handleHeaderBodyClick()} onKeyDown={() => handleHeaderBodyClick()} className={cn('global-header-mobile', 'page-section')} data-testid="global-header-mobile">
            <div className="page-section__inner">
                <NavLink
                    exact
                    to={`/${langCode}/`}
                    title={homeText}
                    className="global-header-mobile__logo"
                    activeClassName="global-header-mobile__logo--active"
                >
                    <SPLogo />
                </NavLink>
                <nav className="global-header-mobile__nav">
                    {(useLocation().pathname === `/${langCode}/` || useLocation().pathname === `/${langCode}`) && (
                        <button
                            onClick={(e: React.MouseEvent) => handleSearchButtonClick(e)}
                            type="button"
                            className="global-header-mobile__search-btn"
                            aria-label="Search"
                            data-testid="search-btn"
                        >
                            <SearchIcon />
                        </button>
                    )}
                    {(useLocation().pathname !== `/${langCode}/` && useLocation().pathname !== `/${langCode}`) && (
                        <button
                            onClick={(e: React.MouseEvent) => handleOldSearchButtonClick(e)}
                            type="button"
                            className="global-header-mobile__search-btn"
                            aria-label="Search"
                            data-testid="search-btn"
                        >
                            <SearchIcon />
                        </button>
                    )}
                    <Modal open={modalIsOpen} onClose={() => setModalOpen(false)} isFullScreen={window.innerWidth < 1024}>
                        <SearchOverlay />
                    </Modal>
                    <BurgerIcon isActive={showMobileNav} onClick={() => setShowMobileNav(!showMobileNav)} />
                </nav>
            </div>
            {showMobileNav && <Menu {...props} />}
        </header>
    );
}

export default React.memo(GlobalHeaderMobile);
