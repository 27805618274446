// Datediff
// if only date is passed it compares the time to now()
// else it compares the two times

export default (date: Date | string, date2?: Date | string) => {
    const now: Date = date2 ? new Date(date) : new Date();
    const then: Date = date2 ? new Date(date2) : new Date(date);
    const diff: number = now.getTime() - then.getTime();
    return Number.isNaN(diff) ? 0 : diff;
};
