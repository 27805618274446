import SPGVariables from './spg-variables';

const spgVariables = SPGVariables();

if (!(spgVariables.IS_RENDERTRON_REQUEST || spgVariables.IS_CRAWLER_REQUEST)) {
    const link = document.createElement('link');
    link.rel = 'dns-prefetch';
    link.crossOrigin = 'anonymous';
    link.href = '//cdn.cookielaw.org';
    document.getElementsByTagName('head')[0].appendChild(link);
}

function cookieNotice() {
    if (!(spgVariables.IS_RENDERTRON_REQUEST || spgVariables.IS_CRAWLER_REQUEST)) {
        const scriptJs = document.createElement('script');
        scriptJs.type = 'text/javascript';
        scriptJs.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        scriptJs.charset = 'UTF-8';
        scriptJs.async = true;
        scriptJs.crossOrigin = 'anonymous';
        scriptJs.setAttribute('data-domain-script', spgVariables.ONE_TRUST_COOKIE_GUID);
        scriptJs.setAttribute('data-document-language', 'true');
        document.getElementsByTagName('head')[0].appendChild(scriptJs);

        const scriptWrapper = document.createElement('script');
        scriptWrapper.type = 'text/javascript';
        scriptWrapper.innerHTML = 'function OptanonWrapper() { }';
        document.getElementsByTagName('head')[0].appendChild(scriptWrapper);
    }
}

export { cookieNotice as default };
