import { addTask } from 'domain-task';
import { get, getConfig, dateDiff, getText } from '../../helpers';
import APIManager from '../../utilities/api-manager';
import { API_FULL_BLUEPRINT, API_INITIAL_BLUEPRINTS } from '../actionTypes';

const apiDataRefreshTime = getConfig('redux.cacheRefreshTimes.long');

const fetchInitialBlueprints = (): AppThunkAction<BlueprintsResultAction> => (dispatch, getState) => {
    const { blueprints } = getState();
    const cachedResponse = get(blueprints, 'all');
    const hasExpired = cachedResponse && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime);
    if (hasExpired) {
        const isDefaultLanguage = getText('isDefaultLanguage'); // making it local as SSR needs to fetch this for each store

        const fetchTask = APIManager.getBlueprints().then(response => {
            let apiBlueprints = response[0];
            if (!isDefaultLanguage && response.length > 1) {
                apiBlueprints = response[0].map((item: APIBlueprint) => {
                    const nameitem = response[1].find((resitem: { KeyMarketplaceBlueprint: string }) => resitem.KeyMarketplaceBlueprint === item.KeyMarketplaceBlueprint);
                    item.AbsoluteBlueprintName = nameitem && nameitem.MarketplaceBlueprintName ? nameitem.MarketplaceBlueprintName : item.MarketplaceBlueprintName;
                    return item;
                });
            }
            dispatch({ response: apiBlueprints, time: new Date(), type: API_INITIAL_BLUEPRINTS } as BlueprintsResultAction);
        });
        addTask(fetchTask);
    }
};

const fetchFullBlueprint = (params: BlueprintsParams): AppThunkAction<BlueprintResultAction> => (dispatch, getState) => {
    const { blueprints } = getState();
    const { id } = params;
    const cachedResponse = get(blueprints, `full.${id}`, {});
    const hasExpired = cachedResponse && (!cachedResponse.response ? true : dateDiff(cachedResponse.response.CachedTime) > apiDataRefreshTime);
    if (!id || !cachedResponse || hasExpired) {
        const fetchTask = APIManager.getBlueprintDetailsById(id).then(response => {
            dispatch({ response: { ...response.data, CachedTime: new Date() }, type: API_FULL_BLUEPRINT, id });
        });
        addTask(fetchTask);
    }
};

// eslint-disable-next-line import/prefer-default-export
export { fetchFullBlueprint, fetchInitialBlueprints };
