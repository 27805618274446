import * as React from 'react';
import SignInButton from '../../_common/sign-in-button';
import SignUpButton from '../../_common/sign-up-button';
import Dropdown from '../../_common/dropdown-button';
import { getText } from '../../../helpers';

const signUpText = getText('generic.newUserSignUp');
const signInText = getText('generic.signIn');

function GlobalHeaderSignIn(props: GlobalHeaderSignInProps) {
    const { className } = props;
    return (
        <Dropdown
            className={className}
            listContent={[
                <SignInButton key="sign-in" isFullWidth />,
                <SignUpButton key="sign-up" text={signUpText} isFullWidth isButton={false} />
            ]}
            buttonContent={signInText}
            buttonClass="global-header-desktop__sign-in-btn button--tertiary"
        />
    );
}

export default React.memo(GlobalHeaderSignIn);
