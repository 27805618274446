import * as React from 'react';

const superScriptSanitizer = (text: string): JSX.Element => {
    const symbols = ['®', '™'];
    const index = [...text].findIndex(s => symbols.includes(s));
    if (index > -1) {
        const symbol = text[index];
        return (
            <>
                {text.substring(0, index)}<sup>{symbol}</sup>{text.substring(index + 1)}
            </>
        );
    }
    return <>{text}</>;
};

export default superScriptSanitizer;
