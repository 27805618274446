import React from 'react';
import { cn, getConfig, getText } from '../../../helpers';
import HistoryInstance from '../../../utilities/history';
import spgvariables from '../../../utilities/spg-variables';

import CustomLink from '../../_common/custom-link';
import Dropdown from '../../_common/dropdown-button';
import APIManager from '../../../utilities/api-manager';
import { ReactComponent as CaretDownIcon } from '../../../assets/svg/icons/caret-down.svg';

const SPGVariables = spgvariables();
const langCode = getText('langCode');

function GlobalHeaderSubMenu(props: GlobalHeaderSubMenuProps) {
    const { dropDownItems, text, headerId = '' } = props;
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const [reqHeader, setReqHeader] = React.useState<string>('');

    React.useEffect(() => {
        setIsActive(HistoryInstance.location.pathname.includes(`/${headerId.toLowerCase()}/`));
        const historyListener = HistoryInstance.listen(location => {
            setIsActive(location.pathname.includes(`/${headerId.toLowerCase()}/`));
        });
        const userOriginCountryCodeFromSession = sessionStorage.getItem('userOriginCountryCode');
        if (userOriginCountryCodeFromSession === null) {
            APIManager.getReqHeaderByName('PMUSER_COUNTRY_CODE').then(response => {
                setReqHeader(response.headers.userorigincountrycode || '');
                sessionStorage.setItem('userOriginCountryCode', response.headers.userorigincountrycode);
            });
        } else {
            setReqHeader(userOriginCountryCodeFromSession || '');
        }
        return () => {
            historyListener();
        };
    }, []);
    return (
        <Dropdown
            openDropDownOnHover
            className="global-header-sub-menu"
            listContent={dropDownItems.map((item: GlobalHeaderLink) => (
                <CustomLink
                    key={item.text}
                    text={item.text}
                    url={item.urlConfigKey ? (getConfig(`${item.urlConfigKey}.${SPGVariables.ENV}`) as string) : item.url}
                    title={item.title}
                    isFullWidth
                    isDisabled={item.isDisabled}
                    activeClassName="global-header-sub-menu__link--active"
                />
            ))}
            buttonContent={
                <>
                    <span className={cn(`global-header-desktop__link global-header-desktop--small-${langCode}`, { 'global-header-desktop__link--active': isActive })}>
                        {text}
                        <CaretDownIcon />
                    </span>
                </>
            }
            buttonClass="global-header-sub-menu__link"
        />
    );
}

export default React.memo(GlobalHeaderSubMenu);
