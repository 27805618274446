import * as React from 'react';
import { useSelector } from 'react-redux';
import Notification from '../_common/notification';
import { get, getText } from '../../helpers';

const errorMessage = getText('generic.errorMessage');

const position = {
    vertical: 'top',
    horizontal: 'right'
};

let cachedError = '';

function NotificationContainer({ message = errorMessage, variant = 'error' }: NotificationContainerProps) {
    const [hasError, setHasError] = React.useState<boolean>(false);

    const errorState = useSelector(
        (state: RootStoreState) => {
            return get(state, 'errors');
        },
        errors => errors.lastErrorTime === ''
    );

    const isNewError = errorState.lastErrorTime !== cachedError;

    if (isNewError) setHasError(true);

    cachedError = errorState.lastErrorTime;

    return hasError ? <Notification message={message} position={position} variant={variant} onClosed={() => setHasError(false)} /> : null;
}

export default React.memo(NotificationContainer);
