import * as React from 'react';
import { useSelector } from 'react-redux';
import Media from 'react-media';
import RightMenuItem from '../../blackhat-header-footer/header/blackhat-right-menu-item';
import CustomLink from '../../_common/custom-link';
import SignInButton from '../../_common/sign-in-button';
import SignUpButton from '../../_common/sign-up-button';
import { cn, getText, get, getConfig, validInternalUser } from '../../../helpers';
import spgvariables from '../../../utilities/spg-variables';

import APIManager from '../../../utilities/api-manager';
import HistoryInstance from '../../../utilities/history';
import Dropdown from '../../_common/dropdown-button';

const globalNavText = getText('globalNav');

const myAccountText = getText('generic.myAccount');

const SPGVariables = spgvariables();

const linkItems: GlobalHeaderLink[] = Object.values(globalNavText);


const data = getText('blackHeader');
const supportedLangs = SPGVariables.SUPPORTED_LANGUAGES;

const langCode = getText('langCode');

function GlobalHeaderSubMenuMobile(props: GlobalHeaderSubMenuProps) {
    const { dropDownItems, text, headerId = '' } = props;
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const [reqHeader, setReqHeader] = React.useState<string>('');


    React.useEffect(() => {
        setIsActive(HistoryInstance.location.pathname.includes(`/${headerId.toLowerCase()}/`));
        const historyListener = HistoryInstance.listen(location => {
            setIsActive(location.pathname.includes(`/${headerId.toLowerCase()}/`));
        });
        const userOriginCountryCodeFromSession = sessionStorage.getItem('userOriginCountryCode');
        if (userOriginCountryCodeFromSession === null) {
            APIManager.getReqHeaderByName('PMUSER_COUNTRY_CODE').then(response => {
                setReqHeader(response.headers.userorigincountrycode || '');
                sessionStorage.setItem('userOriginCountryCode', response.headers.userorigincountrycode);
            });
        } else {
            setReqHeader(userOriginCountryCodeFromSession);
        }
        return () => {
            historyListener();
        };
    }, []);
    return (
        <Dropdown
            openDropDownOnHover
            className="global-header-sub-menu global-header-menu_toolsandsupport"
            listContent={dropDownItems.map((item: GlobalHeaderLink) => (
                <div key={item.text}>
                    <CustomLink
                        key={item.text}
                        text={item.text}
                        url={item.urlConfigKey ? (getConfig(`${item.urlConfigKey}.${SPGVariables.ENV}`) as string) : item.url}
                        title={item.title}
                        isFullWidth
                        isDisabled={item.isDisabled}
                        activeClassName="global-header-sub-menu__link--active"
                        isMobile
                        // hasSVGIcon
                    />
                    <p>{item.subText}</p>
                </div>
            ))}
            buttonContent={
                <>
                    <span className={cn('global-header-desktop__link', { 'global-header-desktop__link--active': isActive })}>{text}</span>
                </>
            }
            buttonClass="global-header-sub-menu__link"
            menuGrp={text}
            isMobile
        />
    );
}

function GlobalHeaderMenu(props: FeatureFlags) {
    const [investorNavVisible, investorNavOpen] = React.useState<boolean>(false);
    const [supportNavVisible, supportNavOpen] = React.useState<boolean>(false);
    const [siteIsDesktop, setSiteDesktop] = React.useState<boolean>(false);
    const [languageNavVisible, languageNavOpen] = React.useState<boolean>(false);
    const dropdownTwo = React.useRef<HTMLLIElement>(null);
    const dropdownThree = React.useRef<HTMLLIElement>(null);
    const dropdownFour = React.useRef<HTMLLIElement>(null);

    const breakPoint = getConfig('routes.homepage.blackhatBreakpoint');
    const handleResize = () => {
        const curWidth = window.innerWidth;
        if (curWidth > breakPoint && !siteIsDesktop) {
            setSiteDesktop(true);
        } else if (curWidth <= breakPoint && siteIsDesktop) {
            setSiteDesktop(false);
        }
    };

    handleResize();

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const getLangURL = (lancCode: string) => {
        return `${window.location.origin}/${lancCode}/`;
    };

    const languages = [
        { label: 'English', link: getLangURL('en'), langCode: 'en' },
        { label: '中文', link: getLangURL('zh'), langCode: 'zh' },
        { label: '日本語', link: getLangURL('ja'), langCode: 'ja' }
    ];

    React.useEffect(() => {
        document.body.classList.add('kill-scroll');
        document.documentElement.classList.add('kill-scroll');
        return () => {
            document.body.classList.remove('kill-scroll');
            document.documentElement.classList.remove('kill-scroll');
        };
    });
    const { fflagEnableQueryBuilder } = props;

    const isUserLoggedIn = useSelector((state: RootStoreState) => get(state, 'user.isLoggedIn', false));
    const isValidInternalUser = validInternalUser(useSelector((state: RootStoreState) => get(state, 'user')));
    const enabledBlueprintFlag = useSelector((state: RootStoreState) => get(state, 'shared.flags.enabledBlueprintFlag', false));

    const getHeight = () => {
        const parent = document.getElementsByClassName('global-header-mobile')[0];
        if (parent) {
            const rect = parent.getBoundingClientRect();
            return `calc(100vh - ${rect.top}px - ${rect.height}px + 1px)`;
        }
        return '100vh';
    };

    const onClick = (item: string) => {
        if (item === 'investor') {
            investorNavOpen(!investorNavVisible);
            languageNavOpen(false);
            supportNavOpen(false);
        } else if (item === 'language') {
            languageNavOpen(!languageNavVisible);
            investorNavOpen(false);
            supportNavOpen(false);
        } else if (item === 'support') {
            supportNavOpen(!supportNavVisible);
            investorNavOpen(false);
            languageNavOpen(false);
        }
    };

    const filterOutDropDownItems = (list: any[]) => {
        return isUserLoggedIn && isValidInternalUser ? list :
            list.filter(el => !el.hideForNonLoggedInUser);
    };

    return (
        <div className="global-header-menu page-section" style={{ height: getHeight() }} data-testid="global-header-menu">
            <nav className="global-header-menu__nav page-section__inner">
                <ul className="global-header-menu__list">
                    {linkItems.filter(item => (item.url === '/extract' ? fflagEnableQueryBuilder : true)).map(item => {
                        if (item.drowDownList) {
                            return (<li key={item.text} className={cn('global-header-desktop__links', { 'border-bottom': true })}><GlobalHeaderSubMenuMobile text={item.text} headerId={item.id} dropDownItems={filterOutDropDownItems(item.drowDownList) || []} /></li>);
                        }
                        if (item.url === '/blueprints' && !enabledBlueprintFlag) return null;
                        if (item.text === 'Research' && enabledBlueprintFlag) return null;
                        return (
                            <li key={item.text} className={cn('global-header-desktop__links', { 'border-bottom': true })}>
                                <CustomLink
                                    text={item.text}
                                    url={item.url}
                                    title={item.title}
                                    className={`global-header-desktop__link global-header-desktop--small-${langCode}`}
                                    activeClassName="global-header-desktop__link--active"
                                />
                            </li>
                        );
                    })}
                    <Media query={{ maxWidth: 1023 }}>
                        <li className="global-header-menu_links ">
                            <RightMenuItem
                                nodeRef={dropdownTwo}
                                onClick={investorNavOpen}
                                onMobileClick={onClick}
                                isVisible={investorNavVisible}
                                navOpenMobile={investorNavVisible}
                                title={data.investorMenuName}
                                menuItems={data.investorNav}
                                classNameProp="investor"
                            />
                            <RightMenuItem
                                nodeRef={dropdownFour}
                                onClick={languageNavOpen}
                                onMobileClick={onClick}
                                isVisible={languageNavVisible}
                                navOpenMobile={languageNavVisible}
                                title={getText('generic.languages')}
                                menuItems={languages.filter(x => supportedLangs.includes(x.langCode))}
                                isLangSelector
                                classNameProp="language"
                            />
                            <RightMenuItem
                                nodeRef={dropdownThree}
                                onClick={supportNavOpen}
                                onMobileClick={onClick}
                                isVisible={supportNavVisible}
                                navOpenMobile={supportNavVisible}
                                title={data.supportMenuName}
                                menuItems={data.supportNav}
                                classNameProp="support"
                            />
                        </li>
                    </Media>

                    {isUserLoggedIn && (
                        <>
                            <li className="global-header-menu__links">
                                <CustomLink
                                    text={myAccountText}
                                    url="/my-account"
                                    title={myAccountText}
                                    className="global-header-menu__link global-header-menu__link--secondary"
                                    activeClassName="global-header-menu__link--active"
                                    isFullWidth
                                />
                            </li>
                            <li className="global-header-menu__links">
                                <SignInButton isFullWidth className="global-header-menu__link global-header-menu__link--secondary" />
                            </li>
                        </>
                    )}

                    {!isUserLoggedIn && (
                        <>
                            <SignInButton isButton buttonTheme="tertiary" className="global-header-menu__sign-in-btn" isFullWidth />
                            <SignUpButton isButton buttonTheme="tertiary" className="global-header-menu__sign-in-btn" isFullWidth />
                        </>
                    )}
                </ul>
            </nav>
        </div>
    );
}

export default React.memo(GlobalHeaderMenu);
