import { detectLanguage } from '../helpers';
import SPGVariables from './spg-variables';

const spgVariables = SPGVariables();
const setLanguageContext = () => {
    const results = detectLanguage();
    if (!spgVariables.IS_SERVER_REQUEST && results.doRedirect) {
        window.location.href = `${window.location.origin}/${results.langCode}${window.location.pathname}${window.location.search}`;
    }
};

export default setLanguageContext;
