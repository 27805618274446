// Change date format based on localization
import getText from './get-text';

const localeCode = getText('language');
const option = { month: '2-digit', day: '2-digit', year: 'numeric' };
export default (date?: Date | string) => {
    if (date && new Date(date).toString() !== 'Invalid Date') {
        return Intl.DateTimeFormat(localeCode, option).format(new Date(date));
    }
    return null;
};
