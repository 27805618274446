import { isInternalUser, getConfig } from '.';

const allowedDomains = getConfig('internalMailDomains');

const validInternalUser = (user: UserStoreState) => {
    const email = (user && user.userInfo && user.userInfo.Email) || '';
    return isInternalUser(allowedDomains, email);
};

export default validInternalUser;
