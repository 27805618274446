import mapDatasetToDataProfile from './map-dataset-to-dataprofile';

const mapDataBundleToDataProfile = (item: APIDataBundle) => {
    const dataProfiles: DataProfile[] = [];
    let alertNote = '';
    let alertTitle = '';
    const marketDatasetResearchs: MarketDatasetResearch[] = [];
    const marketDatasetNotebooks: MarketDatasetNotebook[] = [];
    const marketDatasetDatasets: MarketDatasetDataset[] = [];
    const dataProviders: Set<string> = new Set<string>();
    if (item.MarketDatasetBundleDatasets && item.MarketDatasetBundleDatasets.length > 0) {
        dataProfiles.push(
            ...item.MarketDatasetBundleDatasets.sort((a, b) => {
                return a.FormOrder > b.FormOrder ? 1 : -1;
            })
                .filter(dataset => dataset.MarketplaceDataset !== null)
                .map(i => {
                    dataProviders.add(i.MarketplaceDataset.MarketplaceDataProvider);
                    return mapDatasetToDataProfile(i.MarketplaceDataset);
                })
        );
        let FormOrder = 0;
        [...item.MarketDatasetBundleDatasets]
            .sort((a, b) => a.FormOrder - b.FormOrder)
            .forEach(bundle => {
                if (bundle.MarketplaceDataset.MarketDatasetNotebooks) {
                    [...bundle.MarketplaceDataset.MarketDatasetNotebooks]
                        .sort((a, b) => a.FormOrder - b.FormOrder)
                        .forEach(notebook => {
                            notebook.FormOrder = FormOrder;
                            marketDatasetNotebooks.push(notebook);
                            FormOrder += 1;
                        });
                }
            });
    }
    if (item.ActiveMarketplaceDSBundleNote) {
        alertNote = item.MarketplaceBundleNote.MarketplaceDSBundleNote || '';
        alertTitle = item.MarketplaceBundleNote.MarketplaceDSBundleNoteTitle || '';
    }
    if (item.MarketDSBundleResearchs && item.MarketDSBundleResearchs.length > 0) {
        marketDatasetResearchs.push(
            ...item.MarketDSBundleResearchs.sort((a, b) => {
                return a.FormOrder > b.FormOrder ? 1 : -1;
            }).map(i => {
                return {
                    FormOrder: i.FormOrder,
                    DatasetResearchTitle: i.MarketplaceDatasetResearch.DatasetResearchTitle,
                    WebSiteURL: i.MarketplaceDatasetResearch.WebSiteURL
                };
            })
        );
    }
    if (item.value && item.value.length > 0) {
        item.value.filter(relatedDataset => { if (!relatedDataset.MarketplaceDataset) return true; return relatedDataset.MarketplaceDataset.ActiveMPDatasetCoverage; }).forEach(relatedDataset => {
            marketDatasetDatasets.push({ ...relatedDataset, KeyMarketplaceDatasetRelated: relatedDataset.KeyMarketplaceDataset, KeyMarketplaceDatasetBundle: relatedDataset.KeyMarketplaceDatasetBundleRel });
        });
    }

    let newOrder = 1;
    const bundleLogos: MarketDatasetLogos[] = [];
    dataProfiles
        .filter(dataProfile => !!dataProfile.marketDatasetLogos && dataProfile.marketDatasetLogos.length > 0)
        .map(dataset => {
            if (dataset.marketDatasetLogos && dataset.marketDatasetLogos.length > 0) {
                dataset.marketDatasetLogos.forEach((LogoObj: MarketDatasetLogos) => {
                    const logo: MarketDatasetLogos = {
                        OnlineImageFilePath: LogoObj.OnlineImageFilePath,
                        FormOrder: newOrder,
                        DataProvider: dataset.dataProvider
                    };
                    if (
                        bundleLogos.filter((itemLogo: MarketDatasetLogos) => itemLogo.OnlineImageFilePath === logo.OnlineImageFilePath).length === 0
                    ) {
                        newOrder++;
                        bundleLogos.push(logo);
                    }
                });
            }
            return bundleLogos;
        });

    return {
        marketDatasetVisualization: item.MarketDSBundleVizs,
        name: item.MarketplaceDatasetBundleName,
        id: item.KeyMarketplaceDatasetBundle,
        isBundle: true,
        addedDate: item.MarketplaceDatasetBundleAdded,
        enhancementDate: item.MktplaceBundleEnhancementDate,
        shortDescription: item.MarketplaceBundleShortDesc,
        thumbnailImageFilePath: item.ThumbnailImageFilePath,
        longDescription: item.MarketplaceBundleLongDesc,
        dataProviderDesc: item.MktplaceBundleDataProviderDesc,
        bundleDatasets: dataProfiles,
        releaseNote: '',
        releaseNoteTitle: '',
        marketDatasetResearchs,
        marketplaceTrialButtonTitle: item.MarketplaceTrialButtonTitle,
        dataProvider: [...dataProviders].join(', '),
        earliestRecord: 0,
        pointInTime: false,
        onlineImageFilePath: '',
        socialSharingImageFilePath: item.SocialSharingImageFilePath,
        earliestSignificantCoverage: 0,
        estimatedDatasetSize: 0,
        estimatedDatasetStorage: 0,
        marketplaceDatasetCoverageDesc: '100',
        marketplaceDataSource: '',
        overviewImageFilePath: '',
        pointInTimeDescription: '',
        marketDatasetDatasets,
        marketDatasetSearchTags: [],
        marketDatasetMIIndustrys: [],
        marketDatasetGeographys: [],
        marketDatasetLinks: [],
        marketDatasetNotebooks,
        deliveryChannel: [{ SPGDestinationPlatform: '', KeySPGDestinationPlatform: 0 }],
        deliveryPlatform: [{ SPGDestinationPlatform: '', KeySPGDestinationPlatform: 0 }],
        marketplaceDatasetFieldCount: { MarketplaceDatasetFieldCount: '' },
        supportDocs: item.MarketDSBundleSupportDocs,
        marketDatasetLogos: bundleLogos,
        hasAlert: item.ActiveMarketplaceDSBundleNote,
        alertNote,
        alertTitle,
        active: item.ActiveMPDatasetCoverage,
        marketMSADataLink: item.KeyMSADataLink,
        marketMSADataFilePath: item.MSADataFilePath,
        marketplaceMSATooltip: item.MarketplaceMSATooltip,
        mktplaceSolutionSupportEmail: item.MktplaceSolutionSupportEmail,
        marketSPGDeliveryPlatform: [],
        marketSPGDestinationPlatform: []
    } as DataProfile;
};
export default mapDataBundleToDataProfile;
