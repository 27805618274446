import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as CloseIcon } from '../../../assets/svg/icons/close.svg';
import { cn } from '../../../helpers';
import logUsage from '../../../utilities/log-usage';

const ModalContext = React.createContext<Function>(() => {});

function Modal(props: ModalProps) {
    const { open, onClose, isFullScreen, isFullWidth, maxWidth, dialogClasses, scrollBody, whiteClose, children, keyPageId, backdropProps, paperProps, dialogWrapStyle, title } = props;

    React.useEffect(() => {
        let dialog: HTMLElement;
        const handleKeyDown = (event: KeyboardEvent) => {
            if (dialog && event.key === 'Tab') {
                const focusableElements = dialog
                    .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                if (focusableElements.length) {
                    const firstElement = focusableElements[0];
                    const lastElement = focusableElements[focusableElements.length - 1];
                    if (event.shiftKey) {
                        if (document.activeElement === firstElement) {
                            event.preventDefault();
                            (lastElement as HTMLElement).focus();
                        }
                    } else if (document.activeElement === lastElement) {
                        event.preventDefault();
                        (firstElement as HTMLElement).focus();
                    }
                }
            }
        };

        if (open) {
            setTimeout(() => {
                dialog = document.getElementsByClassName('MuiDialog-container MuiDialog-scrollBody')[0] as HTMLElement;

                if (dialog) {
                    dialog.addEventListener('keydown', handleKeyDown);
                }
            }, 500);
        }

        return () => {
            if (dialog) dialog.removeEventListener('keydown', handleKeyDown);
        };

    }, [open]);

    if (open && keyPageId) logUsage(keyPageId);

    const handleBackdropClose = (e: {clientX: number}, reason: string) => {
        const maxScrollBarWidth = 18;
        if (reason === 'escapeKeyDown' || (document.documentElement.clientWidth - maxScrollBarWidth) > e.clientX) {
            onClose();
        }
    };

    return (
        <ModalContext.Provider value={onClose}>
            <Dialog
                open={open}
                onClose={handleBackdropClose}
                fullScreen={isFullScreen}
                fullWidth={isFullWidth}
                maxWidth={maxWidth}
                classes={dialogClasses}
                BackdropProps={backdropProps}
                PaperProps={paperProps}
                scroll={scrollBody ? 'body' : 'paper'}
                data-testid="modal"
                style={dialogWrapStyle}
                aria-labelledby="modal-title"
            >
                <button
                    onClick={onClose}
                    className={cn('modal__close', {
                        'modal__close--full-screen': isFullWidth,
                        'modal__close--white': whiteClose
                    })}
                    type="button"
                    aria-label="close-modal"
                    data-testid="modal-close-btn"
                >
                    <CloseIcon />
                </button>

                {children}
            </Dialog>
        </ModalContext.Provider>
    );
}

const ModalMemo = React.memo(Modal);

export { ModalMemo as default, ModalContext };
