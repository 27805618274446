import * as React from 'react';
import { Helmet } from 'react-helmet';
import { getText, getConfig } from '../../../helpers';
import SPGVariables from '../../../utilities/spg-variables';

const language = getText('language');
const siteTitle = getText('generic.siteTitle');
const companyName = 'SPGlobal';
const twitterHandle = '@SPGlobal';
const defaultShareImage = getConfig('components.defaultShareImage');
const defaultLDStructureType = getConfig('components.defaultLDStructureType');
const baseUrl = getText('baseUrl');
const langCode = getText('langCode');
const spgVariables = SPGVariables();
const supportedLangs = spgVariables.SUPPORTED_LANGUAGES;

const additionalSchema = `,"creator":
                    {
                        "@type": "Organization",
                        "name": "S&P Global",
                        "url": "${baseUrl}",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "support",
                            "telephone": "+1-877-863-1306",
                            "email": "support.mi@spglobal.com"
                        }
                    }`;

const getAlternateLinks = (metaData: MetaDataProps) => {
    const alternateLinks = supportedLangs.map(langauge => (
        <link rel="alternate" key={langauge} href={`${baseUrl}/${langauge}${metaData.url}`} hrefLang={langauge} />
    ));
    alternateLinks.push(<link rel="alternate" key="x-lang" href={`${baseUrl}/en${metaData.url}`} hrefLang="x-default" />);
    return alternateLinks;
};

const HelmetManager = (props: HelmetManagerProps) => {
    const { metaData, noIndex } = props;
    const fullTitle = metaData.title ? `${metaData.title} | ${siteTitle}` : siteTitle;
    const type = metaData.type ? metaData.type : defaultLDStructureType;
    const shareImage = metaData.image ? metaData.image : defaultShareImage;
    const Window = window as AnonymousObject;

    // GA logic
    React.useEffect(() => {
        if (Window.dataLayer) {
            Window.dataLayer.push({
                event: 'pageview',
                virtualPageTitle: fullTitle,
                virtualPageUrl: window.location.href
            });
        }
    }, [fullTitle]);

    return (
        <Helmet>
            <title>{fullTitle}</title>
            <meta name="description" content={metaData.description} />

            <link rel="canonical" href={`${baseUrl}/${langCode}${metaData.url}`} />
            {getAlternateLinks(metaData)}
            {noIndex && <meta name="robots" content="noindex" />}

            {/* Schema data */}
            <meta itemProp="name" content={siteTitle} />
            <meta itemProp="description" content={metaData.description} />
            <meta itemProp="image" content={shareImage} />

            {/* Open Graph data */}
            <meta property="og:title" content={fullTitle} />
            <meta property="og:url" content={metaData.url} />
            <meta property="og:description" content={metaData.description} />
            <meta property="og:image" content={shareImage} />
            <meta property="og:site_name" content={siteTitle} />
            <meta property="og:locale" content={language} />
            <meta property="og:type" content="website" />

            {/* not zoom in input elements in mobile Safari-browser */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

            {/* Twitter Card data */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content={twitterHandle} />

            {/* Linked data */}
            <script type="application/ld+json">
                {`
                    {
                        "@context": "http://www.schema.org",
                        "@type": "${type}",
                        "name": "${fullTitle}",
                        "description": "${metaData.description}",
                        "alternateName": "${companyName}",
                        "url": "${baseUrl + metaData.url}",
                        "image": {
                            "@type": "ImageObject",
                            "url": "${baseUrl}${shareImage}"
                        }
                        ${type === 'Dataset' ? additionalSchema : ''}
                    }`}
            </script>
        </Helmet>
    );
};

export default HelmetManager;
