import * as React from 'react';
import useForm from 'react-hook-form';
import { useSelector } from 'react-redux';
import Button from '../_common/button';
import Modal from '../_common/modal';
import { cn, getText, get, getPageTextsSection, getConfig } from '../../helpers';
import keyPageIds from '../../utilities/key-pages';
import TextFieldInput from '../_common/form/text-field-input';
import HtmlContent from '../_common/html-content';
import { ReactComponent as SuccessIcon } from '../../assets/svg/icons/success.svg';
import ErrorBoundary from '../_common/error-boundary';
import Popup from '../_common/popup';
import logUsage from '../../utilities/log-usage';
import APIManager from '../../utilities/api-manager';
import CheckboxInput from '../_common/form/checkbox-input';
import Store from '../../redux/store';

const formText = getText('pages.notifyMe');
const validateEmail = /.+@.+\..+/i;
const Window = window as AnonymousObject;
Window.dataLayer = Window.dataLayer || [];

function NotifyMe(props: NotifyMeProps) {
    const { variant } = props;
    const pageSectionNames = getConfig(`pagetext.${variant}.notifyMeSection`);
    const [modalIsOpen, setModalOpen] = React.useState<boolean>(false);
    const [hasSuccess, setSuccess] = React.useState<boolean>(false);
    const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
    const [hasError, setError] = React.useState<boolean>(false);
    const { className, text, id } = props;
    const { userInfo } = useSelector((state: RootStoreState) => get(state, 'user'));

    let keyPageNumber: number;
    let keyPageNotifyMeSubscribed: number;
    let keyPageNotifyMe: number;
    let notifyMeVariant: string;
    let subscriptionText: string;

    if (variant === 'dataset') {
        keyPageNumber = keyPageIds.datasetPage;
        keyPageNotifyMeSubscribed = keyPageIds.datasetNotifyMeSubscribed;
        keyPageNotifyMe = keyPageIds.datasetNotifyMe;
        notifyMeVariant = 'NotifyMeDataset';
        subscriptionText = formText.subscription.text;
    } else if (variant === 'solution') {
        keyPageNumber = keyPageIds.solutionPage;
        keyPageNotifyMeSubscribed = keyPageIds.solutionNotifyMeSubscribed;
        keyPageNotifyMe = keyPageIds.solutionNotifyMe;
        notifyMeVariant = 'NotifyMeSolution';
        subscriptionText = formText.subscription.solutionText;
    } else {
        keyPageNumber = keyPageIds.blueprintPage;
        keyPageNotifyMeSubscribed = keyPageIds.blueprintNotifyMeSubscribed;
        keyPageNotifyMe = keyPageIds.blueprintNotifyMe;
        notifyMeVariant = 'NotifyMeBlueprint';
        subscriptionText = formText.subscription.blueprintText;
    }
    const keyPage = keyPageNumber;
    const pageTextsData = useSelector((state: RootStoreState) => {
        return get(state, 'shared.pageSections.data', []) as PageSections[];
    }).filter(x => x.KeyOnlinePage === keyPage);

    let alertSubmissionSection = getPageTextsSection(pageSectionNames.alertSubmission, []);
    let alertformSection = getPageTextsSection(pageSectionNames.alertSection, []);

    if (pageTextsData) {
        alertSubmissionSection = getPageTextsSection(pageSectionNames.alertSubmission, pageTextsData);
        alertformSection = getPageTextsSection(pageSectionNames.alertSection, pageTextsData);
    }

    const email = userInfo ? userInfo.Email : '';
    const formInitialValues = {
        Email: email,
        HasSubscribedAll: false
    };
    const { register, setValue, watch, errors, handleSubmit, reset } = useForm<NotifyMeState>({
        defaultValues: formInitialValues
    });
    const formFields = watch();

    const emailInputRequired = {
        required: formText.errorRequired,
        pattern: {
            value: validateEmail,
            message: formText.errorEmail
        }
    };
    const updateFormFields = (name: Extract<keyof NotifyMeState, string>, value: string) => {
        setValue(name, value, true);
    };

    const submissionFailed = () => {
        setSubmitting(false);
        setError(true);
        Window.dataLayer.push({
            event: 'formSubmit',
            submitSuccess: 'false',
            formType: 'Notify me',
            additionalDetails: `Subscribed for coming soon alert | ${text} : ${id}`
        });
    };

    const submissionSuccess = () => {
        setSuccess(true);
        Window.dataLayer.push({
            event: 'formSubmit',
            submitSuccess: 'true',
            formType: 'Notify me',
            additionalDetails: `Subscribed for coming soon alert | ${text} : ${id}`
        });
        logUsage(keyPageNotifyMeSubscribed);
    };

    const onSubmit = (formData: NotifyMeState) => {
        setError(false);
        setSubmitting(true);
        const utmParameters: UTMParameters = get(Store.getState(), 'shared.utmParameters', {});
        const notifyMeRequest: NotifyMeRequest = {
            email: formData.Email,
            marketplaceNewDatasets: variant === 'dataset' ? text : '',
            marketplaceAllDatasetsAlerts: formData.HasSubscribedAll,
            marketplaceNewSolution: variant === 'solution' ? text : '',
            marketplaceBlueprint: variant === 'blueprint' ? text : '',
            variant: notifyMeVariant,
            utmSource: utmParameters.utmSource,
            utmMedium: utmParameters.utmMedium,
            utmCampaign: utmParameters.utmCampaign,
            utmTerm: utmParameters.utmTerm,
            utmContent: utmParameters.utmContent
        };
        APIManager.submitLeadToMarketo(notifyMeRequest)
            .then(response => {
                if (response.data) {
                    submissionSuccess();
                    setSubmitting(false);
                    return;
                }
                submissionFailed();
            })
            .catch(() => submissionFailed());
    };

    const handleClick = () => {
        setModalOpen(true);
        setSuccess(false);
        setSubmitting(false);
        reset(formInitialValues);
        logUsage(keyPageNotifyMe);
    };

    React.useEffect(() => {
        register(
            {
                name: 'Email'
            },
            emailInputRequired
        );
        register({
            name: 'HasSubscribedAll'
        });
    }, []);

    return (
        <>
            <Button
                text={formText.popupButtonText}
                onClick={() => handleClick()}
                theme="primary"
                isFullWidth
                className={cn('notify-me__popup-btn', className)}
            />
            <Modal open={modalIsOpen} onClose={() => setModalOpen(false)} isFullScreen={window.innerWidth < 1024} scrollBody>
                <ErrorBoundary isSilent>
                    <div className="notify-me">
                        {!hasSuccess && (
                            <>
                                <div className="notify-me__header">
                                    <h3>{alertformSection.title}</h3>
                                    <p className="notify-me__instructions p--small">{alertformSection.content}</p>
                                </div>
                                <form
                                    action="/"
                                    noValidate
                                    className="notify-me__form notify-me__section"
                                    onSubmit={handleSubmit(onSubmit)}
                                    data-testid="notify-me"
                                >
                                    <TextFieldInput
                                        defaultValue={formFields.Email}
                                        errorObject={errors.Email}
                                        isRequired
                                        label={formText.email.text}
                                        name={formText.email.name}
                                        onChange={updateFormFields}
                                        type="email"
                                    />
                                    <Button
                                        className="notify-me__button"
                                        isDisabled={isSubmitting && !hasError}
                                        isFullWidth
                                        text={!isSubmitting ? formText.submitButton : formText.submittingText}
                                        theme="primary"
                                        type="submit"
                                    />
                                    <div className="margin-bottom-l">
                                        <CheckboxInput
                                            label={subscriptionText}
                                            name={formText.subscription.name}
                                            onChange={(value: boolean) => {
                                                setValue(formText.subscription.name, value);
                                            }}
                                        />
                                    </div>
                                    {hasError && <p className=" notify-me__error-message">{formText.errorNotification}</p>}
                                    <HtmlContent html={formText.privacyCopy} />
                                </form>
                            </>
                        )}
                        {hasSuccess && (
                            <Popup
                                title={alertSubmissionSection.title}
                                content={alertSubmissionSection.content}
                                link={{
                                    text: alertSubmissionSection.linkText ? alertSubmissionSection.linkText : '',
                                    url: alertSubmissionSection.linkUrl ? alertSubmissionSection.linkUrl : ''
                                }}
                                icon={<SuccessIcon />}
                            />
                        )}
                    </div>
                </ErrorBoundary>
            </Modal>
        </>
    );
}

export default React.memo(NotifyMe);
