import * as LDClient from 'launchdarkly-js-client-sdk';
import camelCase from 'lodash.camelcase';
import { setFeatureFlags } from '../redux/shared/shared.actions';
import Store from '../redux/store';
import { getConfig } from '../helpers';
import spgVariables from './spg-variables';


const spgVars = spgVariables();

let ldClient: LDClient.LDClient;

// WARNING!!!
// Fields being added here need to be reviewed through legal !!!
// Please refer to the documentation located here: https://spglobal.visualstudio.com/SNLServiceModules/_git/spg-webplatform-featureflags?path=%2FREADME.md&_a=preview


const initFlags = (flags: AnonymousObject) => {
    const flagValues: AnonymousObject = {};
    Object.keys(flags).forEach(key => {
        const camelCasedKey = camelCase(key);
        flagValues[camelCasedKey] = flags[key];
    });
    Store.dispatch(setFeatureFlags(flags));
};

const setFlags = (flags: AnonymousObject) => {
    const flagValues: AnonymousObject = { isLDReady: true };
    Object.keys(flags).forEach(key => {
        const camelCasedKey = camelCase(key);
        flagValues[camelCasedKey] = ldClient.variation(key, flags[key]);
    });
    Store.dispatch(setFeatureFlags(flagValues));
};

const subscribeToChanges = (flags: AnonymousObject) => {
    Object.keys(flags).forEach(flag => {
        const camelCasedKey = camelCase(flag);
        ldClient.on(`change:${flag}`, current => {
            const newFlagValue: AnonymousObject = {};
            newFlagValue[camelCasedKey] = current;
            Store.dispatch(setFeatureFlags(newFlagValue));
        });
    });
};

const getUser = (userProfile?: UserInfo | null): LDClient.LDUser => {
    if (userProfile) {
        const user = {
            // WARNING - READ ABOVE
            key: userProfile.KeyOnlineUser,
            // WARNING - READ ABOVE
            country: userProfile.Country,
            // WARNING - READ ABOVE
            custom: {
                // WARNING - READ ABOVE
                internalUser: userProfile.InternalUser,
                // WARNING - READ ABOVE
                webpartProfile: userProfile.KeyWebpartProfile,
                // WARNING - READ ABOVE
                userCompanyDepartment: userProfile.KeyOnlineUserCompanyDepartment,
                // WARNING - READ ABOVE
                userCompanyType: userProfile.KeyOnlineUserCompanyType,
                // WARNING - READ ABOVE
                userCompanyRole: userProfile.KeyOnlineUserCompanyRole,
                // WARNING - READ ABOVE
                culture: userProfile.Culture,
                // WARNING - READ ABOVE
                timeZoneUSName: userProfile.TimeZoneUSName
            }
        };

        if (userProfile.InternalUser) {
            // WARNING - READ ABOVE
            return { ...user, email: userProfile.Email };
        }

        return user;
    }
    return {
        key: '0',
        anonymous: true
    };
};

export default {
    init: (clientSideId: string, hash: string, relayProxy: string, user?: UserInfo) => {
        const flags: AnonymousObject = getConfig('featureFlagDefaults');
        const ldOptions: AnonymousObject = {
            hash,
            baseUrl: relayProxy,
            streamUrl: relayProxy
        };
        flags.fflagEnableQueryBuilder = spgVars.SPGVARS_QUERY_BUILDER;
        flags.enableKenshoDemos = spgVars.SPGVARS_ENABLE_KENSHO_DEMOS;
        flags.enableTranslations = spgVars.SPGVARS_ENABLE_TRANSLATIONS;
        flags.enabledBlueprintFlag = spgVars.SPGVARS_ENABLE_BLUEPRINT;
        initFlags(flags);
        ldClient = LDClient.initialize(clientSideId, getUser(user), ldOptions);

        ldClient.on('ready', () => {
            const flagsSanitised = ldClient.allFlags() || flags;
            setFlags(flagsSanitised);
            subscribeToChanges(flagsSanitised);
        });
    }
};
