/**
 * Logger utility to send data to error reporting tool. This may have additon/modification of logging callback method if such scenario arises.
 */
import { AxiosError } from 'axios';
import { get } from '../helpers';
import Store from '../redux/store';
import newError from '../redux/errors/errors.actions';

enum ErrorType {
    applicationError = 'Application Error',
    validationError = 'Validation Error',
    unknownError = 'Unknown Error'
}

class ErrorManager {


    /**
     * This method is called when you want to send custom data or want to add something to error caught
     * params......
     * ErrorType: This would indicate kind of error and you may introduce new type in Enum if required.
     * ErrorMessage: Original message from error caught or custom message or both
     * errorStack: Stack from error caught or manually created stack  or component path/identifier
     * It would be helpful to troubleshoot with custom error message and error stack as system raised error may not provide component specific information
     */
    public static customError(errorType: ErrorType, errorMessage: string, errorStack?: string) {
        const error = new Error(`${errorType.toString()} :${errorMessage}`);
        error.stack = errorMessage;
        this.logError(error);
    }

    /**
     * This Method sends error message ,stack and status code to reporting Tool along with Url.
     */
    public static apiError(errorParam: AxiosError, parameters?: AnonymousObject) {
        const url = get(errorParam, 'config.url', '');
        // let errorMessage: string;
        // errorMessage = errorParam.message;
        // if (errorParam.response) {
        //     errorMessage = `${url} responded with ${errorParam.response.status}. Error message:${errorParam.message}`;
        //     if (parameters) parameters.StatusCode = errorParam.response.status;
        // } else {
        //     errorMessage = `No response from ${url}.Error message:${errorParam.message}`;
        // }
        // console.log('AAA', errorParam.message);
        const error = new Error(errorParam.message);
        error.stack = parameters && parameters.msg ? parameters.msg : errorParam.message;
        this.logError(error, parameters);
    }

    public static logError(errorParam: Error, parameters?: AnonymousObject) {
        Store.dispatch(newError(errorParam));
        // Functionality is disconnected
        // import('./application-insights').then(AppInsights => {
        //     const appInsights = AppInsights.default;
        //     if (appInsights && appInsights.trackException) appInsights.trackException({ error: errorParam, properties: parameters });
        // });
    }
}

export { ErrorType, ErrorManager };
