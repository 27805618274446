import update from 'react-addons-update';
import { API_FULL_SOLUTION, API_INITIAL_SOLUTIONS } from '../actionTypes';

const initialSolutionsState: SolutionsStoreState = {
    full: {},
    all: {}
};

// TODO - remove any
// eslint-disable-next-line
const reducer = (state: SolutionsStoreState = initialSolutionsState, incomingAction: SolutionsResultAction | SolutionResultAction) => {
    const action = incomingAction;
    switch (action.type) {
        case API_INITIAL_SOLUTIONS: {
            const actionObj = action as SolutionsResultAction;
            console.log('fetchInitialSolutions.async.done', state, actionObj.type, actionObj.response);
            return update(state, { all: { response: { $set: actionObj.response.value }, time: { $set: actionObj.time } } });
        }
        case API_FULL_SOLUTION: {
            const actionObj = action as SolutionResultAction;
            console.debug('fetchFullSolution.async.done', state, actionObj.type, actionObj.response);
            return update(state, { full: { [actionObj.id]: { $set: { response: actionObj.response } } } });
        }
        default:
            return state || initialSolutionsState;
    }
};

export { reducer, initialSolutionsState };
