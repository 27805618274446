import { AxiosResponse } from 'axios';
import { addTask } from 'domain-task';
import { getConfig, dateDiff, get } from '../../helpers';
import APIManager from '../../utilities/api-manager';
import { API_RESEARCHLINKS, API_FAQS, API_PAGETEXTS, UPDATE_UTMPARAMETERS, SET_FLAGS, ID_VARIANTS, TILE_NAMES_GT_25_CHARS } from '../actionTypes';

const apiDataRefreshTime = getConfig('redux.cacheRefreshTimes.long');

const fetchResearchLinks = (): AppThunkAction<ResearchLinksAction> => (dispatch, getState) => {
    const state = getState();
    const cachedResponse = get(state, 'shared.researchLinks', {}) as ResearchLinksState;
    const hasExpired = cachedResponse && (cachedResponse.data && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime));
    if (hasExpired) {
        const fetchTask = APIManager.getResearchLinks().then((response: AxiosResponse<ResearchLinks>) => {
            if (response.data) {
                dispatch({ researchLinks: response.data.docs, time: new Date(), type: API_RESEARCHLINKS });
            }
        });
        addTask(fetchTask);
    }
};

const fetchIdVariants = (): AppThunkAction<any> => (dispatch, getState) => {
    const state = getState();
    const cachedResponse = get(state, 'shared.config', {}) as IdVariantsState;
    const hasExpired = cachedResponse && (cachedResponse.data && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime));
    if (hasExpired) {
        const fetchTask =
        APIManager.getIdVariants()
            .then((response: AxiosResponse<IdVariantsData>) => {
                if (response.data) {
                    dispatch({ config: response.data, time: new Date(), type: ID_VARIANTS });
                }
            });
        addTask(fetchTask);
    }

};

const fetchTileNamesGt25Chars = (): AppThunkAction<any> => (dispatch, getState) => {
    const state = getState();
    const cachedResponse = get(state, 'shared.tileNamesGt25Chars', {}) as TileNamesGt25CharsState;
    const hasExpired = cachedResponse && (cachedResponse.data && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime));
    if (hasExpired) {
        const fetchTask =
        APIManager.getTileNamesGt25Chars()
            .then((response: AxiosResponse<TileNamesGt25CharsData>) => {
                if (response.data) {
                    dispatch({ tileNamesGt25Chars: response.data, time: new Date(), type: TILE_NAMES_GT_25_CHARS });
                }
            });
        addTask(fetchTask);
    }
};

const fetchPageTexts = (): AppThunkAction<PageTextsAction> => (dispatch, getState) => {
    const state = getState();
    const cachedResponse = get(state, 'shared.pageSections', {}) as PageTextsState;
    const hasExpired = cachedResponse && (cachedResponse.data && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime));
    if (hasExpired) {
        const fetchTask = APIManager.getPageTexts().then((response: AxiosResponse<PageSections[]>) => {
            if (response.data) {
                dispatch({ texts: response.data, time: new Date(), type: API_PAGETEXTS });
            }
        });
        addTask(fetchTask);
    }
};

const fetchFAQs = (): AppThunkAction<FAQAction> => (dispatch, getState) => {
    const state = getState();
    const cachedResponse = get(state, 'shared.faqs', {}) as FAQState;
    const hasExpired = cachedResponse && (cachedResponse.faqList && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime));
    if (hasExpired) {
        const fetchTask = APIManager.getFAQs().then((response: AxiosResponse<APIMarketFAQ>) => {
            if (response.data) {
                dispatch({ faqs: response.data, time: new Date(), type: API_FAQS });
            }
        });
        addTask(fetchTask);
    }
};

const updateUTMParameters = (): AppThunkAction<UpdateUTMParametersAction> => dispatch => {
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const utmParameters: UTMParameters = {
        utmSource: urlParams.get('utm_source') || '',
        utmMedium: urlParams.get('utm_medium') || '',
        utmCampaign: urlParams.get('utm_campaign') || '',
        utmTerm: urlParams.get('utm_term') || '',
        utmContent: urlParams.get('utm_content') || ''
    };
    dispatch({ utmParameters, type: UPDATE_UTMPARAMETERS });
};

const setFeatureFlags = (flags: FeatureFlags): AppThunkAction<FeatureFlagsAction> => dispatch => {
    dispatch({ flags, type: SET_FLAGS });
};

export { fetchResearchLinks, fetchFAQs, fetchPageTexts, updateUTMParameters, setFeatureFlags, fetchIdVariants, fetchTileNamesGt25Chars };
