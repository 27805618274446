import SPGVariables from './spg-variables';

const spgVariables = SPGVariables();

export default function gtmTracker() {
    if (spgVariables.IS_RENDERTRON_REQUEST || spgVariables.IS_CRAWLER_REQUEST) return;
    const script = document.createElement('script');
    const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${spgVariables.GTM_CONTAINER_ID}');window.dataLayer=window.dataLayer||[];`;

    script.innerHTML = gtmScript;
    document.getElementsByTagName('head')[0].appendChild(script);

    // Google Tag Manager (noscript) hidden iframe
    const noscript = document.createElement('noscript');
    const gtmiframe = document.createElement('iframe');
    gtmiframe.src = `https://www.googletagmanager.com/ns.html?id=${spgVariables.GTM_CONTAINER_ID}`;
    gtmiframe.height = '0';
    gtmiframe.width = '0';
    gtmiframe.style.visibility = 'hidden';
    gtmiframe.style.display = 'none';
    noscript.appendChild(gtmiframe);
    document.getElementsByTagName('body')[0].appendChild(noscript);
}
