import * as React from 'react';
import { cn } from '../../../helpers';
import './loader.style.scss';

export default function Loader(props: LoaderProps) {
    const { minHeight, className } = props;

    return (
        <div className={cn('loader', className)} style={{ minHeight }}>
            <div className="loader__bars">
                <span />
            </div>
        </div>
    );
}
