import * as React from 'react';
import { useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import Store from '../../../redux/store';
import { CLEAR_STORE } from '../../../redux/actionTypes';
import CustomLink from '../custom-link';
import { get, getText, getConfig } from '../../../helpers';
import security from '../../../utilities/security';

const signInText = getText('generic.signIn');
const signOutText = getText('generic.logOut');
const Window = window as AnonymousObject;
Window.dataLayer = Window.dataLayer || [];


const langCode = getText('langCode');

const flushAppInsights = () => {
    import('../../../utilities/application-insights').then(AppInsights => {
        const appInsights = AppInsights.default;
        if (appInsights) {
            appInsights.flush();
        }
    });
};

function SignInButton(props: SignInButtonProps) {
    const { buttonTheme, className, isButton, isFullWidth } = props;
    const { authState, oktaAuth } = useOktaAuth();
    const isUserLoggedIn = useSelector((state: RootStoreState) => get(state, 'user.isLoggedIn', false));
    const signInUrl = getConfig('urls.mi.ssacs.oktaSignIn');
    const logOut = getConfig('urls.mi.ssacs.oktaLogOut');
    let returnUrl = '';
    const gaLabel = isUserLoggedIn ? '' : 'Sign In';

    const handleClick = async (event: React.SyntheticEvent) => {
        /* istanbul ignore next */
        if (isUserLoggedIn) {
            console.log('isUserLoggedIn');
            if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
                await security.signOut();
                if (authState.isAuthenticated) await oktaAuth.signOut();
            } else {
                event.preventDefault();
                Store.dispatch({ type: CLEAR_STORE });
                await security.signOut().then(() => {
                    flushAppInsights();
                    const websiteUrl = `${window.location.origin}/${langCode}`;
                    if (authState.isAuthenticated) {
                        oktaAuth.signOut({ postLogoutRedirectUri: `${websiteUrl}/logout` });
                    } else {
                        window.location.href = `${window.location.origin}/${logOut}&end_url=${encodeURIComponent(`${websiteUrl}`)}`;
                    }
                });
            }
        } else if (process.env.NODE_ENV === 'production') {
            console.log('Is not logged In');
            if (window.location.pathname.length > 1) {
                returnUrl = `&mpurl=${encodeURIComponent(window.location.pathname)}`;
            }
            event.preventDefault();
            flushAppInsights();
            window.location.href = `${signInUrl + returnUrl}`;
        }
    };

    function getUrl(): string {
        let changedUrl = signInUrl;
        const websiteUrl = `${window.location.origin}/${langCode}`;
        if (process.env.NODE_ENV === 'production') {
            if (isUserLoggedIn) {
                changedUrl = `${window.location.origin}/${logOut}&end_url=${encodeURIComponent(`${websiteUrl}`)}`;
            } else {
                if (window.location.pathname.length > 1) {
                    returnUrl = `&mpurl=${encodeURIComponent(window.location.pathname)}`;
                }
                changedUrl = `${signInUrl + returnUrl}`;
            }
        }
        return changedUrl;
    }

    return (
        <CustomLink
            text={isUserLoggedIn ? signOutText : signInText}
            url={getUrl()}
            buttonTheme={buttonTheme}
            className={className}
            isButton={isButton}
            isFullWidth={isFullWidth}
            onClick={handleClick}
            id="sign-in-link"
            gaLabel={gaLabel}
            isLoginLink={!isUserLoggedIn}
        />
    );
}

export default React.memo(SignInButton);
