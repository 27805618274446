import { getText, get } from '../../helpers';

const mapSearchResultToBluePrint = (item: DataModal) => {
    const localeCode = getText('langCode');
    const MarketplaceDataProvider = get(item, localeCode === 'en' ? 'MarketplaceDataProvider' : `MarketplaceDataProvider_${localeCode}`, '');
    const MktplaceDataProviderDesc = get(
        item,
        localeCode === 'en' ? 'MarketplaceDataProviderDesc' : `MarketplaceDataProviderDesc_${localeCode}`,
        ''
    );
    const MarketplaceBlueprintName = get(item, localeCode === 'en' ? 'Name' : `Name_${localeCode}`, '');
    const MktplaceBlueprintDesc = get(item, localeCode === 'en' ? 'Description' : `Description_${localeCode}`, '');
    const MktplaceBlueprintLongDesc = get(item, localeCode === 'en' ? 'MarketplaceLongDescription' : `MarketplaceLongDescription${localeCode}`, '');
    const itemKeyPlatformService = get(item, 'keyplatformservice', '');
    const KeyPlatformService = itemKeyPlatformService ? itemKeyPlatformService.toLowerCase() : itemKeyPlatformService;
    return {
        MarketplaceDataProviders: {
            MarketplaceDataProvider,
            MktplaceDataProviderDesc
        },
        dataProvider: MarketplaceDataProvider,
        MarketplaceBlueprintName,
        name: MarketplaceBlueprintName,
        AbsoluteBlueprintName: undefined,
        MktplaceBlueprintDesc,
        MarketplaceBlueprintShortDesc: MktplaceBlueprintDesc,
        MarketplaceBlueprintLongDesc: MktplaceBlueprintLongDesc,
        PlatformService: {
            ThumbnailImageFilePath: item.ThumbnailImageFilePath
        },
        MarketplaceBlueprintDateAdded: item.MarketplaceDatasetAdded,
        addedDate: item.MarketplaceDatasetAdded,
        MktplaceSolutionCurrentVerDate: item.MktPlaceDataSetEnhancementDate,
        enhancementDate: item.MktPlaceDataSetEnhancementDate,
        MarketplaceBlueprintEnhancementDate: item.MktPlaceDataSetEnhancementDate,
        KeyPlatformService,
        SocialSharingImageFilePath: item.SocialSharingImageFilePath,
        MktplaceBlueprintUrlName: item.Name,
        KeyMarketplaceBlueprint: item.KeyMarketplaceBlueprint,
        ThumbnailImageFilePath: item.ThumbnailImageFilePath,
    };
};
export default mapSearchResultToBluePrint;
