/**
 *
 * Usage
 *
 * getConfig('hello'); // => 'world'
 *
 */
import merge from 'deepmerge';
import get from './get';
import configFile from '../config.json';

const missingValue = 'Missing value: ';
let config = configFile;

// a function to merge the dev config

if (process.env.NODE_ENV !== 'production' || process.env.REACT_APP_RELEASELOCAL) {
    /* eslint-disable */
    const prodConfig = require('../config.dev.json');
    config = merge(config, prodConfig);
    /* eslint-enable */
}

// the main getter
function getConfig(key: string, defaultValue?: string) {
    return get(config, key, defaultValue || `${missingValue}${key}`);
}

export default getConfig;
