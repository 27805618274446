// import { getConfig } from '../helpers';
// import SPGVariables from './spg-variables';

// const spgVariables = SPGVariables();

// const iKey = getConfig('utilities.aiInstrumentationKey');
// const endpointUrl = `${spgVariables.APIBASE}/${getConfig('urls.mi.logging.logClientTelemetry')}`;

// const config = {
//     instrumentationKey: iKey,
//     endpointUrl: `${endpointUrl}${new Date().getTime()}`,
//     overridePageViewDuration: true
// };

const appInsights: { default: string; trackPageView: Function; flush: Function; trackException: Function; context: { telemetryTrace: { traceID: string } } } | null = null;

export default appInsights;
