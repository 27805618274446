import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { cn } from '../../../../helpers';

const useStyles = makeStyles(
    createStyles({
        formField: {
            alignItems: 'start',
        },
        checkbox: {
            padding: '0 9px 0'
        }
    })
);

const checkboxInput = function CheckboxInput({
    label,
    defaultValue,
    className,
    name,
    onChange
}: CheckboxInputProps) {
    const classes = useStyles(0);

    const [value, setValue] = React.useState<boolean>(defaultValue || false);

    function handleChange(event: object, checked: boolean) {
        setValue(checked);
        onChange(checked);
    }

    return (
        <FormControlLabel
            control={<Checkbox color="primary" name={name} id={name} value={value} onChange={handleChange} className={classes.checkbox} />}
            label={label}
            className={cn(classes.formField, className)}
            data-testid={name}
            checked={value}
        />
    );
};

export default React.memo(checkboxInput);
