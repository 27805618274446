/* eslint-disable @typescript-eslint/no-explicit-any */

const name = 'Toggle Helper';

const toggleAll = (items: AnonymousObject, toggleProp: string, value: any) => {
    return items.map((a: AnonymousObject) => {
        const b = a;
        b[toggleProp] = value;
        return b;
    });
};

const toggleAllAria = (items: AnonymousObject, toggleProp1: string, toggleProp2: string, value: any) => {
    return items.map((a: AnonymousObject) => {
        const b = a;
        b[toggleProp1] = value;
        b[toggleProp2] = value;
        return b;
    });
};

const togglebByProp = (items: AnonymousObject, toggleProp: string, IdProp: string, value: any) => {
    return items.map((b: AnonymousObject) => {
        const a = b;
        a[toggleProp] = a[IdProp] === value ? !a[toggleProp] : a[toggleProp];
        return a;
    });
};

export { name as default, toggleAll, toggleAllAria, togglebByProp };
