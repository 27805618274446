import * as React from 'react';
import CustomLink from '../custom-link';
import HtmlContent from '../html-content';

function Popup(props: PopUpProps) {
    const { title, content, link, icon } = props;
    return (
        <div className="popup">
            <div className="popup__header">
                {
                    icon && icon
                }
                <h2 className="popup__title">{title}</h2>
            </div>
            <div className="small">
                <HtmlContent html={content} />
            </div>
            {link && ((link.url !== '' && link.text !== '') && (
                <CustomLink url={link.url} className="links--alternative" text={link.text} isUpperCase hasIcon />
            ))}
        </div>
    );
}

export default React.memo(Popup);
