/**
 *   Utility to generate and return custom headers for the S&P API
 *
 *   @module security/fetch
 */

import SHA from 'jssha';

// hash key for generating the headers
const hashKey = 'PQ/OZW8SZCU/wUwm2u+Os6oyAmiFfif6QGVAhCLUahh36ui7BJfwymytCgULDZ6G111ud6SuySii544A6Uw+Tw==';

// regex to parse a url, taken from Crockford and modified, see http://www.coderholic.com/javascript-the-good-parts/
const pathExp = /^https?:(?:\/{0,3})[0-9.\-A-Za-z]+(?::\d+)?(.+)$/i;

/**
 *   Generates headers for a given url, see https://docs.google.com/document/d/1YK5Iw465A0UgcuWkwzVFLUronMvCCAGhGUiTV0YmSwU/edit
 *
 *   Returns an object with the following properties:
 *
 *   SNL-Request-Time
 *   SNL-Request-Client
 *
 *   @method
 *   @param {string} url         The url for which to generate headers, assumed not to be encoded
 *   @returns {object}           An object of the format described above
 *
 *   Note, renamed to g from generateHeaders to avoid having a self-descriptive method name in the minified build. Obscurity!
 */

export function g(url: string) {
    // get the signable content
    const exec = pathExp.exec(url);
    const content = exec && exec[1];

    // get current time
    const time = new Date().getTime();

    // signable message
    const signable = `${time}:${content}`;

    // console.log('url', url, 'signable', signable);

    // create the hashing object, use it to create the hash: https://github.com/Caligatio/jsSHA
    const sha1 = new SHA('SHA-1', 'TEXT');
    sha1.setHMACKey(hashKey, 'B64');
    sha1.update(signable);

    const headers = {
        'SNL-Request-Time': time,
        'SNL-Request-Client': `1:${sha1.getHMAC('B64')}`
    };

    // console.log('headers', headers);

    return headers;
}

/**
 *   Returns the headers for a new fetch request
 *   @method
 *   @param {string} url the url to be fetched
 */
export default function generateHeaders(url: string): object {
    return g(url);
}
