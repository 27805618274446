import * as React from 'react';
import { cn } from '../../../helpers';

function GlobalHeaderMenuIcon(props: GlobalHeaderMenuIcon) {
    const { onClick, isActive } = props;

    return (
        <button
            aria-label="Menu"
            type="button"
            // aria-controls="global-header__mobile-nav"
            // aria-expanded={showMobileMenu ? 'true' : 'false'}
            // aria-haspopup="true"
            className={cn('global-header__menu-icon', {
                'global-header__menu-icon--open': isActive
            })}
            onClick={onClick}
            data-testid="menu-icon"
        >
            <span className="global-header__menu-icon__line" />
            <span className="global-header__menu-icon__line" />
            <span className="global-header__menu-icon__line" />
        </button>
    );
}

export default React.memo(GlobalHeaderMenuIcon);
