import { createTheme } from '@material-ui/core/styles';

const colors = {
    black: '#000000',
    spRed: '#D6002A',
    blue: '#6986B0',
    whiteSmoke: '#EAEAEA',
    lightGray: '#DEDEDE',
    grey80: '#CCCCCC',
    boulder: '#767676',
    white: '#FFFFFF'
};

const theme = createTheme({
    typography: {
        fontFamily: ['"Akk Pro"', 'Arial', 'sans-serif'].join(',')
    },
    palette: {
        error: {
            main: colors.spRed
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1260,
            xl: 1440
        }
    },
    overrides: {
        MuiToolbar: {
            root: {
                backgroundColor: colors.grey80
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)'
            }
        },
        MuiPaper: {
            elevation1: {
                border: `1px solid ${colors.grey80}`,
                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)'
            },
            rounded: {
                borderRadius: '0'
            }
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'none'
                }
            },
            colorPrimary: {
                '&:hover': {
                    backgroundColor: 'none'
                },
                '&$checked': {
                    background: 'none'
                }
            },
            colorSecondary: {
                '&:hover': {
                    backgroundColor: 'none'
                },
                '&$checked': {
                    background: 'none'
                }
            }
        },
        MuiInputLabel: {
            root: {
                color: colors.boulder
            },
            asterisk: {
                color: colors.spRed
            }
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: colors.grey80
                }
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: '12px',
                '&$error': {
                    color: colors.spRed
                }
            }
        },
        MuiSelect: {
            select: {
                paddingRight: '35px',
                '&:focus': {
                    backgroundColor: 'none'
                }
            },
            selectMenu: {
                whiteSpace: 'normal'
            },
            iconFilled: {
                color: colors.boulder,
                right: '20px',
                top: 'calc(60% - 12px)'
            }
        },
        MuiMenuItem: {
            gutters: {
                paddingLeft: '16px !important'
            },
            root: {
                whiteSpace: 'normal'
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: colors.white,
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
                borderRight: `1px solid ${colors.lightGray}`,
                borderLeft: `1px solid ${colors.lightGray}`,
                borderTop: `1px solid ${colors.lightGray}`,
                color: colors.boulder,

                '&:hover': {
                    backgroundColor: colors.white
                },
                '&$disabled': {
                    backgroundColor: colors.whiteSmoke,
                    color: colors.boulder
                }
            },
            input: {
                color: colors.black
            },
            multiline: {
                paddingBottom: '7px'
            },
            underline: {
                '&:before': {
                    borderBottom: `2px solid ${colors.grey80}`
                },
                '&:after': {
                    borderBottom: `2px solid ${colors.black}`
                },
                '&$focused': {
                    backgroundColor: colors.white,
                    '&:after': {
                        borderBottom: `2px solid ${colors.black}`
                    }
                },
                '&:hover': {
                    '&:before': {
                        borderBottom: `2px solid ${colors.grey80}`
                    }
                },
                '&$disabled': {
                    '&:before': {
                        borderBottomStyle: 'solid'
                    }
                }
            }
        },
        MuiInputBase: {
            inputMultiline: {
                resize: 'vertical'
            },
            input: {
                '&$disabled': {
                    color: colors.boulder
                }
            }
        },
        MuiCheckbox: {
            colorPrimary: {
                '&$checked': {
                    color: colors.spRed,
                    '&:hover': {
                        backgroundColor: 'none'
                    }
                }
            },
            colorSecondary: {
                '&$checked': {
                    color: colors.spRed,
                    '&:hover': {
                        backgroundColor: 'none'
                    }
                }
            }
        },
        MuiRadio: {
            colorPrimary: {
                '&$checked': {
                    color: colors.spRed,
                    '&:hover': {
                        backgroundColor: 'none'
                    }
                }
            }
        },
        MuiExpansionPanel: {
            root: {
                boxShadow: 'none',
                margin: 0,
                '&$expanded': {
                    margin: 0
                },
                '&:before': {
                    content: 'none'
                }
            },
            rounded: {
                '&:last-child': {
                    borderBottomLeftRadius: '0',
                    borderBottomRightRadius: '0'
                }
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                margin: 0,
                minHeight: 0,
                padding: 0,
                '&$expanded': {
                    margin: 0,
                    minHeight: 0
                }
            },
            content: {
                margin: 0,
                '&$expanded': {
                    margin: 0
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: 0
            }
        },
        MuiSnackbarContent: {
            message: {
                padding: '15px 0'
            }
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: colors.grey80
                }
            },
            button: {
                '&:hover': {
                    backgroundColor: colors.lightGray
                }
            }
        },
        MuiTab: {
            root: {
                textTransform: 'uppercase',
                '&$selected': {
                    color: colors.spRed
                }
            },
            textColorInherit: {
                color: colors.black,
                opacity: 'initial'
            },
            wrapper: {
                fontWeight: 700
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: colors.spRed,
                height: '4px'
            }
        }
    }
});

export { colors, theme };
