import * as React from 'react';
import { ErrorManager, ErrorType } from '../../../utilities/error-manager';

export function reportError(error: Error, errorInfo: React.ErrorInfo) {
    // console.log('** ERROR LOGGER **', error, errorInfo);
    ErrorManager.customError(
        ErrorType.applicationError,
        'Oops! Something went wrong. Please try again later.',
        `${error.name}...component Stack :${errorInfo.componentStack} Error stack: ${error.stack}`
    );
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    private constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            error: null
        };
    }

    public static getDerivedStateFromError(error: Error) {
        return {
            error
        };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const { isSilent } = this.props;
        if (isSilent) {
            reportError(error, errorInfo);
        }
    }

    public render() {
        const { error } = this.state;
        const { children, render, isSilent, replacementComponent } = this.props;
        if (render) return render(children, error);
        if (error && !isSilent) return replacementComponent || null;
        return children;
    }
}
