import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as SPLogo } from '../../../assets/svg/standard-and-poor-global-marketplace.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/icons/close.svg';
import CustomLink from '../../_common/custom-link';
import { cn, getText, get, validInternalUser } from '../../../helpers';
import GlobalHeaderProfileMenu from '../global-header-profile-menu';
import GolbalHeaderSubMenu from '../global-header-sub-menu';

import GlobalHeaderSignIn from '../global-header-sign-in';
import Typeahead from '../../_common/typeahead-old';

const homeText = getText('generic.home');
const globalNavText = getText('globalNav');
const placeholder = getText('generic.typeAheadPlaceholder');
const langCode = getText('langCode');

function GlobalHeaderFormSearchIcon() {
    return (
        <span className="global-header-desktop__search-form-search-icon">
            <SearchIcon />
        </span>
    );
}
function GlobalHeaderFormCloseIcon(onClick: (event: React.SyntheticEvent) => void) {
    return (
        <button type="button" className="global-header-desktop__search-form-close-icon" onClick={onClick} data-testid="close-icon">
            <CloseIcon />
        </button>
    );
}

function GlobalHeaderDesktop(props: FeatureFlags) {
    const [showSearchForm, setShowSearchForm] = React.useState<number>(0);
    const linkItems: GlobalHeaderLink[] = Object.values(globalNavText);
    const { fflagEnableQueryBuilder } = props;
    const enabledBlueprintFlag = useSelector((state: RootStoreState) => get(state, 'shared.flags.enabledBlueprintFlag', false));

    React.useEffect(() => {
        document.addEventListener('HomeButtonClick', () => {
            setShowSearchForm(0);
        });
    });

    const handleOnAnimationEnd = (event: React.AnimationEvent) => {
        if (event.animationName.indexOf('search-input') > -1) setShowSearchForm(showSearchForm + 1);
    };

    const isUserLoggedIn = useSelector((state: RootStoreState) => get(state, 'user.isLoggedIn', false));
    const isValidInternalUser = validInternalUser(useSelector((state: RootStoreState) => get(state, 'user')));

    const handleSearchButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onSearchIconClick();
    };

    const handleHeaderBodyClick = () => {
        props.onHeaderBodyClick();
    };

    const filterOutDropDownItems = (list: any[]) => {
        return isUserLoggedIn && isValidInternalUser ? list :
            list.filter(el => !el.hideForNonLoggedInUser);
    };

    return (
        <header role="presentation" onClick={handleHeaderBodyClick} onKeyDown={handleHeaderBodyClick} className={cn('global-header-desktop', 'page-section')}>
            <div className="page-section__inner">
                <NavLink exact to={`/${langCode}/`} title={homeText} className="global-header-desktop__logo" activeClassName="global-header-desktop__logo--active">
                    <SPLogo onClick={() => setShowSearchForm(0)} />
                </NavLink>
                <nav className="global-header-desktop__nav">
                    <ul className="global-header-desktop__list">
                        {linkItems.filter(item => (item.url === '/extract' ? fflagEnableQueryBuilder : true)).map(item => {
                            if (item.drowDownList) {
                                return (<li key={item.text} className="global-header-desktop__links"><GolbalHeaderSubMenu text={item.text} headerId={item.id} dropDownItems={filterOutDropDownItems(item.drowDownList) || []} /></li>);
                            }
                            if (item.url === '/blueprints' && !enabledBlueprintFlag) return null;
                            if (item.text === 'Research' && enabledBlueprintFlag) return null;
                            return (
                                <li key={item.text} className="global-header-desktop__links">
                                    <CustomLink
                                        text={item.text}
                                        url={item.url}
                                        title={item.title}
                                        className={`global-header-desktop__link global-header-desktop--small-${langCode}`}
                                        activeClassName="global-header-desktop__link--active"
                                    />
                                </li>
                            );
                        })}
                    </ul>
                    {/* home page search icon */}
                    {(useLocation().pathname === `/${langCode}/` || useLocation().pathname === `/${langCode}`) && (
                        <button type="button" className="global-header-desktop__search-btn button--icon-only" onClick={e => handleSearchButtonClick(e)} data-testid="search-icon" aria-label="search icon">
                            <SearchIcon />
                        </button>
                    )}
                    {/* NOT HOME page search icon */}
                    {(useLocation().pathname !== `/${langCode}/` && useLocation().pathname !== `/${langCode}`) && (
                        <button type="button" className="global-header-desktop__search-btn button--icon-only" onClick={() => setShowSearchForm(1)} data-testid="search-icon" aria-label="search icon">
                            <SearchIcon />
                        </button>
                    )}
                    {showSearchForm > 0 && showSearchForm < 4 && (
                        <div
                            className={cn('global-header-desktop__search-form', {
                                'global-header-desktop__search-form--closing': showSearchForm === 3
                            })}
                            onAnimationEnd={handleOnAnimationEnd}
                            data-testid="global-header-desktop__search"
                        >
                            <Typeahead
                                iconPrepend={GlobalHeaderFormSearchIcon()}
                                iconAppend={GlobalHeaderFormCloseIcon(() => setShowSearchForm(3))}
                                name="global-search"
                                className="typeahead--equal-height global-header-desktop__search-form-input"
                                placeholder={placeholder}
                                autoFocus
                                autoClose={showSearchForm === 3}
                                onComplete={() => setShowSearchForm(3)}
                            />
                        </div>
                    )}
                </nav>
                {!isUserLoggedIn ? (
                    <GlobalHeaderSignIn className="global-header-desktop__sign-in" />
                ) : (
                    <GlobalHeaderProfileMenu className="global-header-desktop__profile-menu" />
                )}
            </div>
        </header>
    );
}

export default React.memo(GlobalHeaderDesktop);
