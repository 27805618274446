import SPGVariables from './spg-variables';

const spgVariables = SPGVariables();

if (!(spgVariables.IS_RENDERTRON_REQUEST || spgVariables.IS_CRAWLER_REQUEST)) {
    let link = document.createElement('link');
    link.rel = 'preconnect';
    link.type = 'text/plain';
    link.crossOrigin = 'anonymous';
    link.className = 'optanon-category-C0002';
    link.href = '//munchkin.marketo.net';
    document.getElementsByTagName('head')[0].appendChild(link);

    link = document.createElement('link');
    link.type = 'text/plain';
    link.rel = 'preconnect';
    link.className = 'optanon-category-C0002';
    link.crossOrigin = 'anonymous';
    link.href = `//${spgVariables.MARKETO_MUNCHKINID}.mktoresp.com`;
    document.getElementsByTagName('head')[0].appendChild(link);

    const bizible = document.createElement('script');
    bizible.type = 'text/plain';
    bizible.className = 'optanon-category-C0002';
    bizible.src = '//cdn.bizible.com/scripts/bizible.js';
    bizible.async = true;
    document.getElementsByTagName('head')[0].appendChild(bizible);
}

export default function marketoTracker() {
    if (spgVariables.IS_RENDERTRON_REQUEST || spgVariables.IS_CRAWLER_REQUEST) return;

    const script = document.createElement('script');
    script.type = 'text/plain';
    script.className = 'optanon-category-C0002';
    const marketoScript =
                      `(function() {
                        var didInit = false;
                        function initMunchkin() {
                            if(didInit === false) {
                            didInit = true;
                            Munchkin.init('${spgVariables.MARKETO_MUNCHKINID}');
                            }
                        }
                        var s = document.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = '//munchkin.marketo.net/munchkin.js';
                        s.onreadystatechange = function() {
                            if(this.readyState == 'complete' || this.readyState == 'loaded') {
                            initMunchkin();
                            }
                        };
                        s.onload = initMunchkin;
                        document.getElementsByTagName('head')[0].appendChild(s);
                        })();`;
    script.innerHTML = marketoScript;
    document.getElementsByTagName('head')[0].appendChild(script);
}
