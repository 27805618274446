import update from 'react-addons-update';
import { API_RESEARCHLINKS, API_FAQS, API_PAGETEXTS, UPDATE_UTMPARAMETERS, SET_FLAGS, ID_VARIANTS, TILE_NAMES_GT_25_CHARS } from '../actionTypes';
import SPGVariables from '../../utilities/spg-variables';

const spgVars = SPGVariables();

const initialSharedState: SharedStoreState = {
    researchLinks: {
        data: [],
        time: undefined
    },
    faqs: {
        faqList: [],
        time: undefined
    },
    pageSections: {
        data: [],
        time: undefined
    },
    utmParameters: {
        utmSource: '',
        utmMedium: '',
        utmCampaign: '',
        utmTerm: '',
        utmContent: ''
    },
    flags: {
        isLDReady: false,
        fflagEnableQueryBuilder: spgVars.SPGVARS_QUERY_BUILDER,
        enableKenshoDemos: spgVars.SPGVARS_ENABLE_KENSHO_DEMOS,
        enableTranslations: spgVars.SPGVARS_ENABLE_TRANSLATIONS,
        enabledBlueprintFlag: spgVars.SPGVARS_ENABLE_BLUEPRINT,
        fflagEnableWorkbench: spgVars.SPGVARS_ENABLE_WORKBENCH,
        hideFooterFlag: false
    },
    config: {
        data: {
            Solution: { IdVariant: [] },
            Dataset: { IdVariant: [] }
        },
        time: undefined

    },
    tileNamesGt25Chars: {
        data: {
            TileNames: []
        },
        time: undefined
    }
};

// TODO - remove any
// eslint-disable-next-line
const reducer = (state: SharedStoreState = initialSharedState,
    incomingAction: PageTextsAction |
        ResearchLinksAction |
        FAQAction |
        UpdateUTMParametersAction |
        FeatureFlagsAction | IdVariantsAction) => {
    const action = incomingAction;
    switch (action.type) {
        case API_RESEARCHLINKS: {
            const actionObj = action as ResearchLinksAction;
            return update(state, { researchLinks: { data: { $set: actionObj.researchLinks }, time: { $set: actionObj.time } } });
        }
        case API_FAQS: {
            const actionObj = action as FAQAction;
            return update(state, { faqs: { faqList: { $set: actionObj.faqs }, time: { $set: actionObj.time } } });
        }
        case API_PAGETEXTS: {
            const actionObj = action as PageTextsAction;
            return update(state, { pageSections: { data: { $set: actionObj.texts }, time: { $set: actionObj.time } } });
        }
        case UPDATE_UTMPARAMETERS: {
            const actionObj = action as UpdateUTMParametersAction;
            return update(state, { utmParameters: { $set: actionObj.utmParameters } });
        }
        case SET_FLAGS: {
            const actionObj = action as FeatureFlagsAction;
            return update(state, { flags: { $merge: actionObj.flags } });
        }
        case ID_VARIANTS: {
            const actionObj = action as IdVariantsAction;
            return update(state, { config: { data: { $set: actionObj.config }, time: { $set: actionObj.time } } });
        }
        case TILE_NAMES_GT_25_CHARS: {
            const actionObj = action as TileNamesGt25CharsAction;
            return update(state, { tileNamesGt25Chars: { data: { $set: actionObj.tileNamesGt25Chars }, time: { $set: actionObj.time } } });
        }
        default:
            return state || initialSharedState;
    }
};

export { reducer, initialSharedState };
