/* eslint-disable @typescript-eslint/camelcase */
import getConfig from './get-config';

const name = 'search filter Builder';
const filterOptions = getConfig('components.filterOptions');
const keyRegion: string = filterOptions.region.keyColumn;
const keyPointInTime: string = filterOptions.pointInTime.keyColumn;
const keyIndustry: string = filterOptions.industry.keyColumn;
const keyCategory: string = filterOptions.category.keyColumn;
const keyHistory: string = filterOptions.history.keyColumn;
const keyDelivery: string = filterOptions.delivery.keyColumn;
const keyActiveFlag: string = filterOptions.activeFlag.keyColumn;
const keyDeliveryPlatform: string = filterOptions.deliveryPlatform.keyColumn;
const keyVendor: string = filterOptions.vendor.keyColumn;
const keyQueryType: string = filterOptions.querytype.keyColumn;
const keyDataset: string = filterOptions.dataset.keyColumn;

const searchFilterBuilder = (filters: string[]) => {
    const pointInTime: string[] = [];
    const delivery: string[] = [];
    const deliveryPlatform: string[] = [];
    const industry: string[] = [];
    const category: string[] = [];
    const region: string[] = [];
    const vendor: string[] = [];
    const querytype: string[] = [];
    const dataset: string[] = [];
    let history = '';
    const activeFlag: string[] = [];
    const filter: Record<string, AnonymousObject>[] = [];
    let geographyQuery = '';
    filters.forEach((filterData: string) => {
        if (filterData.split(':')[0] === keyRegion) {
            const keyValue: string[] = filterData.split(':');
            const GeographyArray: number[] = keyValue[1].split(',').map(value => parseInt(value, 0));
            if (GeographyArray.length === 1) {
                region.push(filterData.split(':')[1]);
            } else {
                // for Global we need query_string with anding
                geographyQuery = `KeyGeographyTree:(${filterData.split(':')[1].replace(/,/g, ' AND ')})`;
            }
        }
        if (filterData.split(':')[0] === keyDelivery) delivery.push(filterData.split(':')[1]);
        if (filterData.split(':')[0] === keyPointInTime) pointInTime.push(filterData.split(':')[1]);
        if (filterData.split(':')[0] === keyDeliveryPlatform) deliveryPlatform.push(filterData.split(':')[1]);
        if (filterData.split(':')[0] === keyIndustry) industry.push(filterData.split(':')[1]);
        if (filterData.split(':')[0] === keyCategory) category.push(filterData.split(':')[1]);
        if (filterData.split(':')[0] === keyHistory) [, history] = filterData.split(':');
        if (filterData.split(':')[0] === keyActiveFlag) activeFlag.push(filterData.split(':')[1]);
        if (filterData.split(':')[0] === keyVendor) vendor.push(filterData.split(':')[1]);
        if (filterData.split(':')[0] === keyQueryType) querytype.push(filterData.split(':')[1]);
        if (filterData.split(':')[0] === keyDataset) dataset.push(filterData.split(':')[1]);
    });

    if (pointInTime.length > 0) filter.push({ terms: { PointInTime: pointInTime } });
    if (delivery.length > 0) filter.push({ terms: { KeySPGDestinationPlatform: delivery } });
    if (deliveryPlatform.length > 0) filter.push({ terms: { KeySPGDeliveryPlatform: deliveryPlatform } });
    if (industry.length > 0) filter.push({ terms: { KeyMIIndustryTree: industry } });
    if (category.length > 0) filter.push({ terms: { KeyMarketplaceSearchTag: category } });
    if (activeFlag.length > 0) filter.push({ terms: { ActiveMPDatasetCoverage: activeFlag } });
    if (vendor.length > 0) filter.push({ terms: { MarketPlaceVendor: vendor } });
    if (querytype.length > 0) filter.push({ terms: { KeyMarketplaceQueryType: querytype } });
    if (dataset.length > 0) filter.push({ terms: { DatasetID: dataset } });

    if (history.length > 0) {
        if (history.split(' ')[0] === '[*') {
            filter.push({ range: { EarliestRecord: { lte: history.split(' ')[2].replace(']', '') } } });
        } else {
            filter.push({ range: { EarliestRecord: { gte: history.split(' ')[0].replace('[', '') } } });
        }
    }
    // if geography has global use query_string else terms
    if (geographyQuery !== '') {
        filter.push({ query_string: { query: region.length > 0 ? `${keyRegion}:(${region.join(' OR ')}) OR ${geographyQuery}` : geographyQuery } });
    } else if (region.length > 0) {
        filter.push({ terms: { KeyGeographyTree: region } });
    }
    return filter;
};

export { name as default, searchFilterBuilder };
