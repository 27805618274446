/**
 *
 * Usage
 *
 * getText('hello'); // => 'world'
 *
 * getText('pages.dataSets.text'); // => 'Datasets'
 *
 */

import deepMerge from 'deepmerge';
import get from './get';
import langFileEN from '../languages/en/en-us.json';
import detectLanguage from './detect-language';

const missingValue = 'Missing text: ';
const result = detectLanguage();

const overwriteMerge = (destinationArray: [], sourceArray: []) => sourceArray;

const setLangResource = (language?: string) => {
    switch (language || result.langCode) {
        /* eslint-disable */
        case 'ja':
            const langFileJA = require('../languages/ja/ja-jp.json');
            return deepMerge(langFileEN, langFileJA, { arrayMerge: overwriteMerge });
        case 'zh':
            const langFileZH = require('../languages/zh/zh-cn.json');
            return deepMerge(langFileEN, langFileZH, { arrayMerge: overwriteMerge });
        /* eslint-enable */
        default:
            return langFileEN;
    }
};

let langFile = setLangResource();

const setLanguage = (language: string) => {
    langFile = setLangResource(language);
};

const getText = (key: string) => {
    return get(langFile, key, `${missingValue}${key}`);
};


export { getText as default, setLanguage };
