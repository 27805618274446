import * as React from 'react';
import { getText } from '../../../helpers';

const data = getText('blackFooter');
const BlackhatFooter = function BlackhatFooter() {
    const [xImgSrc, setXImgSrc] = React.useState<string>('/x-twitter.jpg');

    const handleMouseEnter = () => {
        setXImgSrc('/x-twitter-grey.png');
    };

    const handleMouseLeave = () => {
        setXImgSrc('/x-twitter.jpg');
    };

    return (
        <footer className="footer page-section" role="contentinfo">
            <div className="footer__content page-section__inner">
                <ul className="footer__link-list">
                    {data.primaryLinks.map((item: AnonymousObject) => {
                        return (
                            <li key={item.label} className="footer__link-items">
                                <a href={item.url} title="">
                                    {item.label}
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <ul className="footer__link-list footer__link-list--secondary">
                    {data.secondaryLinks.map((item: AnonymousObject) => {
                        return (
                            <li key={item.label} className="footer__link-items">
                                <a href={item.url} title="" onClick={() => {}}>
                                    {item.label}
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <div className="footer__bottom-wrapper">
                    <ul className="footer__social">
                        {data.socialLinks.map((item: AnonymousObject) => {
                            if (item.label === 'twitter') {
                                return (
                                    <li key={item.label} className="footer__social-items">
                                        <a href={item.url} title="" aria-label={item.label} onClick={() => {}}>
                                            <img
                                                style={{ maxHeight: '16px', marginBottom: '0px' }}
                                                src={xImgSrc}
                                                alt="x twitter icon"
                                                data-testid="xImageIcon"
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                            />
                                        </a>
                                    </li>
                                );
                            }
                            return (
                                <li key={item.label} className="footer__social-items">
                                    <a href={item.url} title="" aria-label={item.label} onClick={() => {}}>
                                        <i className={`fa fa-${item.label}`} aria-hidden="true" />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                    <ul className="footer__notice">
                        {data.noticeLinks.map((item: AnonymousObject) => {
                            const target = item.target ? item.target : '_self';
                            const relAttribute = item.target ? 'noopener noreferrer' : '';
                            const listItem = item.url ? (
                                <a href={item.url} target={target} rel={relAttribute} onClick={() => {}}>
                                    {item.label}
                                </a>
                            ) : (
                                item.label.replace('{year}', new Date().getFullYear())
                            );
                            return (
                                <li key={item.label} className="footer__notice-items">
                                    {listItem}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default React.memo(BlackhatFooter);
