import queryString from 'query-string';
import history from './history';

const queryStringManager = {
    /**  Getters */
    getSearch: () => {
        return queryString.parse(window.location.search);
    },

    getSearchItem: (param: string) => {
        const parsed = queryString.parse(window.location.search);
        return parsed[param];
    },

    stringify: (param: {}) => {
        return queryString.stringify(param);
    },

    /** Setters */
    setSearchItem: (queryStringParam: object, silent?: boolean) => {
        const searchParsed = queryString.parse(window.location.search);
        const location = `${window.location.pathname}?${queryString.stringify({ ...searchParsed, ...queryStringParam })}`;
        if (silent) return history.replace(location);
        return history.push(location);
    },

    removeSearchItem: (key: string, silent?: boolean) => {
        const searchParsed = queryString.parse(window.location.search);
        delete searchParsed[key];
        const location = `${window.location.pathname}?${queryString.stringify(searchParsed)}`;
        if (silent) return history.replace(location);
        return history.push(location);
    },

    setHash: (hash: string) => {
        if (hash.length) {
            window.history.replaceState(null, '', hash);
        } else {
            window.history.replaceState(null, '', window.location.pathname + window.location.search);
        }
    },

    /** Updaters */
    updateSearchItem: (key: string, value: string | null, silent?: boolean) => {
        let searchParsed: AnonymousObject = {};
        searchParsed = queryString.parse(window.location.search);
        searchParsed[key] = value;
        const searchString = queryString.stringify(searchParsed);
        const location = `${window.location.pathname}?${searchString}`;
        if (silent) return history.replace(location);
        return history.push(location);
    }

};

export default queryStringManager;
