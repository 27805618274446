import update from 'react-addons-update';
import { QUERY_DOWNLOADS, QUERY_STEPS } from '../actionTypes';

const initialQueryBuilderState: QueryBuilderStoreState = {
    downloads: {
        data: undefined,
        time: undefined
    },
    steps: undefined
};

const reducer = (state: QueryBuilderStoreState = initialQueryBuilderState, incomingAction: QueryBuilderDownloadAction | QueryBuilderStepsAction) => {
    const action = incomingAction;
    switch (action.type) {
        case QUERY_DOWNLOADS: {
            const actionObj = action as QueryBuilderDownloadAction;
            return update(state, { downloads: { data: { $set: actionObj.downloads }, time: { $set: actionObj.time } } });
        }
        case QUERY_STEPS: {
            const actionObj = action as QueryBuilderStepsAction;
            return update(state, { steps: { $set: actionObj.steps }, time: { $set: actionObj.time } });
        }
        default:
            return state || initialQueryBuilderState;
    }
};

export { reducer, initialQueryBuilderState };
