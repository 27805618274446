import SPGVariables from './utilities/spg-variables';

const spgVariables = SPGVariables();

function registerValidSW(swUrl: string) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        console.log('Offline fallback page is installed!');
                    }
                };
            };
        })
        .catch(error => {
            console.error('Error during service worker registration:', error);
        });
}

function register() {
    if (spgVariables.IS_RENDERTRON_REQUEST || spgVariables.IS_CRAWLER_REQUEST) return;
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
            registerValidSW(swUrl);
        });
    }
}

export { register as default };
