
const name = 'UnMask Helper';

function GetBase(x: number) {
    let n = 0;
    let y = 0;
    while (x > y) {
        y = 2 ** (n + 1);
        if (x < y) return n;
        n++;
    }
    return n;
}

const unMask = (value: number) => {
    const unmask: number[] = [];
    let x = value;
    let y = 0;
    while (x > 0) {
        y = GetBase(x);
        unmask.push(y);
        x -= (2 ** y);
    }
    return unmask;
};

export { name as default, unMask };
