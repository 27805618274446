import { datadogRum } from '@datadog/browser-rum';

export default function datadogtracker() {
    datadogRum.init({
        applicationId: 'b7925abc-ce8b-41a5-a728-25e3513c302e',
        clientToken: 'pub47cc5e76b2ddbce149c5b008fdde123c',
        site: 'datadoghq.com',
        service: 'marketplace-storefront',
        // eslint-disable-next-line no-nested-ternary
        env: window.location.hostname.includes('stage') ? 'stage' : (window.location.hostname.includes('dev') ? 'development' : 'production'),
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        replaySampleRate: 100,
        trackInteractions: true
    });

    datadogRum.startSessionReplayRecording();
}
