import { getText, get } from '../../helpers';

function removeLastComma(string: string) {
    const n = string.lastIndexOf(',');
    return n > -1 && (n + 1) === string.length ? string.substring(0, n) : string;
}
const mapSearchResultToDataProfileCard = (item: DataModal) => {
    const isBundle = item.isType === 'Bundle';
    const localeCode = getText('langCode');
    const marketplaceDataProvider = get(item, localeCode === 'en' ? 'MarketplaceDataProvider' : `MarketplaceDataProvider_${localeCode}`, '');
    return {
        name: get(item, localeCode === 'en' ? 'Name' : `Name_${localeCode}`, ''),
        urlName: item.Name,
        id: isBundle ? item.KeyMarketplaceDatasetBundle : item.KeyMarketplaceDataset,
        isBundle,
        addedDate: item.MarketplaceDatasetAdded,
        enhancementDate: item.MktPlaceDataSetEnhancementDate,
        shortDescription: get(item, localeCode === 'en' ? 'Description' : `Description_${localeCode}`, ''),
        thumbnailImageFilePath: item.ThumbnailImageFilePath,
        longDescription: get(item, localeCode === 'en' ? 'MarketplaceLongDescription' : `MarketplaceLongDescription_${localeCode}`, ''),
        dataProvider: marketplaceDataProvider && isBundle ? removeLastComma(marketplaceDataProvider.trim()) : marketplaceDataProvider
    };
};
export default mapSearchResultToDataProfileCard;
