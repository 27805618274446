
import { isInternalUser, getConfig } from '.';


const authSolList = getConfig('authorizedsolutions');
const allowedDomains = getConfig('internalMailDomains');

const authdatablueprint = (list: APIBlueprint[], email: string) => {

    const isValidUser = isInternalUser(allowedDomains, email);
    // Hide sensitive blueprints for external users
    if (list && list.length > 0 && list as APIBlueprint[] && !isValidUser) {
        const value = list.filter(x => !authSolList.includes(x.KeyMarketplaceBlueprint));
        return value || [];
    }
    return list;
};

export default authdatablueprint;
