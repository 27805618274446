import { addTask } from 'domain-task';
import { API_FILTERS } from '../actionTypes';
import APIManager from '../../utilities/api-manager';
import { get, getConfig, dateDiff } from '../../helpers';

const apiDataRefreshTime = getConfig('redux.cacheRefreshTimes.medium');

const fetchFilters = (): AppThunkAction<FiltersResultAction> => (dispatch, getState) => {
    const state = getState();
    const cachedResponse = get(state, 'filters');
    const hasExpired = cachedResponse && (!cachedResponse.time ? true : dateDiff(cachedResponse.time) > apiDataRefreshTime);
    if (hasExpired) {
        const fetchTask = APIManager.getBatchFilters().then(response => {
            dispatch({ response, time: new Date(), type: API_FILTERS });
        });
        addTask(fetchTask);
    }
};

export default fetchFilters;
