import SPGVariables from '../utilities/spg-variables';

const spgVariables = SPGVariables();
const detectLanguage = () => {
    const languages = spgVariables.SUPPORTED_LANGUAGES;
    const langCode = spgVariables.IS_SERVER_REQUEST ? 'en' : window.location.pathname.split('/')[1];
    if (langCode.length !== 2 || (langCode.length === 2 && !languages.includes(langCode))) {
        let browserLangCode = 'en';
        if ((typeof window) !== 'undefined') {
            browserLangCode = window.navigator.language.substring(0, 2);
        }
        browserLangCode = browserLangCode && browserLangCode.length === 2 && languages.includes(browserLangCode) ? browserLangCode : 'en';
        return { langCode: browserLangCode, doRedirect: true };
    }
    return { langCode, doRedirect: false };
};

export default detectLanguage;
