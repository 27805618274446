import * as React from 'react';
import DataFlag from '../../../data-flag';
import CustomLink from '../../custom-link';
import { cn, getText } from '../../../../helpers';
import buildUrl from '../../../../utilities/build-url';

const maxDropdownResults = 5;
const seeAllResults = getText('generic.seeAllResults');

const Dataset = getText('generic.dataset');
const Solution = getText('generic.solution');
const Blueprint = getText('generic.blueprint');

function TypeaheadDropdown(props: TypeaheadDropdownProps) {
    const { dataProfiles, inputRef, isMobileDropdown, searchToken, loading, className, onClick, parentRef } = props;
    const hasMoreThan5Results = dataProfiles.length > maxDropdownResults;

    let highlightedItemIndex = -1;

    //  REFs
    let allResultsRef: HTMLAnchorElement | null = null;
    let refsArray: HTMLAnchorElement[] = [];

    const setAllResultsRef = (element: HTMLAnchorElement) => {
        allResultsRef = element;
    };

    const keyPressHandler = (event: KeyboardEvent) => {
        const inputIndex = -1;
        const firstResultIndex = 0;
        const lastResultIndex = refsArray.length - 1;
        const allResultsBtnIndex = lastResultIndex + 1;

        // select input when going up from first result
        if (highlightedItemIndex <= firstResultIndex && (event.key === 'Up' || event.key === 'ArrowUp')) {
            highlightedItemIndex = inputIndex;
            if (inputRef.current) inputRef.current.focus();
            return;
        }

        // select button when going down from last result
        if (highlightedItemIndex === lastResultIndex && (event.key === 'Down' || event.key === 'ArrowDown') && hasMoreThan5Results) {
            event.preventDefault();
            if (allResultsRef) allResultsRef.focus();
            highlightedItemIndex++;
            return;
        }

        // do nothing when going down from button
        if (
            (highlightedItemIndex === allResultsBtnIndex || (highlightedItemIndex === lastResultIndex && !hasMoreThan5Results)) &&
            (event.key === 'Down' || event.key === 'ArrowDown')
        ) {
            event.preventDefault();
            return;
        }

        // set index of to be focussed
        switch (event.key) {
            case 'Up':
            case 'ArrowUp':
                highlightedItemIndex -= 1;
                break;
            case 'Down':
            case 'ArrowDown':
                highlightedItemIndex += 1;
                break;
            default:
                break;
        }

        // focus item
        if (event.key === 'up' || event.key === 'ArrowUp' || event.key === 'Down' || event.key === 'ArrowDown') {
            event.preventDefault();
            refsArray[highlightedItemIndex].focus();
        }
    };

    React.useEffect(() => {
        const container = parentRef ? parentRef.current : null;
        if (container) container.addEventListener('keydown', keyPressHandler);
        return () => {
            if (container) container.removeEventListener('keydown', keyPressHandler);
        };
    });

    const setResultRef = (element: HTMLAnchorElement) => {
        refsArray = [...refsArray, element];
    };

    const buildSolution = (solution: APISolution) => {
        const solutionPathSegment = buildUrl(solution.AbsoluteSolutionName || solution.MktplaceSolutionUrlName || solution.MktplaceSolutionName, solution.KeyPlatformService);
        const url = `/solutions/${solutionPathSegment}`;
        return (
            <li key={solution.KeyPlatformService} className="typeahead-dropdown-old__list-item">
                <span className="typeahead-dropdown-old__list-type p p--small">{Solution}</span>
                <CustomLink
                    text={solution.MktplaceSolutionName}
                    url={url}
                    className="typeahead-dropdown-old__list-link"
                    focusRef={setResultRef}
                    onClick={onClick}
                />
                <DataFlag datasetAddedDate={solution.MktplaceDateAdded} datasetEnhancementDate={solution.MktplaceSolutionCurrentVerDate} isSmall />
            </li>
        );
    };

    const buildBlueprint = (blueprint: APIBlueprint) => {
        const blueprintPathSegment = buildUrl(blueprint.AbsoluteBlueprintName || blueprint.MktplaceBlueprintUrlName || blueprint.MarketplaceBlueprintName,
            blueprint.KeyMarketplaceBlueprint);
        const url = `/blueprints/${blueprintPathSegment}`;
        return (
            <li key={blueprint.KeyMarketplaceBlueprint} className="typeahead-dropdown-old__list-item">
                <span className="typeahead-dropdown-old__list-type p p--small">{Blueprint}</span>
                <CustomLink
                    text={blueprint.MarketplaceBlueprintName}
                    url={url}
                    className="typeahead-dropdown-old__list-link"
                    focusRef={setResultRef}
                    onClick={onClick}
                />
                <DataFlag datasetAddedDate={blueprint.MarketplaceBlueprintDateAdded} datasetEnhancementDate={blueprint.MarketplaceBlueprintEnhancementDate} isSmall />
            </li>
        );
    };

    const buildDataset = (dataProfile: DataProfileCard) => (
        <li key={dataProfile.id} className="typeahead-dropdown-old__list-item">
            <span className="typeahead-dropdown-old__list-type p p--small">{Dataset}</span>
            <CustomLink
                text={dataProfile.name}
                url={
                    dataProfile.isBundle
                        ? `/datasets/bundle/${buildUrl(dataProfile.absoluteName || dataProfile.urlName || dataProfile.name, dataProfile.id)}`
                        : `/datasets/${buildUrl(dataProfile.absoluteName || dataProfile.urlName || dataProfile.name, dataProfile.id)}`
                }
                className="typeahead-dropdown-old__list-link"
                focusRef={setResultRef}
                onClick={onClick}
            />
            <DataFlag datasetAddedDate={dataProfile.addedDate} datasetEnhancementDate={dataProfile.enhancementDate} isSmall />
        </li>
    );

    const hasDataProfiles = dataProfiles.length > 0;
    const hasSearchTerm = searchToken.length > 0;
    return (!loading || hasDataProfiles) && hasSearchTerm ? (
        <article className={cn('typeahead-dropdown-old', className)}>
            {!hasDataProfiles && <p className="typeahead-dropdown-old__no-results">No results - please amend your search</p>}
            {hasDataProfiles && (
                <ul className="typeahead-dropdown-old__list">
                    {dataProfiles.slice(0, 5).map(dataProfile => {
                        if ('MktplaceSolutionName' in dataProfile) return buildSolution(dataProfile as APISolution);
                        if ('MarketplaceBlueprintName' in dataProfile) return buildBlueprint(dataProfile as APIBlueprint);
                        return buildDataset(dataProfile as DataProfileCard);
                    })}
                    {hasMoreThan5Results && !isMobileDropdown && (
                        <CustomLink
                            buttonTheme="tertiary"
                            className="typeahead-dropdown-old__button"
                            focusRef={setAllResultsRef}
                            isButton
                            isFullWidth
                            text={`${seeAllResults} (${dataProfiles.length})`}
                            url={`/search-results?search=${encodeURIComponent(searchToken.trim())}`}
                            onClick={onClick}
                        />
                    )}
                </ul>
            )}
        </article>
    ) : null;
}

export default React.memo(TypeaheadDropdown);
