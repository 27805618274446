/**
 *
 * Usage
 *
 * const objects = [{ 'a': 1 }, { 'b': 2 }];
 * const deep = clonedeep(objects);
 *
 */

import cloneDeep from 'lodash.clonedeep';

export default cloneDeep;
