import { batch } from 'react-redux';
import ClientStore from '../redux/store';
import ServerStore from '../redux/server-store';
import { fetchInitialDataset, fetchFullDatasetwithfilters } from '../redux/datasets/datasets.actions';
import { fetchQueryBuilderSteps } from '../redux/querybuilder/query-builder.actions';
import { fetchInitialSolutions, fetchFullSolution } from '../redux/solutions/solutions.actions';
import { fetchFullBlueprint, fetchInitialBlueprints } from '../redux/blueprints/blueprints.actions';
import { fetchFullQueryLibrary } from '../redux/querylibrary/querylibrary.actions';
import { fetchResearchLinks, fetchFAQs, fetchPageTexts, updateUTMParameters, fetchIdVariants, fetchTileNamesGt25Chars } from '../redux/shared/shared.actions';
import fetchFilters from '../redux/filters/filters.actions';
import extractId from './extract-id';
import SPGVariables from './spg-variables';

const spgVariables = SPGVariables();

let Store = ClientStore;

const loadAll = () => {
    batch(() => {
        Store.dispatch(fetchResearchLinks());
        // load all datasets
        Store.dispatch(fetchInitialDataset());
        // Load all solutions
        Store.dispatch(fetchInitialSolutions());
        // Load all blueprints
        Store.dispatch(fetchInitialBlueprints());
        // load all filters
        Store.dispatch(fetchFilters());
        // load all page texts / page sections
        Store.dispatch(fetchPageTexts());
        // load all steps when user is not logged in
        Store.dispatch(fetchQueryBuilderSteps());
        // load IdVariants
        Store.dispatch(fetchIdVariants());
        // load tileNamesGt25Chars
        Store.dispatch(fetchTileNamesGt25Chars());
    });
};

const start = (path: string) => {
    if (spgVariables.IS_CRAWLER_REQUEST || spgVariables.IS_SERVER_REQUEST) {
        Store = spgVariables.IS_SERVER_REQUEST ? ServerStore() : Store;
        const pathLowerCase = path.toLowerCase();
        const id = extractId(pathLowerCase);
        if (id) {
            if (pathLowerCase.indexOf('/solutions/') > -1) {
                Store.dispatch(fetchFullSolution({ id }));
            } else if (pathLowerCase.indexOf('/blueprints/') > -1) {
                Store.dispatch(fetchFullBlueprint({ id }));
            } else if (pathLowerCase.indexOf('/query-library/') > -1) {
                Store.dispatch(fetchFullQueryLibrary({ id }));
            } else {
                const isBundle = pathLowerCase.indexOf('bundle') > -1;
                Store.dispatch(fetchFullDatasetwithfilters({ id, isBundle }));
            }
        } else if (pathLowerCase.indexOf('faq') > -1) {
            Store.dispatch(fetchFAQs());
        } else {
            loadAll();
        }
    } else {
        loadAll();
        // store utm params for marketo form submission
        Store.dispatch(updateUTMParameters());
    }
};

export default start;
