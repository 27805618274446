import { reducerWithInitialState } from 'typescript-fsa-reducers';
import update from 'react-addons-update';
import {
    toggleLogInStatus,
    fetchUserProfile,
    clearUserProfile,
    toggleSessionCreate,
    fetchUserPreference,
    updateUserPreference,
    updateUserPreferenceManually
} from './user.actions';

const initialUserState: UserStoreState = {
    isLoggedIn: false,
    isTableauSessionCreated: false,
    bookmarkPreference: undefined
};

const reducer = reducerWithInitialState(initialUserState);

reducer.case(toggleLogInStatus, (state, isLoggedIn) => {
    return { ...state, isLoggedIn };
});

reducer.case(toggleSessionCreate, (state: UserStoreState, isTableauSessionCreated: boolean) => {
    return { ...state, isTableauSessionCreated };
});

reducer.case(fetchUserProfile.async.done, (state, userInfo) => {
    // console.log('fetchInitialDataset.async.done', state, userInfo);
    return update(state, { userInfo: { $set: userInfo.result } });
});

reducer.case(fetchUserPreference.async.done, (state, bookmarkPreference) => {
    return update(state, { bookmarkPreference: { $set: bookmarkPreference.result } });
});

reducer.case(updateUserPreference.async.done, (state, bookmarkPreference) => {
    return update(state, { bookmarkPreference: { $set: bookmarkPreference.result } });
});

reducer.case(updateUserPreferenceManually.async.done, (state, bookmarkPreference) => {
    return update(state, { bookmarkPreference: { $set: bookmarkPreference.result } });
});

reducer.case(clearUserProfile, state => {
    return update(state, { $set: initialUserState });
});

export { reducer, initialUserState };
