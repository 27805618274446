/*
  Convert piped string to array
*/

const createArrayFromPipedString = (str: string) => {
    let cleanString = str;
    if (cleanString) {
        // remove whitespace
        cleanString = cleanString.replace(/\s/g, '');

        return cleanString.split('|');
    }

    return [];
};

export default createArrayFromPipedString;
