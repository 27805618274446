const emptySection = {
    title: '',
    content: '',
    linkText: '',
    linkUrl: ''
};
export default (sectionName: string, pageSectionsData: PageSections[]) => {
    if (pageSectionsData && pageSectionsData.length < 1) return emptySection;
    const dataDrivenTexts = pageSectionsData && pageSectionsData.find(x => x.MarketplacePageSection.MarketplacePageSectionName === sectionName);
    let pageSectionData = emptySection;
    if (dataDrivenTexts) {
        pageSectionData = {
            title: dataDrivenTexts.MarketplaceSectionConHeader ? dataDrivenTexts.MarketplaceSectionConHeader : '',
            content: dataDrivenTexts.MarketplaceSectionConDesc ? dataDrivenTexts.MarketplaceSectionConDesc : '',
            linkText: dataDrivenTexts.MarketplaceSectionConLinkTitle ? dataDrivenTexts.MarketplaceSectionConLinkTitle : '',
            linkUrl: dataDrivenTexts.WebSiteURL ? dataDrivenTexts.WebSiteURL : ''
        };
    }
    return pageSectionData;
};
