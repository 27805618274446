import DateDiff from './date-diff';

const name = 'Sort Helper';

const isValidDate = (prop: undefined | number | string) => {
    if (!prop || prop === '') return false;
    return new Date(prop).toString() !== 'Invalid Date';
};

const sortAsc = (items: AnonymousObject, sortProp1: string, sortProp2 = '') => {
    if (!items.length || !items[0][sortProp1]) return items;
    return items.sort((a: AnonymousObject, b: AnonymousObject) => {
        if (a[sortProp1].toLowerCase() < b[sortProp1].toLowerCase()) return -1;
        if (a[sortProp1].toLowerCase() > b[sortProp1].toLowerCase()) return 1;
        if (sortProp2 !== '') {
            if (a[sortProp2].toLowerCase() < b[sortProp2].toLowerCase()) return -1;
            if (a[sortProp2].toLowerCase() > b[sortProp2].toLowerCase()) return 1;
        }
        return 0;
    });
};

const sortDesc = (items: AnonymousObject, sortProp1: string, sortProp2 = '') => {
    if (!items[0][sortProp1]) return items;
    return items.sort((a: AnonymousObject, b: AnonymousObject) => {
        if (a[sortProp1].toLowerCase() < b[sortProp1].toLowerCase()) return 1;
        if (a[sortProp1].toLowerCase() > b[sortProp1].toLowerCase()) return -1;
        if (sortProp2 !== '') {
            if (a[sortProp2].toLowerCase() < b[sortProp2].toLowerCase()) return 1;
            if (a[sortProp2].toLowerCase() > b[sortProp2].toLowerCase()) return -1;
        }
        return 0;
    });
};

const sortAscByDate = (items: AnonymousObject, sortProp1: string, sortProp2 = '') => {
    return items.sort((a: AnonymousObject, b: AnonymousObject) => {
        // a is null? last
        if (!isValidDate(a[sortProp1])) return 1;
        // b is null? last
        if (!isValidDate(b[sortProp1])) return -1;
        if (new Date(a[sortProp1]) < new Date(b[sortProp1])) return -1;
        if (new Date(a[sortProp1]) > new Date(b[sortProp1])) return 1;
        if (sortProp2 !== '') {
            if (a[sortProp2].toLowerCase() < b[sortProp2].toLowerCase()) return -1;
            if (a[sortProp2].toLowerCase() > b[sortProp2].toLowerCase()) return 1;
        }
        return 0;
    });
};

const sortDescByDate = (items: AnonymousObject, sortProp1: string, sortProp2 = '') => {
    return items.sort((a: AnonymousObject, b: AnonymousObject) => {
        // a is null? last
        if (!isValidDate(a[sortProp1])) return 1;
        // b is null? last
        if (!isValidDate(b[sortProp1])) return -1;
        if (new Date(a[sortProp1]) < new Date(b[sortProp1])) return 1;
        if (new Date(a[sortProp1]) > new Date(b[sortProp1])) return -1;
        if (sortProp2 !== '') {
            if (a[sortProp2].toLowerCase() < b[sortProp2].toLowerCase()) return 1;
            if (a[sortProp2].toLowerCase() > b[sortProp2].toLowerCase()) return -1;
        }
        return 0;
    });
};

const sortByEnhanced = (items: AnonymousObject) => {
    const enhancedDate = items
        .filter((item: DataProfileCard) => {
            if (!item.enhancementDate) return false;
            return isValidDate(item.enhancementDate) && DateDiff(item.enhancementDate) > 0;
        }) // plus filter to remove future dates use DateDiff
        .sort((a: AnonymousObject, b: AnonymousObject) => {
            // a is null? last
            if (!isValidDate(a.enhancementDate)) return 1;
            // b is null? last
            if (!isValidDate(b.enhancementDate)) return -1;
            if (new Date(a.enhancementDate) < new Date(b.enhancementDate)) return 1;
            if (new Date(a.enhancementDate) > new Date(b.enhancementDate)) return -1;
            return 0;
        });

    const nonEnhancedDate = items
        .filter((item: DataProfileCard) => {
            if (!item.enhancementDate) return true;
            if (!isValidDate(item.enhancementDate)) return true;
            return DateDiff(item.enhancementDate) <= 0;
        })
        .sort((a: AnonymousObject, b: AnonymousObject) => {
            // a is null? last
            if (!isValidDate(a.addedDate)) return 1;
            // b is null? last
            if (!isValidDate(b.addedDate)) return -1;
            if (new Date(a.addedDate) < new Date(b.addedDate)) return 1;
            if (new Date(a.addedDate) > new Date(b.addedDate)) return -1;
            return 0;
        });

    return enhancedDate.concat(nonEnhancedDate);
};

export { name as default, sortAsc, sortDesc, sortAscByDate, sortDescByDate, sortByEnhanced, isValidDate };
